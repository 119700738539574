import { AdminContext } from 'components/AdminContext/AdminContext';
import { useContext } from 'react';
import { RiToggleFill, RiToggleLine } from 'react-icons/ri';

import styles from './ToggleAdminModeButton.module.css';

/**
 * Toggle hide / show the admin banner
 * @returns {JSX.Element}
 * @constructor
 */
export const ToggleAdminModeButton = () => {
  const { isBannerVisible, updateBannerVisible } = useContext(AdminContext);

  return (
    <button
      aria-pressed={isBannerVisible}
      aria-label={`${isBannerVisible ? 'un' : ''}press to ${
        isBannerVisible ? 'dis' : 'en'
      }able admin doc stage mode`}
      className={styles.adminToggleButton}
      onClick={() => updateBannerVisible(!isBannerVisible)}
    >
      {isBannerVisible ? <RiToggleFill size="2.5rem" /> : <RiToggleLine size="2.5rem" />}
    </button>
  );
};

ToggleAdminModeButton.propTypes = {};
