import { MainContentWrapper } from 'components/MainContentWrapper/MainContentWrapper';
import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';

/**
 * We don't always have the year for a particular title/article particularly in
 * IAR documents, and in that case the expectation is to display the latest
 * version. This component simply gets the latest edition from the Ref Data
 *  and replaces the URL so the component that displays the article and title
 *  navigation can take over.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const LatestArticle = () => {
  const { article, title } = useParams();
  const { hash } = useLocation();
  const history = useHistory();
  const { currentEdition } = useContext(RefDataContext);
  const [link, setLink] = useState('');

  useEffect(() => {
    let url = `/code/${currentEdition}/${title}/${article}`;
    if (hash) {
      url += `${hash}`;
    }
    setLink(url);
    history.replace(url);
  }, [article, currentEdition, hash, history, title]);

  // Users should not see this, but just in case it doesn't properly redirect...
  return (
    <MainContentWrapper>
      <Link
        to={link}
        aria-label={`link to the latest version of Title ${title}, Article ${article}`}
      >
        Title {title}, Article {article}
      </Link>
    </MainContentWrapper>
  );
};
