import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { RiArrowUpLine } from 'react-icons/ri';

import styles from './BackToTopButton.module.css';

/**
 * Back to top button. Fades in when the scroll threshold has been reached, or
 * 5% (0.05) by default, of the scroll height of the scrollable element.
 *
 * @param scrollThreshold Percentage of scrolling (from 0 to 1)
 * @param scrollRef React Ref to the scrollable element
 * @returns {JSX.Element}
 * @constructor
 */
export const BackToTopButton = ({ scrollThreshold, scrollRef }) => {
  const [isVisible, setIsVisible] = useState(false);

  const threshold = scrollThreshold ?? 0.05;

  const scrollToTop = () => {
    scrollRef.current?.scrollTo({ behavior: 'smooth', top: 0 });
  };

  useEffect(() => {
    const scrollableElem = scrollRef.current;
    const handleScroll = () => {
      const el = scrollableElem;
      const scrollTotal = el.scrollHeight - el.clientHeight;
      setIsVisible(el.scrollTop / scrollTotal > threshold);
    };
    scrollableElem.addEventListener('scroll', handleScroll);
    return () => {
      scrollableElem.removeEventListener('scroll', handleScroll);
    };
  }, [threshold, scrollRef]);

  return (
    <button
      aria-label="scroll back to the top button"
      className={isVisible ? `${styles.backToTopButton} ${styles.visible}` : styles.backToTopButton}
      onClick={scrollToTop}
    >
      <RiArrowUpLine size="2rem" title="Scroll to top" />
    </button>
  );
};

BackToTopButton.propTypes = {
  scrollThreshold: PropTypes.number,
  scrollRef: PropTypes.any
};
