import { AdminContext } from 'components/AdminContext/AdminContext';
import { Button } from 'components/Button/Button';
import { useDocStageMutation } from 'hooks/documentHooks';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { documentStagePrivate, documentStagePrivateReady } from 'util/shapes';

import { AdminRegisterDoc } from '../AdminRegisterDoc/AdminRegisterDoc';
import styles from './AdminRegisterDocs.module.css';

const AdminRegisterDocs = ({ docs, stage, id, promote }) => {
  const [checkedOnes, setCheckedOnes] = useState([]);
  const { docStage } = useContext(AdminContext);
  const { isPending, mutate } = useDocStageMutation();

  const toggleCheckbox = (idx) => {
    const newChecked = [...checkedOnes];
    newChecked[idx] = !newChecked[idx];
    setCheckedOnes(newChecked);
  };
  const allChecked =
    checkedOnes.length > 0 &&
    checkedOnes.length === docs?.length &&
    checkedOnes.every((box) => box);
  const allUnChecked = checkedOnes.every((box) => !box);
  const toggleAllChecked = () => {
    setCheckedOnes(docs?.map(() => !allChecked) ?? []);
  };
  const uncheckAll = () => {
    setCheckedOnes(docs?.map(() => false) ?? []);
  };

  const promoteSelected = () => {
    const dins = docs?.filter((doc, idx) => checkedOnes[idx])?.map((doc) => doc.din);
    uncheckAll();
    mutate({ dins: dins, promote: promote });
  };

  return (
    <>
      <div className={styles.headerRow}>
        <div className={styles.headerControls}>
          <input
            type="checkbox"
            id={id}
            value={allChecked}
            checked={allChecked}
            onChange={toggleAllChecked}
          />
          <label htmlFor={id} className={styles.labelAll}>
            Select All
          </label>
          <Button disabled={allUnChecked || isPending} action={promoteSelected}>
            {promote ? 'Promote Selected' : 'Revert to Private'}
          </Button>
        </div>
        <strong>Published</strong>
      </div>
      <ul className={styles.docList}>
        {docs?.map((doc, idx) => (
          <AdminRegisterDoc
            key={doc.din}
            document={doc}
            externalLink={false}
            disabled={docStage !== stage}
            isChecked={checkedOnes[idx]}
            toggleCheckbox={() => toggleCheckbox(idx)}
          />
        ))}
      </ul>
    </>
  );
};

AdminRegisterDocs.propTypes = {
  docs: PropTypes.array,
  stage: PropTypes.oneOf([documentStagePrivate, documentStagePrivateReady]),
  id: PropTypes.string.isRequired,
  promote: PropTypes.bool.isRequired
};

export default AdminRegisterDocs;
