import PropTypes from 'prop-types';

import styles from './Button.module.css';

/**
 * creates a standard `<button>`
 * @param action Action when the button is clicked
 * @param additionalClassName additional class to add to the button
 * @param ariaLabel aria-label for the button
 * @param children markup or text for the button's content
 * @param disabled whether or not the button is disabled
 * @param type type of button (submit, button, reset)
 * @param variants space separated list of variants, eg: 'hollow inverted'
 * @returns {JSX.Element}
 * @constructor
 */
export const Button = ({
  action,
  additionalClassName,
  ariaLabel,
  children,
  disabled,
  type,
  variants
}) => {
  let className = styles.defaultButton;
  if (variants?.includes('hollow')) {
    className = `${className} ${styles.hollowButton}`;
  }
  if (variants?.includes('inverted')) {
    className = `${className} ${styles.invertedButton}`;
  }

  return (
    <button
      aria-label={ariaLabel}
      className={additionalClassName ? `${className} ${additionalClassName}` : className}
      disabled={disabled}
      onClick={action || undefined}
      type={type || 'button'}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  action: PropTypes.func,
  additionalClassName: PropTypes.string,
  ariaLabel: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  variants: PropTypes.string
};
