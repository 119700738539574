import { Auth } from 'aws-amplify';
import { Button } from 'components/Button/Button';
import PropTypes from 'prop-types';
import { useLayoutEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';

import styles from './AzurePrompt.module.css';

export const AzurePrompt = ({ isOpen, onClose }) => {
  const azurePromptRef = useRef(null);
  const [hasIgnoredPrompt, setHasIgnoredPrompt] = useState(false);

  useLayoutEffect(() => {
    const dialog = azurePromptRef.current;
    if (!hasIgnoredPrompt && isOpen) {
      dialog.showModal();
    }

    const promptEscapeListener = (e) => {
      if (e.key === 'Escape') {
        e.preventDefault();
      }
    };
    dialog.addEventListener('keydown', promptEscapeListener);

    return () => {
      dialog?.removeEventListener('keydown', promptEscapeListener);
    };
  }, [hasIgnoredPrompt, isOpen]);

  const handleAzureLogin = async () => {
    try {
      await Auth.federatedSignIn({
        customProvider: 'Azure'
      });
    } catch (error) {
      toast.error(error?.message ?? 'Error with federated sign in', {
        position: 'top-right'
      });
    }
  };

  const ignoreAzurePrompt = () => {
    setHasIgnoredPrompt(true);
    azurePromptRef.current.close();
    if (onClose) {
      onClose();
    }
  };

  return (
    <dialog className={styles.azurePromptDialog} ref={azurePromptRef}>
      <p>Based on your email address, you should be able to sign in via Azure.</p>
      <div className={styles.buttonContainer}>
        <Button action={handleAzureLogin} variants="inverted">
          Sign in via Azure
        </Button>
        <Button action={ignoreAzurePrompt} variants="hollow inverted">
          Cancel
        </Button>
      </div>
    </dialog>
  );
};

AzurePrompt.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};
