import { Auth } from 'aws-amplify';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { Button } from 'components/Button/Button';
import { InputField } from 'components/InputField/InputField';
import { PasswordField } from 'components/InputField/PasswordField';
import { MainContentWrapper } from 'components/MainContentWrapper/MainContentWrapper';
import { useInputVisitedMarker } from 'hooks/useInputVisitedMarker';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import { useHistory, useLocation } from 'react-router';

import styles from '../MyIAR.module.css';

export const VerifyReset = () => {
  const { state } = useLocation();
  const history = useHistory();
  const [visitedFields, markVisited, markAllVisited] = useInputVisitedMarker([
    'code',
    'newPassword',
    'username'
  ]);
  let toastId;

  const handleVerification = async (e) => {
    e.preventDefault();
    if (toastId) {
      toast.dismiss(toastId);
    }
    if (e.target.checkValidity()) {
      const data = {};
      for (const [field, value] of new FormData(e.target).entries()) {
        data[field] = value;
      }

      try {
        await Auth.forgotPasswordSubmit(data.username, data.code, data.newPassword);
        history.push('/myIAR/login', {
          message: 'Password successfully reset! You may now sign in.'
        });
      } catch (error) {
        toast.error(error?.message ?? 'Setting new password failed.', {
          position: 'top-right'
        });
      }
    } else {
      markAllVisited();
    }
  };

  return (
    <MainContentWrapper>
      <Helmet>
        <title>Reset Password | MyIAR | IARP</title>
      </Helmet>
      <AccentHeader level={1}>Reset Password</AccentHeader>
      <p>Enter the verification code sent to your email address, and enter a new password.</p>
      <form onSubmit={handleVerification} noValidate className={styles.simpleForm}>
        <InputField
          ariaLabel="Enter your email address"
          autocompleteType="email"
          errorLabel="Valid email required"
          handleBlur={markVisited}
          id="username-field"
          initialValue={state?.username}
          inputType="email"
          isVisited={visitedFields.username}
          label="Email"
          name="username"
          readonly={!!state?.username}
        />
        <InputField
          ariaLabel="Enter the verification code from email"
          errorLabel="Required"
          handleBlur={markVisited}
          id="verificationCode"
          isVisited={visitedFields.code}
          label="Verification Code"
          name="code"
        />
        <PasswordField
          ariaLabel="Enter a new password"
          autocompleteType="new-password"
          errorLabel="Required"
          handleBlur={markVisited}
          id="new-password-field"
          isVisited={visitedFields.newPassword}
          label="New Password"
          name="newPassword"
        />
        <div className={styles.formControls}>
          <Button type="submit">Verify</Button>
        </div>
      </form>
    </MainContentWrapper>
  );
};
