import { ActionOrOpinionList } from 'pages/Home/ActionsAndOpinions/ActionOrOpinionList';

import styles from './ActionsAndOpinions.module.css';

export const ActionsAndOpinions = () => {
  const year = new Date().getFullYear();
  return (
    <section
      className={styles.actionsOpinionsContainer}
      aria-label={"This year's Governor's actions and Attorney General Opinions"}
    >
      <ActionOrOpinionList label="Governor's Actions" tab="gov" year={year} />
      <ActionOrOpinionList label="Attorney General's Opinions" tab="ag" year={year} />
    </section>
  );
};
