import { ListFilter } from 'components/ListFilter/ListFilter';
import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import PropTypes, { oneOfType } from 'prop-types';
import { useContext } from 'react';

export const EditionYearFilter = ({ handleFilterChange, handleToggle, selectedEditions }) => {
  const { editions } = useContext(RefDataContext);
  return (
    <ListFilter
      dataList={editions}
      fieldName="editions"
      initialSelection={selectedEditions}
      isActive={true}
      listName="Editions"
      onToggle={handleToggle}
      onUpdate={handleFilterChange}
      pluralName="Editons"
      propName="label"
      propValue="value"
    />
  );
};

EditionYearFilter.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  selectedEditions: PropTypes.arrayOf(oneOfType([PropTypes.number, PropTypes.string]))
};
