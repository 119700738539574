import { OptionButton } from 'components/OptionButton/OptionButton';
import { SegmentedControl } from 'components/SegmentedControl/SegmentedControl';
import isSameDay from 'date-fns/isSameDay';
import filterStyles from 'pages/Register/RegisterList/RegisterFilterForm/RegisterFilterForm.module.css';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import {
  earliestDataFn,
  sixDaysAgoFn,
  sixMonthsAgoFn,
  thirtyDaysAgoFn,
  todayFn
} from 'util/dateUtil';
import { dateRange, dateRanges } from 'util/shapes';

import styles from './PostedDateFilter.module.css';

const today = todayFn();
const sixDaysAgo = sixDaysAgoFn();
const thirtyDaysAgo = thirtyDaysAgoFn();
const sixMonthsAgo = sixMonthsAgoFn();
const earliest = earliestDataFn();

const timeFilterItems = [
  { id: 'sevenDayRegisterFilter', label: '7 Days', value: dateRanges.sevenDays },
  { id: 'thirtyDayRegisterFilter', label: '30 Days', value: dateRanges.thirtyDays },
  { id: 'sixMonthRegisterFilter', label: '6 Months', value: dateRanges.sixMonths },
  { id: 'customRegisterFilter', label: 'Custom', value: dateRanges.custom }
];

export const PostedDateFilter = ({ dateRange, endDate, isActive, onChange, startDate }) => {
  const range = {
    dateRange: dateRange ?? dateRanges.custom,
    startDate: startDate ?? earliest,
    endDate: endDate ?? today
  };
  const labelClass = isActive ? undefined : styles.inactiveLabel;
  let dateLabel;
  if (range.dateRange !== dateRanges.custom) {
    dateLabel = `(Last ${timeFilterItems.find((item) => item.value === range.dateRange).label})`;
  } else if (isSameDay(range.startDate, earliest) && isSameDay(range.endDate, today)) {
    dateLabel = '(All)';
  } else {
    dateLabel = '(Custom)';
  }

  const toggleCustom = (e) => {
    const newSelection = e.target.value;
    let newRange = {
      ...range,
      dateRange: newSelection
    };

    // set the range. If 'custom', it will just retain whichever range was selected
    if (newSelection === dateRanges.sevenDays) {
      newRange.endDate = today;
      newRange.startDate = sixDaysAgo;
    } else if (newSelection === dateRanges.thirtyDays) {
      newRange.endDate = today;
      newRange.startDate = thirtyDaysAgo;
    } else if (newSelection === dateRanges.sixMonths) {
      newRange.endDate = today;
      newRange.startDate = sixMonthsAgo;
    }
    onChange(newRange);
  };

  const setRangeDate = (field, value) => {
    const newRange = {
      ...range,
      [field]: value
    };
    onChange(newRange);
  };

  return (
    <OptionButton
      ariaLabel="filter documents by posted date"
      disabled={!isActive}
      label={`Posted Date ${dateLabel}`}
    >
      <p className={labelClass}>Show Register documents from the last</p>
      <SegmentedControl
        ariaLabel="filter documents by date"
        controlName="timeFilter"
        disabled={!isActive}
        items={timeFilterItems}
        onSelect={toggleCustom}
        selectedVal={dateRange}
      />
      <div
        className={
          dateRange === dateRanges.custom
            ? `${filterStyles.customRangeWrapper} ${filterStyles.isOpen}`
            : filterStyles.customRangeWrapper
        }
      >
        <div className={filterStyles.customRange}>
          <div className={filterStyles.dateContainer}>
            <label className={labelClass} htmlFor="filterStartDate">
              Start Date
            </label>
            <DatePicker
              aria-label="Posted Start Date"
              calendarClassName="customDatePicker"
              disabled={!isActive}
              id="filterStartDate"
              maxDate={today}
              minDate={earliest}
              name="startDate"
              onChange={(date) => setRangeDate('startDate', date)}
              popperPlacement="bottom-start"
              selected={startDate}
            />
          </div>
          <div className={filterStyles.dateContainer}>
            <label className={labelClass} htmlFor="filterEndDate">
              End Date
            </label>
            <DatePicker
              aria-label="Posted End Date"
              calendarClassName="customDatePicker"
              disabled={!isActive}
              id="filterEndDate"
              maxDate={today}
              minDate={earliest}
              name="endDate"
              onChange={(date) => setRangeDate('endDate', date)}
              popperPlacement="bottom-end"
              selected={endDate}
            />
          </div>
        </div>
      </div>
    </OptionButton>
  );
};

PostedDateFilter.propTypes = {
  dateRange: dateRange,
  endDate: PropTypes.instanceOf(Date),
  isActive: PropTypes.bool,
  onChange: PropTypes.func,
  startDate: PropTypes.instanceOf(Date)
};
