import PropTypes from 'prop-types';

import styles from './ExternalLink.module.css';

export const ExternalLink = ({
  additionalClassName,
  ariaLabel,
  className,
  linkText,
  url,
  children
}) => {
  if (!ariaLabel && !linkText) {
    throw new Error('<ExternalLink> requires an "ariaLabel" or "linkText" prop');
  }
  let classes = styles.linkClass;
  if (className) {
    classes = className;
  } else if (additionalClassName) {
    classes += ` ${additionalClassName}`;
  }
  return (
    <a
      aria-label={`${ariaLabel ?? linkText}, Opens in a new tab`}
      className={classes}
      href={url}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children ?? linkText}
    </a>
  );
};

ExternalLink.propTypes = {
  additionalClassName: PropTypes.string,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  linkText: PropTypes.string,
  url: PropTypes.string.isRequired,
  children: PropTypes.any
};
