import { Auth } from 'aws-amplify';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { AuthContext } from 'components/AuthContext/AuthContext';
import { Button } from 'components/Button/Button';
import { InputField } from 'components/InputField/InputField';
import { PasswordField } from 'components/InputField/PasswordField';
import linkStyles from 'components/Link/Link.module.css';
import { MainContentWrapper } from 'components/MainContentWrapper/MainContentWrapper';
import { useInputVisitedMarker } from 'hooks/useInputVisitedMarker';
import { AzurePrompt } from 'pages/MyIAR/AzurePrompt/AzurePrompt';
import { useContext, useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { isEmailDomainIGA } from 'util/accountUtil';

import styles from './Login.module.css';

export const Login = () => {
  const [promptAzureLogin, setPromptAzureLogin] = useState(false);
  const { setUser } = useContext(AuthContext);
  const [visitedFields, markVisited, markAllVisited] = useInputVisitedMarker([
    'username',
    'password'
  ]);
  const { state } = useLocation();
  const history = useHistory();
  let toastId;

  useLayoutEffect(() => {
    if (state?.message) {
      toast.success(state.message, { duration: 10000, position: 'top-right' });
    }
  }, [state]);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (toastId) {
      toast.dismiss(toastId);
    }
    markAllVisited();
    const formData = new FormData(e.target);
    if (e.target.checkValidity()) {
      try {
        const email = formData.get('username');
        const password = formData.get('password');
        const user = await Auth.signIn(email, password);
        setUser(user);
        history.push(state?.from ? state.from : '/myIAR/home');
      } catch (error) {
        toastId = toast.error(error?.message ?? 'Error signing out', {
          position: 'top-right'
        });
      }
    }
  };

  const handleAzureLogin = async () => {
    try {
      await Auth.federatedSignIn({
        customProvider: 'Azure'
      });
    } catch (error) {
      toastId = toast.error(error?.message ?? 'Error with federated sign in', {
        position: 'top-right'
      });
    }
  };

  const checkEmail = (e) => {
    markVisited(e);
    setPromptAzureLogin(isEmailDomainIGA(e.target.value));
  };

  const focusPassword = () => {
    document.getElementById('password-field').focus();
  };

  return (
    <MainContentWrapper>
      <Helmet>
        <title>Login | MyIAR | IARP</title>
      </Helmet>
      <AccentHeader level={1}>Welcome to MyIAR</AccentHeader>
      <p>Sign in to follow items and see updates</p>
      <div className={styles.signInOptions}>
        <div>
          <AccentHeader level={2} underline="subtle">
            IGA Accounts
          </AccentHeader>
          <p>If you have an IGA account, sign in with your IGA email.</p>
          <br />
          <Button action={handleAzureLogin}>Sign in with IGA Email</Button>
        </div>
        <div>
          <AccentHeader level={2} underline="subtle">
            Sign in with external email
          </AccentHeader>
          <form onSubmit={handleLogin} noValidate className={styles.loginForm}>
            <InputField
              additionalClassName={styles.emailField}
              ariaLabel="Enter your email address"
              autocompleteType="username"
              errorLabel="Required"
              handleBlur={checkEmail}
              id="username-field"
              inputType="email"
              isVisited={visitedFields.username}
              label="Email"
              name="username"
            />
            <PasswordField
              additionalClassName={styles.passwordField}
              ariaLabel="Enter your password"
              autocompleteType="current-password"
              errorLabel="Required"
              handleBlur={markVisited}
              id="password-field"
              isVisited={visitedFields.password}
              name="password"
            />
            <div className={styles.formControls}>
              <Button type="submit">Sign In</Button>
              <Link to="/myIAR/forgotPassword" className={linkStyles.buttonLink}>
                Forgot Password?
              </Link>
            </div>
          </form>
          <p>
            Don&apos;t have an account?{' '}
            <Link to="/myIAR/signUp" className={linkStyles.buttonLink}>
              Sign up
            </Link>
          </p>
        </div>
      </div>
      <AzurePrompt isOpen={promptAzureLogin} onClose={focusPassword} />
    </MainContentWrapper>
  );
};
