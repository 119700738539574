import PropTypes from 'prop-types';
import { useState } from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';

import styles from './IARAccordion.module.css';

/**
 * Render a simple accordion
 * @param title Accordion Title
 * @param defaultOpen Whether the accordion is open when initialized
 * @param children Standard React prop, used as content of the accordion
 * @returns {JSX.Element}
 * @constructor
 */
export const IARAccordion = ({ title, defaultOpen = false, children }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className={styles.accordion}>
      <button
        className={styles.toggleButton}
        onClick={() => setIsOpen((current) => !current)}
        aria-expanded={isOpen}
      >
        <div>{title}</div>
        <RiArrowDownSLine
          size="2rem"
          className={styles.toggleButtonIcon}
          title="accordion toggle icon"
        />
      </button>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

IARAccordion.propTypes = {
  title: PropTypes.node,
  defaultOpen: PropTypes.bool,
  children: PropTypes.node
};
