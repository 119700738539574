import { useCallback, useState } from 'react';

const createFieldMap = (fieldArray, status) => {
  const fieldMap = {};
  for (const i in fieldArray) {
    fieldMap[fieldArray[i]] = status;
  }
  return fieldMap;
};

/**
 * Hook to encapsulate keeping track of fields that have been visited.
 * @param fieldArray an array of field names
 * @returns {[{},((function(event): void|*)),((function(): void)|*),((function(): void)|*)]}
 */
export const useInputVisitedMarker = (fieldArray) => {
  const [visitedFields, setVisitedFields] = useState(createFieldMap(fieldArray, false));

  const markVisited = (e) => {
    setVisitedFields((visited) => {
      return {
        ...visited,
        [e.target.name]: true
      };
    });
  };

  const markAllVisited = useCallback(() => {
    setVisitedFields(createFieldMap(fieldArray, true));
  }, [fieldArray]);

  const resetVisited = useCallback(() => {
    setVisitedFields(createFieldMap(fieldArray, false));
  }, [fieldArray]);

  return [visitedFields, markVisited, markAllVisited, resetVisited];
};
