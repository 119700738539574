import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { IRDocumentAccordion } from 'pages/MyIAR/Home/Dashboard/IRDocumentAccordion/IRDocumentAccordion';
import PropTypes from 'prop-types';

import styles from './DashboardGroup.module.css';

export const DashboardGroup = ({ agencyList, lastVisited, title }) => {
  return (
    <div className={styles.dashboardGroupContainer}>
      {title ? (
        <AccentHeader level={3} underline="subtle">
          {title}
        </AccentHeader>
      ) : null}
      {agencyList?.length ? (
        <div className={styles.updates}>
          {agencyList.map((agencyDocs) => (
            <IRDocumentAccordion
              agencyDocs={agencyDocs}
              key={`accordion-${agencyDocs.agencyId}`}
              lastVisited={lastVisited}
            />
          ))}
        </div>
      ) : (
        <div>None</div>
      )}
    </div>
  );
};

DashboardGroup.propTypes = {
  agencyList: PropTypes.array,
  lastVisited: PropTypes.instanceOf(Date),
  title: PropTypes.string
};
