import { OptionButton } from 'components/OptionButton/OptionButton';
import PropTypes from 'prop-types';

export const AdditionalFilters = ({ children }) => {
  if (!children) {
    return null;
  }

  return (
    <OptionButton label="Additional Filters" ariaLabel="Additional Filters">
      <div>{children}</div>
    </OptionButton>
  );
};

AdditionalFilters.propTypes = {
  children: PropTypes.node
};
