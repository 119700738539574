import { AuthContext } from 'components/AuthContext/AuthContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import { claim } from 'util/shapes';

/**
 * Protects a route from being viewed by people who have not logged in. The component's
 * logic will check for a logged-in user, and either display the route if the user is
 * logged in, or forward to the login page (which will redirect to the user's original
 * destination once authenticated.
 *
 * Usage <PrivateRoute path={somePath}><AgencyList /></PrivateRoute>
 *
 * @param children The component to be rendered upon verifying user
 * @param requiredClaim (optional) a security claim that the user must have for access
 * @param rest The rest of the properties to apply to a standard <Route>
 * @returns {JSX.Element}
 * @constructor
 */
export const PrivateRoute = ({ children, requiredClaim, ...rest }) => {
  const { user, userClaims } = useContext(AuthContext);
  const hasRequiredClaim = !requiredClaim || userClaims.includes(requiredClaim);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user && hasRequiredClaim ? (
          children
        ) : user && !hasRequiredClaim ? (
          <Redirect
            to={{
              pathname: '/notAllowed'
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/myIAR/login',
              state: {
                from: location
              }
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.any,
  requiredClaim: claim
};
