import { Button } from 'components/Button/Button';
import linkStyles from 'components/Link/Link.module.css';
import { defaultSize, defaultStart } from 'hooks/openSearchHook';
import PropTypes from 'prop-types';
import { openSearchResults, searchParams } from 'util/shapes';

import styles from './SearchResultPaging.module.css';

/**
 * Create Previous / Next paging links if the result set is more than one page
 * @param data Search result data
 * @param onPageChange
 * @param params search params
 * @returns {JSX.Element|null}
 * @constructor
 */
export const SearchResultPaging = ({ data, onPageChange, params }) => {
  const resultCount = data?.hits?.total?.value ?? 0;
  const hasPrevious = resultCount > 0 && (params.start ?? defaultStart) > 0;
  const hasNext = (params.start ?? defaultStart) + (params.size ?? defaultSize) < resultCount;

  if (!hasPrevious && !hasNext) {
    return null;
  }

  return (
    <div className={styles.pagingContainer}>
      {hasPrevious ? (
        <Button
          action={() => onPageChange({ start: params.start - (params.size ?? defaultSize) })}
          aria-label={`show ${params.size} previous results`}
          variants="inverted"
        >
          Previous
        </Button>
      ) : (
        <span className={`${linkStyles.buttonLink} ${linkStyles.disabled}`}>Previous</span>
      )}
      <div>
        Showing {(params.start ?? 0) + 1} -{' '}
        {hasNext ? (params.start ?? 0) + (params.size ?? defaultSize) : resultCount}
      </div>
      {hasNext ? (
        <Button
          action={() => onPageChange({ start: (params.start ?? 0) + (params.size ?? defaultSize) })}
          aria-label={`show ${params.size} next results`}
          variants="inverted"
        >
          Next
        </Button>
      ) : (
        <span className={`${linkStyles.buttonLink} ${linkStyles.disabled}`}>Next</span>
      )}
    </div>
  );
};

SearchResultPaging.propTypes = {
  data: openSearchResults,
  onPageChange: PropTypes.func.isRequired,
  params: searchParams
};
