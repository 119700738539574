/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import { RiAddLine, RiCheckLine, RiStopFill } from 'react-icons/ri';

import styles from './AddCheckbox.module.css';

export const AddCheckbox = ({
  checked,
  disabled,
  fieldName,
  hasChildrenChecked,
  id,
  invalid,
  onToggle
}) => {
  return (
    <span
      className={invalid ? `${styles.invalid} ${styles.toggleContainer}` : styles.toggleContainer}
    >
      <input
        checked={checked ?? false}
        disabled={disabled}
        id={`${fieldName}-${id}`}
        name={fieldName}
        onChange={(e) => {
          if (onToggle) {
            onToggle(id, e.target.checked);
          }
        }}
        type="checkbox"
        value={id}
      />
      <label htmlFor={`${fieldName}-${id}`} className={styles.activeFilter}>
        <RiCheckLine size="1.25rem" title="checked icon" />
      </label>
      <label htmlFor={`${fieldName}-${id}`} className={styles.inactiveFilter}>
        {hasChildrenChecked ? (
          <RiStopFill size="1.25rem" title="add icon" />
        ) : (
          <RiAddLine size="1.25rem" title="add icon" />
        )}
      </label>
    </span>
  );
};

AddCheckbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  hasChildrenChecked: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  invalid: PropTypes.bool,
  onToggle: PropTypes.func
};
