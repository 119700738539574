import { Button } from 'components/Button/Button';
import { useManageDocStage } from 'hooks/documentHooks';
import { useRef } from 'react';
import toast from 'react-hot-toast';

import styles from './ManualSyncButton.module.css';

export const ManualSyncButton = () => {
  const { isPending, mutate } = useManageDocStage();
  const dialogRef = useRef(null);
  const promptForPublish = () => {
    dialogRef?.current?.showModal();
  };

  const abortSync = () => {
    dialogRef?.current?.close();
  };

  const initializeSync = () => {
    dialogRef?.current?.close();
    mutate();
    toast.success('Manual sync initiated.', {
      position: 'top-right'
    });
  };

  return (
    <>
      <Button action={promptForPublish} type="button" disabled={isPending}>
        Manually Sync Documents
      </Button>
      <dialog ref={dialogRef} className={styles.syncDialog}>
        <h1>Manual Sync</h1>
        <div className={styles.syncDialogBody}>
          <p>
            Please be sure you have alerted all IAC admin users that a manual sync is about to
            begin.
          </p>
          <div className={styles.buttonContainer}>
            <Button action={abortSync} type="button">
              Cancel
            </Button>
            <Button action={initializeSync} type="button">
              Proceed with sync
            </Button>
          </div>
        </div>
      </dialog>
    </>
  );
};
