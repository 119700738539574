import PropTypes from 'prop-types';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { articleNavType } from 'util/shapes';

import styles from './ArticleNavLink.module.css';

/**
 * Render a link to another article.
 *
 * @param articleInfo info needed to build the link
 * @param direction next / previous
 * @returns {JSX.Element}
 * @constructor
 */
export const ArticleNavLink = ({ articleInfo, direction }) => {
  return (
    <Link
      aria-label={`Title ${articleInfo.title}, Article ${articleInfo.article}`}
      className={styles.link}
      to={`/code/${articleInfo.year}/${articleInfo.title}/${articleInfo.article}`}
    >
      {direction === 'previous' ? <RiArrowLeftSLine size="1.5rem" /> : null}
      <span className={styles[direction]}>
        {articleInfo.titleChange ? <>Title {articleInfo.title},</> : null} Article{' '}
        {articleInfo.article}
      </span>
      {direction === 'next' ? <RiArrowRightSLine size="1.5rem" /> : null}
    </Link>
  );
};

ArticleNavLink.propTypes = {
  articleInfo: articleNavType.isRequired,
  direction: PropTypes.oneOf(['next', 'previous']).isRequired
};
