import indianaSeal from 'assets/static/images/indiana-seal.png';
import searchIcon from 'assets/static/images/search-icon.png';
import { Banner } from 'components/AppHeader/Banner/Banner';
import { useEffect, useState } from 'react';
import { RiCloseFill, RiMenuFill } from 'react-icons/ri';
import { useLocation } from 'react-router';
import { Link, NavLink } from 'react-router-dom';

import styles from './AppHeader.module.css';

export const AppHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen((isOpen) => !isOpen);
  };

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  return (
    <div className={styles.headerWrapper}>
      <Banner />
      <header className={isMenuOpen ? `${styles.header} ${styles.navOpen}` : styles.header}>
        <div className={styles.logoTitle}>
          <Link className={styles.logo} to="/" title="Indiana Administrative Rules">
            <img src={indianaSeal} alt="Indiana Seal Logo" />
          </Link>
          <div>
            <span className={styles.longTitle}>Indiana Administrative Rules and Policies</span>
            <span className={styles.shortTitle}>IARP</span>
          </div>
        </div>
        <nav
          aria-label="Primary"
          className={
            isMenuOpen ? `${styles.open} ${styles.navLinks}` : `${styles.closed} ${styles.navLinks}`
          }
        >
          <NavLink to="/" exact className={styles.navLink} activeClassName={styles.activeNavLink}>
            Home
          </NavLink>
          <NavLink to="/register" className={styles.navLink} activeClassName={styles.activeNavLink}>
            Indiana Register
          </NavLink>
          <NavLink to="/code" className={styles.navLink} activeClassName={styles.activeNavLink}>
            Administrative Code
          </NavLink>
          <NavLink to="/myIAR" className={styles.navLink} activeClassName={styles.activeNavLink}>
            MyIAR
          </NavLink>
          <NavLink to="/search" className={styles.navLink} activeClassName={styles.activeNavLink}>
            <img src={searchIcon} className={styles.searchIcon} alt="search icon" />
            <span className={styles.mobileText}>Search</span>
          </NavLink>
        </nav>
        <button className={styles.navDrawerButton} onClick={toggleMenu}>
          {isMenuOpen ? (
            <RiCloseFill size="2rem" title="Close menu icon" />
          ) : (
            <RiMenuFill size="2rem" title="Open menu icon" />
          )}
        </button>
      </header>
    </div>
  );
};
