import { Error404 } from 'pages/Error/Error404';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { RegisterDocument } from './RegisterDocument/RegisterDocument';
import { RegisterList } from './RegisterList/RegisterList';

export const Register = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={RegisterList} />
      <Route exact path={`${path}/:din`} component={RegisterDocument} />
      <Route path="*" component={Error404} />
    </Switch>
  );
};
