import { format, parseISO } from 'date-fns';
import { defaultSize, defaultStart } from 'hooks/openSearchHook';
import {
  displayFormat,
  earliestDataFn,
  sixDaysAgoFn,
  sixMonthsAgoFn,
  thirtyDaysAgoFn,
  todayFn,
  urlFormat
} from 'util/dateUtil';
import { docGroup } from 'util/documentUtil';
import { dateRanges as dataRanges, dateRanges } from 'util/shapes';

// Default values for the search object
export const defaultSearchObject = {
  agencies: [],
  archived: false,
  dateRange: dateRanges.custom,
  docSubTypes: [],
  docTypes: [],
  document_group: docGroup.all,
  editions: [],
  endDate: todayFn(),
  fiscallySignificant: false,
  search: '',
  size: defaultSize,
  start: defaultStart,
  startDate: earliestDataFn(),
  titles: [],
  topics: []
};

const arrayFilters = ['agencies', 'docSubTypes', 'docTypes', 'editions', 'titles', 'topics'];

/**
 * convert param object to a query string for browser history
 * @param params the parameters of the search
 * @param dateIsString boolean, use true after retrieving params from localStorage
 * @returns {string}
 */
export const buildSearchQueryString = (params, dateIsString) => {
  const queryParams = [];

  arrayFilters.forEach((filter) => {
    if (params[filter]?.length) {
      queryParams.push(`${filter}=${params[filter].join(',')}`);
    }
  });

  // add date range if Register is active
  if (params.dateRange && params.dateRange !== dataRanges.custom) {
    queryParams.push(`dateRange=${params.dateRange}`);
  } else {
    if (params.startDate) {
      const startDate = dateIsString ? parseISO(params.startDate) : params.startDate;
      queryParams.push(`startDate=${format(startDate, urlFormat)}`);
    }
    if (params.endDate) {
      const endDate = dateIsString ? parseISO(params.endDate) : params.endDate;
      queryParams.push(`endDate=${format(endDate, urlFormat)}`);
    }
  }

  if (params.archived) {
    queryParams.push('archived=true');
  }

  if (params.fiscallySignificant) {
    queryParams.push('fiscallySignificant=true');
  }

  // Add the search text.
  if (params.search?.length && params.search[0]) {
    queryParams.push(`search=${encodeURIComponent(params.search)}`);
  }

  // Paging params
  if (params.start) {
    queryParams.push(`start=${params.start}`);
  }
  if (params.size) {
    queryParams.push(`size=${params.size}`);
  }

  return queryParams.join('&');
};

const filterTypes = (selectedDocTypes, allDocTypes, property) => {
  return (
    selectedDocTypes?.filter((type) =>
      allDocTypes?.[property]?.docTypes?.find((docType) => type === docType.doc_type)
    ) ?? []
  );
};

export const parseTabbedSearchQueryString = (
  queryString,
  defaultParams,
  defaultEdition,
  documentTypes,
  allTopics,
  tab
) => {
  // parse the querystring, putting them in the appropriate tab's params
  const searchObject = structuredClone(defaultParams);
  searchObject.all.editions = [defaultEdition];
  searchObject.iac.editions = [defaultEdition];

  const params = parseSearchQueryString(queryString, defaultEdition, documentTypes, allTopics);

  // Attorney General Opinions specific stuff...
  if (tab === 'ag') {
    searchObject.ag.archived = params.archived;
    searchObject.ag.fiscallySignificant = params.fiscallySignificant;
    searchObject.ag.docTypes = filterTypes(params.docTypes, documentTypes, 'ag_opinions');
    searchObject.ag.topics = params.topics;
    searchObject.ag.agencies = params.agencies;
    searchObject.ag.dateRange = params.dateRange;
    searchObject.ag.endDate = params.endDate;
    searchObject.ag.startDate = params.startDate;
    searchObject.ag.start = params.start;
  }

  // Agency Guidance specific stuff...
  if (tab === 'agencyGuidance') {
    searchObject.agencyGuidance.archived = params.archived;
    searchObject.agencyGuidance.fiscallySignificant = params.fiscallySignificant;
    searchObject.agencyGuidance.docTypes = filterTypes(
      params.docTypes,
      documentTypes,
      'agency_guidance'
    );
    searchObject.agencyGuidance.docSubTypes = params.docSubTypes;
    searchObject.agencyGuidance.topics = params.topics;
    searchObject.agencyGuidance.agencies = params.agencies;
    searchObject.agencyGuidance.dateRange = params.dateRange;
    searchObject.agencyGuidance.endDate = params.endDate;
    searchObject.agencyGuidance.startDate = params.startDate;
    searchObject.agencyGuidance.start = params.start;
  }

  // all...
  if (tab === 'all') {
    searchObject.all = {
      ...params
    };
  }

  // Gov...
  if (tab === 'gov') {
    searchObject.gov.archived = params.archived;
    searchObject.gov.fiscallySignificant = params.fiscallySignificant;
    searchObject.gov.docTypes = filterTypes(params.docTypes, documentTypes, 'gov');
    searchObject.gov.topics = params.topics;
    searchObject.gov.agencies = params.agencies;
    searchObject.gov.dateRange = params.dateRange;
    searchObject.gov.endDate = params.endDate;
    searchObject.gov.startDate = params.startDate;
    searchObject.gov.start = params.start;
  }

  // iac...
  if (tab === 'iac') {
    searchObject.iac.editions = params.editions;
    searchObject.iac.topics = params.topics;
    searchObject.iac.agencies = params.agencies;
    searchObject.iac.start = params.start;
    searchObject.iac.titles = params.titles;
  }

  // Pending...
  if (tab === 'pending') {
    searchObject.pending.archived = false;
    searchObject.pending.fiscallySignificant = params.fiscallySignificant;
    searchObject.pending.docTypes = filterTypes(params.docTypes, documentTypes, 'pending_rules');
    searchObject.pending.topics = params.topics;
    searchObject.pending.agencies = params.agencies;
    searchObject.pending.dateRange = params.dateRange;
    searchObject.pending.endDate = params.endDate;
    searchObject.pending.startDate = params.startDate;
    searchObject.pending.start = params.start;
  }

  if (tab === 'ir') {
    searchObject.ir.archived = params.archived;
    searchObject.ir.fiscallySignificant = params.fiscallySignificant;
    searchObject.ir.docTypes = params.docTypes;
    searchObject.ir.topics = params.topics;
    searchObject.ir.docSubTypes = params.docSubTypes;
    searchObject.ir.agencies = params.agencies;
    searchObject.ir.dateRange = params.dateRange;
    searchObject.ir.endDate = params.endDate;
    searchObject.ir.startDate = params.startDate;
    searchObject.ir.start = params.start;
  }

  searchObject.ag.search = params.search;
  searchObject.agencyGuidance.search = params.search;
  searchObject.gov.search = params.search;
  searchObject.iac.search = params.search;
  searchObject.pending.search = params.search;
  searchObject.ir.search = params.search;
  searchObject.all.search = params.search;
  searchObject.search = params.search;

  return searchObject;
};

/**
 * Parse the query string into an object for the search form
 * @param queryString
 * @param defaultEdition - a default edition value to use, if present
 * @param allDocTypes - all document types, needed to limit types to particular tabs
 * @param allTopics - topic/agency list to populate the search form object if a topic is selected
 * @returns {{docSubTypes: [], dateRange: string, endDate: Date, topics: [], start: number, agencies: [], docTypes: [], archived: string, search: string, editions: *[], size: number, document_group: string, startDate: Date}}
 */
export const parseSearchQueryString = (queryString, defaultEdition, allDocTypes, allTopics) => {
  const dateFilters = ['endDate', 'startDate'];
  const numericProps = ['size', 'start'];
  const searchObject = {
    ...defaultSearchObject,
    editions: defaultEdition ? [defaultEdition] : []
  };

  const params = queryString?.startsWith('?') ? queryString.substring(1).split('&') : null;
  if (params && params.length) {
    params.forEach((param) => {
      const [name, value] = param.split('=');
      if (value?.length) {
        if (arrayFilters.includes(name)) {
          searchObject[name] = value.split(',');
        } else if (dateFilters.includes(name)) {
          searchObject[name] = parseISO(value);
        } else if (name === 'search') {
          searchObject.search = decodeURIComponent(value);
        } else if (name === 'dateRange') {
          searchObject.dateRange = value;
          if (value === 'sevenDays') {
            searchObject.startDate = sixDaysAgoFn();
          } else if (value === 'thirtyDays') {
            searchObject.startDate = thirtyDaysAgoFn();
          } else if (value === 'sixMonths') {
            searchObject.startDate = sixMonthsAgoFn();
          }
        } else if (numericProps.includes(name)) {
          // convert to a number, otherwise later addition turns into concatenation
          searchObject[name] = Number(value);
        } else if (name === 'archived') {
          searchObject.archived = value === 'true';
        } else if (name === 'fiscallySignificant') {
          searchObject.fiscallySignificant = value === 'true';
        }
      }
    });
  }

  // if there are topics selected, make sure all the agencies under the topic are selected, too
  if (searchObject.topics?.length) {
    const selectedTopics =
      allTopics?.filter((topic) => searchObject.topics.includes(`${topic.topicId}`)) ?? [];
    let implicitAgencies = [];
    selectedTopics.forEach((topic) => {
      const childAgencies = topic.children.map((child) => child.title_num);
      implicitAgencies = implicitAgencies.concat(childAgencies);
    });
    const agencies = new Set([...searchObject.agencies, ...implicitAgencies]);
    searchObject.agencies = Array.from(agencies);
  }

  // if Nonrule is selected, make sure all subtypes are selected
  if (searchObject.docTypes?.includes('NR')) {
    const nr = allDocTypes?.all?.docTypes.find((docType) => docType.doc_type === 'NR');
    searchObject.docSubTypes = nr?.children?.map((child) => child.name);
  }
  return searchObject;
};

/**
 * Count of the number of filters that apply to the search.
 * @param params
 * @returns Number
 */
export const getFilterCount = (params) => {
  return (
    // each agency selected is a filter (active for both repos)
    (params.agencies?.length ?? 0) +
    // if code is selected, each edition is a filter
    (params.scope.code ? params.editions?.length ?? 0 : 0) +
    // if register is selected, each doc type is a filter
    (params.scope.register ? params.docTypes?.length ?? 0 : 0) +
    // And if AF or NR are selected, each subtype is a filter
    (params.scope.register ? params.docSubTypes?.length ?? 0 : 0) +
    // if register is selected, then the date range is a filter
    // since we currently require a date range (which defaults to 1/1/2006-today),
    // this should always be 1 if register is selected.
    (params.scope.register && params.dateRange ? 1 : 0)
  );
};

// Key in LocalStorage
const recentSearchKey = 'RECENT_SEARCHES';
const maxRecentSearches = 5;

/**
 * Deep-equals check on search objects.
 */
const areSearchesEqual = (newSearch, savedSearch) => {
  const newQuery = buildSearchQueryString(newSearch, false);
  const savedQuery = buildSearchQueryString(savedSearch, true);
  return newQuery === savedQuery;
};

/**
 * Get the recent searches
 * @returns {any|*[]}
 */
export const getRecentSearches = () => {
  const recent = localStorage.getItem(recentSearchKey);
  return recent ? JSON.parse(recent) : [];
};

/**
 * Add a recent search to the recent searches.
 * Bump out the oldest if it would result in a list larger than maxRecentSearches
 * @param search
 */
export const addRecentSearch = (search) => {
  let searches = getRecentSearches().filter(
    (savedSearch) => !areSearchesEqual(search, savedSearch)
  );
  if (searches.length >= maxRecentSearches) {
    searches = [search].concat(searches.slice(0, maxRecentSearches - 1));
  } else {
    searches = [search].concat(searches);
  }
  localStorage.setItem(recentSearchKey, JSON.stringify(searches));
};

/**
 * Delete the recent searches
 */
export const clearRecentSearches = () => {
  localStorage.removeItem(recentSearchKey);
};

/**
 * Delete the recent search at the specified index
 * @param index index of search to delete
 */
export const removeRecentSearch = (index) => {
  const searches = getRecentSearches();
  searches.splice(index, 1);
  localStorage.setItem(recentSearchKey, JSON.stringify(searches));
};

/**
 * Convert the search filters into descriptive text for the "previous searches" list.
 * @param filters
 * @returns {string}
 */
export const filtersAsText = (filters) => {
  let filterStringParts = [];
  let scopeAndSearch = 'All Documents';
  if (filters.scope.code && !filters.scope.register) {
    scopeAndSearch = 'Admin Code Documents';
  } else if (!filters.scope.code && filters.scope.register) {
    scopeAndSearch = 'Register Documents';
  }
  if (filters.search?.length) {
    scopeAndSearch += ` containing "${filters.search}"`;
  }
  filterStringParts.push(scopeAndSearch);

  if (filters.agencies.length) {
    const length = filters.agencies.length;
    filterStringParts.push(`${length} ${length === 1 ? 'Agency' : 'Agencies'}`);
  }

  // filters that apply to Indiana Administrative Code only.
  if (filters.scope.code && filters.editions?.length) {
    const length = filters.editions.length;
    filterStringParts.push(`${length} ${length === 1 ? 'Edition' : 'Editions'}`);
  }

  // filters that apply to Indiana Register docs only
  if (filters.scope.register) {
    if (filters.docTypes?.length) {
      const length = filters.docTypes.length;
      filterStringParts.push(`${length} Document ${length === 1 ? 'Type' : 'Types'}`);
    }

    if (filters.docSubTypes?.length) {
      const sLength = filters.docSubTypes.length;
      filterStringParts.push(`${sLength} Document ${sLength === 1 ? 'Subtype' : 'Subtypes'}`);
    }

    // Posted date range
    if (filters.dateRange === dateRanges.custom) {
      filterStringParts.push(
        `posted ${format(parseISO(filters.startDate), displayFormat)} to ${format(
          parseISO(filters.endDate),
          displayFormat
        )}`
      );
    } else {
      let range = '';
      if (filters.dateRange === dateRanges.sevenDays) {
        range = 'posted in the last seven days';
      } else if (filters.dateRange === dateRanges.thirtyDays) {
        range = 'posted in the last thirty days';
      } else if (filters.dateRange === dateRanges.sixMonths) {
        range = 'posted in the last six months';
      }
      if (range) {
        filterStringParts.push(range);
      }
    }
  }
  return filterStringParts.join(', ');
};

/**
 * Build the search tab options for the tab bar and select (on mobile) with tab name and count
 * @param data
 * @returns {[{label: string, value: string},{label: string, value: string},{label: string, value: string},{label: string, value: string},{label: string, value: string}]}
 */
export const buildSearchTabOptions = (data) => {
  return [
    {
      value: 'pending',
      label: 'Pending Rules' + (data?.pending ? ` (${data.pending})` : '')
    },
    {
      value: 'iac',
      label: 'IAC' + (data?.iac ? ` (${data.iac})` : '')
    },
    {
      value: 'agencyGuidance',
      label: 'Agency Guidance' + (data?.agencyGuidance ? ` (${data.agencyGuidance})` : '')
    },
    {
      value: 'gov',
      label: 'Gov' + (data?.gov ? ` (${data.gov})` : '')
    },
    {
      value: 'ag',
      label: 'AG Opinions' + (data?.ag ? ` (${data.ag})` : '')
    },
    { value: 'ir', label: 'IR' + (data?.ir ? ` (${data.ir})` : '') },
    { value: 'all', label: 'All' + (data?.all ? ` (${data.all})` : '') }
  ];
};
