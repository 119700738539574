import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './AdminCodeArticle.module.css';

/**
 * Render the data for an Admin Code Article in an admin user's Admin page
 * @param article the article metadata
 * @param disabled whether to render a link or plain text
 * @returns {JSX.Element}
 * @constructor
 */
export const AdminCodeArticle = ({
  article,
  disabled = false,
  isChecked = false,
  toggleCheckbox
}) => {
  const linkText = `${article.edition_year} Title ${article.title_num}, Article ${article.article_num}`;
  return (
    <li className={styles.articleContainer}>
      <div className={styles.article}>
        <input
          type="checkbox"
          className={styles.checkbox}
          checked={isChecked}
          onChange={toggleCheckbox}
        />
        <div className={styles.articleLink}>
          {disabled ? (
            <span>{linkText}</span>
          ) : (
            <Link to={`/code/${article.edition_year}/${article.title_num}/${article.article_num}`}>
              {linkText}
            </Link>
          )}
        </div>
        <div className={styles.titleName}>{article.title_name}</div>
        <div className={styles.articleName}>{article.article_name}</div>
      </div>
    </li>
  );
};

AdminCodeArticle.propTypes = {
  article: PropTypes.shape({
    edition_year: PropTypes.number,
    title_num: PropTypes.string,
    title_name: PropTypes.string,
    article_num: PropTypes.string,
    article_name: PropTypes.string
  }),
  disabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  toggleCheckbox: PropTypes.func
};
