import { ListFilter } from 'components/ListFilter/ListFilter';
import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import PropTypes, { oneOfType } from 'prop-types';
import { useContext } from 'react';
import { docGroup } from 'util/documentUtil';

export const DocTypesFilter = ({
  handleFilterChange,
  handleToggle,
  selectedSubTypes,
  selectedTypes,
  tabId
}) => {
  const { documentTypes } = useContext(RefDataContext);
  return (
    <ListFilter
      childFieldName="docSubTypes"
      childPropName="name"
      childPropValue="name"
      dataList={documentTypes[docGroup[tabId]].docTypes}
      fieldName="docTypes"
      initialChildSelection={selectedSubTypes}
      initialSelection={selectedTypes}
      isActive={true}
      isFilterable={true}
      listName="Document Type"
      onToggle={handleToggle}
      onUpdate={handleFilterChange}
      pluralName="Document Types"
      propName="fullName"
      propValue="doc_type"
    />
  );
};

DocTypesFilter.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  selectedSubTypes: PropTypes.arrayOf(oneOfType([PropTypes.number, PropTypes.string])),
  selectedTypes: PropTypes.arrayOf(oneOfType([PropTypes.number, PropTypes.string])),
  tabId: PropTypes.string.isRequired
};
