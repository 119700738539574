import { DownloadRegisterPdfButton } from 'components/DownloadRegisterPdfButton/DownloadRegisterPdfButton';
import linkStyles from 'components/Link/Link.module.css';
import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { displayLsaDocNumber } from 'util/documentUtil';
import { indianaRegisterDocument } from 'util/shapes';

import styles from './DocumentItem.module.css';

export const DocumentItem = ({ item }) => {
  const { getAgencyName } = useContext(RefDataContext);

  return (
    <div className={styles.itemWrapper}>
      <div className={styles.itemHeader}>
        <div>
          <strong>Title {item.title_num}</strong> {getAgencyName(item.title_num)}
        </div>
      </div>
      <div className={styles.itemLinkGroup}>
        <div>
          <strong>DIN</strong> {item.din}
        </div>
        <div className={styles.itemLinks}>
          <Link
            aria-label="view HTML document in a new tab"
            className={linkStyles.buttonLink}
            rel="noopener noreferrer"
            target="_blank"
            to={`/register/${item.din}`}
          >
            HTML
          </Link>
          <DownloadRegisterPdfButton din={item.din} />
          <Link
            aria-label="view LSA document info in a new tab"
            className={linkStyles.buttonLink}
            rel="noopener noreferrer"
            target="_blank"
            to={`/lsadoc/${item.lsa_doc_num}?searchDoc=${item.din}`}
          >
            Related Documents
          </Link>
        </div>
      </div>
      <div className={styles.itemDetailGroup}>
        <div>
          <strong>Posted</strong> {item.date_posted?.split(',')[0] ?? 'NA'}
        </div>
        <div>
          <strong>Type</strong> {item.doc_type}
        </div>
        <div>
          <strong>LSA Doc</strong> {displayLsaDocNumber(item.lsa_doc_num)}
        </div>
        <div>
          <strong>Title</strong> {item.title_num}
        </div>
      </div>
      <div className={styles.digest} dangerouslySetInnerHTML={{ __html: item.digest }} />
      <hr className={styles.documentDivider} />
    </div>
  );
};

DocumentItem.propTypes = {
  item: indianaRegisterDocument.isRequired
};
