import { SegmentedControl } from 'components/SegmentedControl/SegmentedControl';
import PropTypes from 'prop-types';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { RiArrowRightLine } from 'react-icons/ri';
import { sixDaysAgoFn, sixMonthsAgoFn, thirtyDaysAgoFn, todayFn } from 'util/dateUtil';
import { dateRange, dateRanges } from 'util/shapes';

import styles from './RegisterFilterForm.module.css';

const today = todayFn();
const sixDaysAgo = sixDaysAgoFn();
const thirtyDaysAgo = thirtyDaysAgoFn();
const sixMonthsAgo = sixMonthsAgoFn();

const timeFilterItems = [
  { id: 'sevenDayRegisterFilter', label: '7 Days', value: dateRanges.sevenDays },
  { id: 'thirtyDayRegisterFilter', label: '30 Days', value: dateRanges.thirtyDays },
  { id: 'sixMonthRegisterFilter', label: '6 Months', value: dateRanges.sixMonths },
  { id: 'customRegisterFilter', label: 'Custom', value: dateRanges.custom }
];

export const RegisterFilterForm = ({ initialDateRange, setDisplayedDateRange }) => {
  const [selection, setSelection] = useState(initialDateRange.selection);
  const [range, setRange] = useState({
    from: initialDateRange.from ?? sixDaysAgo,
    to: initialDateRange.to ?? today
  });

  const handleFilterSelection = (e) => {
    const newSelection = e.target.value;
    let newRange = {
      ...range
    };

    // set the range. If 'custom', it will just retain whichever range was selected
    if (newSelection === dateRanges.sevenDays) {
      newRange = {
        from: sixDaysAgo,
        to: today
      };
    } else if (newSelection === dateRanges.thirtyDays) {
      newRange = {
        from: thirtyDaysAgo,
        to: today
      };
    } else if (newSelection === dateRanges.sixMonths) {
      newRange = {
        from: sixMonthsAgo,
        to: today
      };
    }
    setSelection(newSelection);
    setRange(newRange);

    if (setDisplayedDateRange) {
      setDisplayedDateRange({
        ...newRange,
        selection: newSelection
      });
    }
  };

  const setRangeDate = (field, date) => {
    setRange((oldRange) => {
      return {
        ...oldRange,
        [field]: date
      };
    });
  };

  const submitCustomRange = (e) => {
    e.preventDefault();
    if (setDisplayedDateRange) {
      setDisplayedDateRange({ ...range, selection: dateRanges.custom });
    }
  };

  return (
    <form onSubmit={submitCustomRange} className={styles.registerFilterForm}>
      <p>Showing items from the last</p>
      <SegmentedControl
        ariaLabel="filter documents by date"
        controlName="timeFilter"
        items={timeFilterItems}
        onSelect={handleFilterSelection}
        selectedVal={selection}
      />
      <div
        className={
          selection === dateRanges.custom
            ? `${styles.customRangeWrapper} ${styles.isOpen}`
            : styles.customRangeWrapper
        }
      >
        <div className={styles.customRange}>
          <div className={styles.dateContainer}>
            <label htmlFor="filterStartDate">Start Date</label>
            <DatePicker
              aria-label="Start Date"
              selected={range.from}
              onChange={(date) => setRangeDate('from', date)}
              id="filterStartDate"
              calendarClassName="customDatePicker"
            />
          </div>
          <div>to</div>
          <div className={styles.dateContainer}>
            <label htmlFor="filterEndDate">End Date</label>
            <DatePicker
              aria-label="End Date"
              selected={range.to}
              onChange={(date) => setRangeDate('to', date)}
              id="filterEndDate"
              calendarClassName="customDatePicker"
              popperPlacement="bottom-end"
            />
          </div>
          <button
            type="submit"
            className={styles.submitButton}
            aria-label="submit custom date range"
          >
            <RiArrowRightLine size="2rem" color="white" title="submit dates icon" />
          </button>
        </div>
      </div>
    </form>
  );
};

RegisterFilterForm.propTypes = {
  initialDateRange: PropTypes.shape({
    from: PropTypes.instanceOf(Date).isRequired,
    selection: dateRange,
    to: PropTypes.instanceOf(Date).isRequired
  }),
  setDisplayedDateRange: PropTypes.func
};
