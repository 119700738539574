import { FeatureFlagContext } from 'components/FeatureFlags/FeatureFlagContext/FeatureFlagContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';

/**
 * Check if a feature is enabled.
 * @param children Components to render if the flag is enabled.
 * @param fallback OPTIONAL component to render if the flag is NOT enabled (otherwise, will render null)
 * @param feature the name of the flag to check.
 * @returns {*|null}
 * @constructor
 */
export const FeatureEnabled = ({ children, fallback, feature }) => {
  const { flags } = useContext(FeatureFlagContext);

  // Make an explicit check for the boolean true, because we don't trust
  // the data, and it might be the truthy string "false".
  if (flags && flags[feature] === true) {
    return children;
  } else if (fallback) {
    return fallback;
  }
  return null;
};

FeatureEnabled.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
  feature: PropTypes.string.isRequired
};
