import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { MainContentWrapper } from 'components/MainContentWrapper/MainContentWrapper';
import { ContactForm } from 'pages/Contact/ContactForm/ContactForm';
import { Helmet } from 'react-helmet-async';

import { About } from './About/About';

export const Contact = () => {
  return (
    <>
      <About />
      <MainContentWrapper>
        <Helmet>
          <title>About / Contact Us | IARP</title>
        </Helmet>
        <AccentHeader level={1}>Contact Us</AccentHeader>
        <p>
          Please provide your name and email address, then indicate the general topic of your
          question/suggestion so we can route your message within our team for response. All fields
          required.
        </p>
        <ContactForm />
        <p>
          <strong>Notice</strong> Public websites are often targeted by SPAM email accounts, so we
          use the Captcha tool to prevent automated emails (robots).
        </p>
      </MainContentWrapper>
    </>
  );
};
