import { Auth } from 'aws-amplify';
import toast from 'react-hot-toast';

// links
export const API = `${process.env.REACT_APP_API_URL}/api`;
export const SEARCH = `${process.env.REACT_APP_API_URL}/search`;
export const OPEN_SEARCH = `${process.env.REACT_APP_API_URL}/openSearch`;

// API functions

const defaultApiHeaders = {
  'Content-Type': 'application/json'
};

export const getData = async (dataURL, accessToken = false, method = 'GET') => {
  const headers = { ...defaultApiHeaders };
  if (accessToken) {
    headers.Authorization = 'Bearer ' + accessToken;
  }

  const res = await fetch(dataURL, {
    method: method,
    headers: headers
  });
  if (res.ok) {
    return res.json();
  }

  // Temp hack, as we don't get 404s right now
  const error = await res.json();
  // need a string for Error.
  let code = `${res.status}`;
  if (
    code === '500' &&
    error?.message?.indexOf('There should only be 1 document in the database') > -1
  ) {
    code = '404';
  }
  return Promise.reject({
    code,
    message: error?.message
  });
};

export const postData = async (dataURL, accessToken = false, formData) => {
  const headers = { ...defaultApiHeaders };

  if (accessToken) {
    headers.Authorization = 'Bearer ' + accessToken;
  }

  const res = await fetch(dataURL, {
    body: JSON.stringify(formData),
    headers: headers,
    method: 'POST'
  });
  if (res.ok) {
    return res.json();
  }

  return Promise.reject({
    code: res.status,
    message: await res.json()
  });
};

export function getAsText(dataURL) {
  return fetch(dataURL, {
    method: 'GET'
  }).then((res) => {
    if (res.ok) {
      return res.text();
    }
    return Promise.reject({ status: res.status, message: 'Network response not ok' });
  });
}

/**
 * Get the logged-in user's current token, or re-direct for login.
 *
 * @param {object} history The history object to use for re-directing
 * @returns A Promise that resolves to the JWT Token from the user's
 * Amazon Cognito ID Token
 */
export async function getToken(history) {
  try {
    const cognitoSession = await Auth.currentSession();
    return cognitoSession?.getIdToken()?.getJwtToken();
  } catch (error) {
    console.error('getToken error handler got called');
    console.error(error);
    toast.error(error?.message ?? 'Login expired.', {
      position: 'top-right'
    });
    history.push('/myIAR/login', { from: history.location.pathname });
  }
}

/**
 * Get the auth token and use it to make an API call.
 *
 * Note: the getToken function may re-direct for login before the
 * API call is made.
 *
 * @param {object} history react-router history object
 * @param {string} url API endpoint to access with token
 * @param {*} defaultValue return value if token is not present
 * @returns promise that resolves to the result of calling the url
 */
export const getAuthData = async (history, url, defaultValue = null) => {
  const token = await getToken(history);
  if (token) {
    return getData(url, token);
  }
  return defaultValue;
};
