import PropTypes from 'prop-types';

import styles from './AccentHeader.module.css';

export const AccentHeader = ({ additionalClassName, children, id, level, subtitle, underline }) => {
  const Tag = `h${level}`;
  let headerClassName = styles.header;
  let underlineClassName = styles.underline;
  if (underline === 'light' || underline === 'subtle') {
    underlineClassName += ' ' + styles[underline];
  }
  if (additionalClassName) {
    headerClassName += ' ' + additionalClassName;
  }

  return (
    <div className={styles.accent}>
      <Tag className={headerClassName} id={id}>
        {children}
        {subtitle ? <span>{subtitle}</span> : null}
      </Tag>
      {underline === 'none' ? null : <div className={underlineClassName} />}
    </div>
  );
};

AccentHeader.propTypes = {
  additionalClassName: PropTypes.string,
  children: PropTypes.any.isRequired,
  id: PropTypes.string,
  level: PropTypes.number.isRequired,
  subtitle: PropTypes.string,
  underline: PropTypes.oneOf(['accent', 'light', 'none', 'subtle'])
};

AccentHeader.defaultProps = {
  underline: 'accent'
};
