import { Colors, Select } from 'colosseum';
import { AdminContext } from 'components/AdminContext/AdminContext';
import React, { useContext } from 'react';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { documentStagePrivate, documentStagePrivateReady } from 'util/shapes';

import styles from './AdminBanner.module.css';

const AdminBanner = () => {
  const { docStage, setDocStage } = useContext(AdminContext);
  const updateDocStage = (option) => setDocStage(option.value);

  const docStageData = [
    {
      value: documentStagePrivate,
      label: 'Show Private'
    },
    {
      value: documentStagePrivateReady,
      label: 'Show Private Ready'
    }
  ];

  return (
    <div className={styles.banner}>
      <Link className={`${styles.link} ${styles.label}`} to="/myIAR/home/admin">
        <RiArrowLeftSLine size="1.5rem" /> Admin
      </Link>
      <div className={styles.stagePicker}>
        <span className={styles.label}>Admin View</span>
        <Select
          compact={true}
          options={docStageData}
          value={docStageData.find((data) => data.value === docStage)}
          onChange={updateDocStage}
          customStyles={{
            control: {
              backgroundColor: Colors.accent,
              ':hover': {
                backgroundColor: Colors.accent
              }
            },
            option: {
              backgroundColor: Colors.accent
            }
          }}
        />
      </div>
    </div>
  );
};

export default AdminBanner;
