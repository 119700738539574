import PropTypes from 'prop-types';
import { RiSearchLine } from 'react-icons/ri';

import styles from './AgencyNameFilter.module.css';

/**
 * Simple text filter control.
 *
 * @param onFilterChange Function to call when the filter value changes
 * @returns {JSX.Element}
 * @constructor
 */
export const AgencyNameFilter = ({ onFilterChange }) => {
  return (
    <div className={styles.filterAgencies}>
      <RiSearchLine className={styles.filterIcon} title="Search icon" />
      <input
        type="search"
        aria-label="Filter Topic/Agency List"
        placeholder="Search topics/agencies..."
        className={styles.filterAgenciesInput}
        onChange={(e) => onFilterChange(e.target.value ? e.target.value.toLowerCase() : '')}
      />
    </div>
  );
};

AgencyNameFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired
};
