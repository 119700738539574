import { Loading } from 'colosseum';
import { AdminContext } from 'components/AdminContext/AdminContext';
import { DownloadRegisterPdfButton } from 'components/DownloadRegisterPdfButton/DownloadRegisterPdfButton';
import { MainContentWrapper } from 'components/MainContentWrapper/MainContentWrapper';
import { SearchButton } from 'components/SearchButton/SearchButton';
import { useIrDocument } from 'hooks/documentHooks';
import { Error404 } from 'pages/Error/Error404';
import { GeneralError } from 'pages/Error/GeneralError';
import { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import { displayLsaDocNumber } from 'util/documentUtil';
import { dateRanges, documentStagePrivate, documentStagePrivateReady } from 'util/shapes';

import styles from './RegisterDocument.module.css';

export const RegisterDocument = () => {
  const { din } = useParams();
  const { data, error, isLoading } = useIrDocument(din);
  const [updatedDocStage, setUpdatedDocStage] = useState(false);
  const { setDocStage, updateBannerVisible } = useContext(AdminContext);
  const docTitle = data
    ? `${data.title_num} ${displayLsaDocNumber(data.lsa_doc_num)} | IARP`
    : 'Register Document | IARP';

  // If necessary, open the admin banner once, when irdoc data is available.
  if (data?.doc_stage && !updatedDocStage) {
    if (data?.doc_stage === documentStagePrivate || data?.doc_stage === documentStagePrivateReady) {
      updateBannerVisible(true);
      setDocStage(data?.doc_stage);
    }
    setUpdatedDocStage(true);
  }

  return (
    <>
      {error?.code === '404' ? (
        <Error404 />
      ) : error ? (
        <GeneralError />
      ) : (
        <MainContentWrapper
          additionalClassName={isLoading ? undefined : styles.registerPageWrapper}
        >
          <Helmet>
            <title>{docTitle}</title>
          </Helmet>
          <SearchButton
            params={{
              dateRange: dateRanges.sevenDays,
              scope: { register: true }
            }}
          />
          <div>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <span className={styles.pdfWrapper}>
                  <DownloadRegisterPdfButton din={din} />
                </span>
                <article className="documentWrapper">
                  <div dangerouslySetInnerHTML={{ __html: data.doc_html }} />
                  <div className="documentPostedDate">
                    Posted:{' '}
                    <span dangerouslySetInnerHTML={{ __html: data.date_posted?.split(',')[0] }} />{' '}
                    by Legislative Services Agency
                  </div>
                </article>
              </>
            )}
          </div>
        </MainContentWrapper>
      )}
    </>
  );
};
