import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './AppFooter.module.css';

export const AppFooter = ({ sticky }) => {
  return (
    <footer className={sticky ? `${styles.footer} ${styles.sticky}` : styles.footer}>
      <nav className={styles.footerLinks} aria-label="Footer">
        <div className={styles.linkColumn}>
          <ExternalLink
            className={styles.footerLink}
            linkText="Administrative Drafting Manual"
            url="http://iac.iga.in.gov/iac//IACDrftMan.pdf"
          />
          <ExternalLink
            className={styles.footerLink}
            linkText="General Assembly"
            url="https://iga.in.gov"
          />
          <ExternalLink
            className={styles.footerLink}
            linkText="Agency List"
            url="https://www.in.gov/core/find_agency.html"
          />
        </div>
        <div className={styles.linkColumn}>
          <ExternalLink
            className={styles.footerLink}
            linkText="LSA Rulemaking Templates"
            url="https://www.in.gov/omb/rule-approval-process/lsa-templates/"
          />
          <Link to="/contact" className={styles.footerLink}>
            Contact/About Us
          </Link>
        </div>
      </nav>
    </footer>
  );
};

AppFooter.propTypes = {
  sticky: PropTypes.bool
};
