import { Auth } from 'aws-amplify';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { AuthContext } from 'components/AuthContext/AuthContext';
import { Button } from 'components/Button/Button';
import { InputField } from 'components/InputField/InputField';
import { PasswordField } from 'components/InputField/PasswordField';
import { useInputVisitedMarker } from 'hooks/useInputVisitedMarker';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';

import styles from '../../MyIAR.module.css';

export const Password = () => {
  const { user } = useContext(AuthContext);
  const [visitedFields, markVisited, markAllVisited, resetVisited] = useInputVisitedMarker([
    'currentPassword',
    'newPassword',
    'username'
  ]);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    markAllVisited();
    if (e.target.checkValidity()) {
      const form = new FormData(e.target);
      const currentPassword = form.get('currentPassword');
      const newPassword = form.get('newPassword');
      try {
        await Auth.changePassword(user, currentPassword, newPassword);
        e.target.reset();
        resetVisited();
        toast.success('Password changed!', { position: 'top-right' });
      } catch (error) {
        toast.error(error?.message ?? 'Unable to change password', { position: 'top-right' });
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Change Password | MyIAR | IARP</title>
      </Helmet>
      <AccentHeader level={2} underline="subtle">
        Change Your Password
      </AccentHeader>
      <form onSubmit={handleChangePassword} noValidate className={styles.simpleForm}>
        <InputField
          ariaLabel="Enter your email address"
          autocompleteType="username"
          errorLabel="Required"
          handleBlur={markVisited}
          id="username"
          initialValue={user?.attributes?.email}
          isVisited={visitedFields.username}
          label="Username"
          name="username"
          readonly={true}
        />
        <PasswordField
          ariaLabel="Enter your current password"
          autocompleteType="current-password"
          errorLabel="Required"
          handleBlur={markVisited}
          id="current-password-field"
          isVisited={visitedFields.currentPassword}
          label="Current Password"
          name="currentPassword"
        />
        <PasswordField
          ariaLabel="Enter a new password"
          autocompleteType="new-password"
          errorLabel="Required"
          handleBlur={markVisited}
          id="new-password-field"
          isVisited={visitedFields.newPassword}
          label="New Password"
          name="newPassword"
        />
        <div className={styles.formControls}>
          <Button type="submit">Change Password</Button>
        </div>
      </form>
    </>
  );
};
