import PropTypes from 'prop-types';

/**
 * Parse the name of the article so that the "ARTICLE N." portion, if it exists, can be bolded
 * @param name the name of the article
 * @returns {JSX.Element}
 * @constructor
 */
export const CodeArticleName = ({ name }) => {
  const regexp = /(ARTICLE\s[\d.]+)\s(.*)/g;
  const match = regexp.exec(name);
  let articleNumber = match?.length > 1 ? match[1] : null;
  let articleName = match?.length > 2 ? match[2] : name;
  if (articleNumber?.endsWith('.')) {
    articleNumber = articleNumber.substring(0, articleNumber.length - 1);
  }

  return (
    <>
      {articleNumber ? <strong>{articleNumber}</strong> : ''} {articleName}
    </>
  );
};

CodeArticleName.propTypes = {
  name: PropTypes.string.isRequired
};
