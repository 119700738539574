import { SpinningButton } from 'components/SpinningButton/SpinningButton';
import { useIrDocument } from 'hooks/documentHooks';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { downloadPDF } from 'util/documentUtil';

import styles from './DownloadRegisterPdfButton.module.css';

export const DownloadRegisterPdfButton = ({ din }) => {
  // We want to fetch manually, when the user clicks
  const { refetch, remove } = useIrDocument(din, true);
  const [renderingPDF, setRenderingPDF] = useState(false);
  const documentRef = useRef(null);
  const documentBodyRef = useRef(null);
  const postedDateRef = useRef(null);

  const handleClick = () => {
    setRenderingPDF(true);
    refetch().then(({ data }) => {
      if (data && documentRef.current) {
        // set the innerHTML via standard DOM manipulation, which avoids rendering oddities
        documentBodyRef.current.innerHTML = data.doc_html;
        postedDateRef.current.innerHTML = data.date_posted?.split(',')[0];
        downloadPDF(documentRef.current, `${din}.pdf`, () => {
          // Remove the query from cache, because if the user clicks the button again, within the cache time, nothing happens.
          // This is a potential problem if the user accidentally deleted the file, or didn't realize where it saved.
          // Mostly, it's just very unexpected behavior, and that's bad.
          remove();
          setRenderingPDF(false);
        });
      }
    });
  };

  return (
    <>
      <SpinningButton
        ariaLabel="Download as PDF"
        ariaSpinningLabel="Downloading PDF"
        buttonLabel="PDF"
        onClick={handleClick}
        isSpinning={renderingPDF}
      />
      <span className={styles.hiddenDocument}>
        <article className="documentWrapper print" ref={documentRef}>
          <div ref={documentBodyRef} />
          <div className="documentPostedDate">
            Posted: <span ref={postedDateRef} /> by Legislative Services Agency
          </div>
        </article>
      </span>
    </>
  );
};

DownloadRegisterPdfButton.propTypes = {
  din: PropTypes.string
};
