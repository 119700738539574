import { ActionsAndOpinions } from 'pages/Home/ActionsAndOpinions/ActionsAndOpinions';
import { Helmet } from 'react-helmet-async';

import { Hero } from './Hero/Hero';
import { RegisterDocuments } from './RegisterDocuments/RegisterDocuments';
import { Upcoming } from './Upcoming/Upcoming';

export const Home = () => {
  return (
    <>
      <Helmet>
        <title>Indiana Administrative Rules and Policies</title>
      </Helmet>
      <Hero />
      <Upcoming />
      <RegisterDocuments />
      <ActionsAndOpinions />
    </>
  );
};
