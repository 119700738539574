import { Loading, Select } from 'colosseum';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { CodeTitleAccordion } from 'components/DocumentAccordion/CodeTitleAccordion';
import { ErrorMessage } from 'components/ErrorMessage/ErrorMessage';
import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { MainContentWrapper } from 'components/MainContentWrapper/MainContentWrapper';
import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import { SearchButton } from 'components/SearchButton/SearchButton';
import { useTitleList } from 'hooks/documentHooks';
import { Article } from 'pages/Code/Article/Article';
import { Error404 } from 'pages/Error/Error404';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router';

import styles from './Code.module.css';

/**
 * Main display for Indiana Administrative Code.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const Code = ({ scrollTo }) => {
  const history = useHistory();
  const { year, title: titleParam, article } = useParams();
  const [selectedEdition, setSelectedEdition] = useState(null);
  const [invalidEdition, setInvalidEdition] = useState(false);
  const { currentEdition, editions } = useContext(RefDataContext);
  const {
    data: titleData,
    error: titleError,
    isLoading: titleLoading
  } = useTitleList(year || selectedEdition?.value);

  /*
   * Handle changes to the URL to make sure the right edition is selected and
   * loaded. This kicks into effect when handling the year param and when the
   * list of editions loads.
   */
  useEffect(() => {
    setInvalidEdition(false);
    if (year) {
      // There's a year param, so find the item in the list of editions
      const initialSelected = editions.find((edition) => edition.value === year);
      if (initialSelected) {
        setSelectedEdition(initialSelected);
      } else {
        setInvalidEdition(true);
      }
    } else {
      // No year param, default to the latest edition, and set the URL
      history.replace(`/code/${currentEdition}`);
    }
  }, [currentEdition, editions, history, year]);

  /**
   * Change the selected edition, and update the URL, which triggers the
   * `useEffect` above.
   *
   * @param val the selected edition value
   */
  const handleEditionChange = (val) => {
    if (val) {
      history.push(`/code/${val.value}`);
    }
  };

  return (
    <>
      {invalidEdition ? (
        <Error404 />
      ) : (
        <MainContentWrapper
          additionalClassName={
            !article ? styles.codeWrapper : `${styles.codeWrapper} ${styles.articleOpen}`
          }
        >
          <Helmet>
            <title>Indiana Administrative Code | IARP</title>
          </Helmet>
          <SearchButton params={{ editions: [], scope: { code: true } }} />
          <div className={styles.headerContainer}>
            <AccentHeader level={1} underline="subtle">
              Indiana Administrative Code
            </AccentHeader>
            <Select
              compact={true}
              onChange={handleEditionChange}
              options={editions}
              value={selectedEdition}
            />
          </div>
          <div className={styles.codeNav}>
            {titleLoading ? (
              <div className={styles.titlesLoadingWrapper}>
                <Loading screenreaderText="Loading Indiana Administrative Code List" />
              </div>
            ) : !year || titleError ? (
              <ErrorMessage>Unable to load title navigation.</ErrorMessage>
            ) : (
              <>
                {titleData.map((title) => (
                  <CodeTitleAccordion
                    key={`iac-${year}-title-${title.title_num}`}
                    title={title}
                    year={year}
                  />
                ))}
              </>
            )}
          </div>
          <div className={styles.article}>
            {article ? (
              <>
                {/*
                <Link
                  aria-label={`Back to IAC navigation for ${year}`}
                  className={`${styles.codeBackLink} ${linkStyles.backLink}`}
                  to={`/code/${year}/${titleParam}`}
                >
                  <RiArrowLeftSLine size="1rem" /> Back to Indiana Administrative Code
                </Link>
                */}
                <Article
                  article={article}
                  scrollTo={scrollTo}
                  title={titleParam}
                  titleData={titleData}
                  year={year}
                />
              </>
            ) : (
              <div className={styles.landing}>
                The Indiana Administrative Code (IAC) is the electronic compilation of all permanent
                administrative rules. The first official edition of the IAC was published in 1979,
                with subsequent editions published in paper format through 2005. After June 30,
                2006, the IAC has been updated weekly only in electronic format on the{' '}
                <ExternalLink linkText="General Assembly's website" url="https://iga.in.gov/" /> as
                permanent rulemaking becomes effective.
              </div>
            )}
          </div>
        </MainContentWrapper>
      )}
    </>
  );
};

Code.propTypes = {
  scrollTo: PropTypes.func
};
