import { AdminContext } from 'components/AdminContext/AdminContext';
import { Button } from 'components/Button/Button';
import { useDocStageMutation } from 'hooks/documentHooks';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { documentStagePrivate, documentStagePrivateReady } from 'util/shapes';

import { AdminCodeArticle } from '../AdminCodeArticle/AdminCodeArticle';
import styles from './AdminCodeArticles.module.css';

const AdminCodeArticles = ({ articles, stage, id }) => {
  const [checkedOnes, setCheckedOnes] = useState([]);
  const { docStage } = useContext(AdminContext);
  const { isPending, mutate } = useDocStageMutation();

  const toggleCheckbox = (idx) => {
    const newChecked = [...checkedOnes];
    newChecked[idx] = !newChecked[idx];
    setCheckedOnes(newChecked);
  };
  const allChecked =
    checkedOnes.length > 0 &&
    checkedOnes.length === articles?.length &&
    checkedOnes.every((box) => box);
  const allUnChecked = checkedOnes.every((box) => !box);
  const toggleAllChecked = () => {
    setCheckedOnes(articles?.map(() => !allChecked) ?? []);
  };
  const uncheckAll = () => {
    setCheckedOnes(articles?.map(() => false) ?? []);
  };
  const promoteSelected = () => {
    const ids = articles
      ?.filter((doc, idx) => checkedOnes[idx])
      ?.map((article) => article.article_id);
    uncheckAll();
    mutate({ articleIds: ids, promote: true });
  };

  return (
    <>
      <div className={styles.headerRow}>
        <input
          type="checkbox"
          id={id}
          value={allChecked}
          checked={allChecked}
          onChange={toggleAllChecked}
        />
        <label htmlFor={id} className={styles.labelAll}>
          Select All
        </label>
        <Button disabled={allUnChecked || isPending} action={promoteSelected}>
          Promote Selected
        </Button>
      </div>
      <ul className={styles.docList}>
        {articles?.map((article, idx) => (
          <AdminCodeArticle
            key={`${article.edition_year}_${article.title_num}_${article.article_num}`}
            article={article}
            disabled={docStage !== stage}
            isChecked={checkedOnes[idx]}
            toggleCheckbox={() => toggleCheckbox(idx)}
          />
        ))}
      </ul>
    </>
  );
};

AdminCodeArticles.propTypes = {
  articles: PropTypes.array,
  stage: PropTypes.oneOf([documentStagePrivate, documentStagePrivateReady]),
  id: PropTypes.string.isRequired
};

export default AdminCodeArticles;
