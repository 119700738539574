import { CheckboxFilter } from 'pages/Search/CheckboxFilter/CheckboxFilter';
import PropTypes from 'prop-types';

export const IncludeArchivedFilter = ({ handleFilterChange, isChecked }) => {
  return (
    <CheckboxFilter
      handleFilterChange={handleFilterChange}
      isChecked={isChecked}
      label="Include Archived"
      name="archived"
    />
  );
};

IncludeArchivedFilter.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool
};
