import linkStyles from 'components/Link/Link.module.css';
import Spinner from 'components/Spinner/Spinner';
import PropTypes from 'prop-types';

import styles from './SpinningButton.module.css';

export const SpinningButton = ({
  ariaLabel,
  ariaSpinningLabel,
  buttonLabel,
  isSpinning,
  onClick
}) => {
  return (
    <button
      aria-label={isSpinning ? ariaSpinningLabel : ariaLabel}
      className={`${linkStyles.buttonLink} ${styles.downloadButton}`}
      disabled={isSpinning}
      onClick={onClick}
    >
      {isSpinning ? <Spinner /> : buttonLabel}
    </button>
  );
};

SpinningButton.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  ariaSpinningLabel: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  isSpinning: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};
