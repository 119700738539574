import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { MainContentWrapper } from 'components/MainContentWrapper/MainContentWrapper';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import styles from './Error.module.css';

export const Error404 = ({ subPage }) => {
  const location = useLocation();
  const isCode = location.pathname.startsWith('/code');
  const isRegister = location.pathname.startsWith('/register');

  const WrapperTag = subPage ? 'div' : MainContentWrapper;
  return (
    <WrapperTag>
      <Helmet>
        <title>Page Not Found | IARP</title>
      </Helmet>
      <AccentHeader level={subPage ? 2 : 1}>
        <strong>404</strong> Page Not Found
      </AccentHeader>
      <div>
        <p className={styles.help}>We are not able to find the page you are looking for.</p>
        {isRegister || isCode ? (
          <>
            <p className={styles.primaryHelp}>
              Based on the url it looks like you were looking for a document in the Indiana{' '}
              {isCode ? 'Administrative Code' : 'Register'}.
            </p>
            <Link
              to={isCode ? '/code' : '/register'}
              className={`${styles.helpfulLink} ${styles.primary}`}
            >
              Go to Indiana {isCode ? 'Administrative Code' : 'Register'}
            </Link>
          </>
        ) : null}
        <p className={styles.help}>Here are a few links that might be helpful</p>
        <ul className={styles.helpfulLinks}>
          <li>
            <Link to="/" className={styles.helpfulLink}>
              Home
            </Link>
          </li>
          {!isCode ? (
            <li>
              <Link to="/code" className={styles.helpfulLink}>
                Indiana Administrative Code
              </Link>
            </li>
          ) : null}
          {!isRegister ? (
            <li>
              <Link to="/register" className={styles.helpfulLink}>
                Indiana Register
              </Link>
            </li>
          ) : null}
          <li>
            <Link to="/search" className={styles.helpfulLink}>
              Search
            </Link>
          </li>
        </ul>
      </div>
    </WrapperTag>
  );
};

Error404.propTypes = {
  subPage: PropTypes.bool
};
