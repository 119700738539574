import PropTypes from 'prop-types';
import { useLayoutEffect, useRef } from 'react';

import styles from './FadeScrollPanel.module.css';

/**
 * Panel that, when scrolling is available, will fade-out the contents on the bottom (if there is more content below),
 * or the top (if there is content above), or both.
 *
 * @param additionalClassName additional class to apply to the container
 * @param children JSX element(s)
 * @param tag HTML tag to use, defaults to "div"
 * @returns {JSX.Element}
 * @constructor
 */
export const FadeScrollPanel = ({ additionalClassName, children, tag }) => {
  const panelRef = useRef(null);
  const Tag = tag ?? 'div';

  /**
   * Handles adding/removing masks at the top and bottom of the panel content in order to
   * help indicate that there is more text. This is useful on mobile, especially, when the
   * browser doesn't show a scrollbar.
   */
  const handleFadeClasses = () => {
    const scrollHeight = panelRef.current.scrollHeight;
    const clientHeight = panelRef.current.clientHeight;
    const isScrollable = scrollHeight > clientHeight;
    const scrollTop = panelRef.current.scrollTop;

    if (!isScrollable) {
      panelRef.current.classList.remove(styles.bottomOverflowing, styles.topOverflowing);
      return;
    }

    // With some screen resolutions & zooming, there's some rounding. So, add a pixel to make up for that.
    const isAtBottom = scrollHeight <= clientHeight + scrollTop + 1;
    const isAtTop = isAtBottom ? false : scrollTop === 0;

    panelRef.current.classList.toggle(styles.bottomOverflowing, !isAtBottom);
    panelRef.current.classList.toggle(styles.topOverflowing, !isAtTop);
  };

  useLayoutEffect(() => {
    panelRef.current.addEventListener('scroll', handleFadeClasses);
    panelRef.current.addEventListener('resize', handleFadeClasses);
    window.addEventListener('resize', handleFadeClasses);
    handleFadeClasses();
    const panel = panelRef.current;
    return () => {
      panel.removeEventListener('scroll', handleFadeClasses);
      panel.removeEventListener('resize', handleFadeClasses);
      window.removeEventListener('resize', handleFadeClasses);
    };
  });

  return (
    <Tag
      className={
        additionalClassName ? `${additionalClassName} ${styles.panelContent}` : styles.panelContent
      }
      ref={panelRef}
    >
      {children}
    </Tag>
  );
};

FadeScrollPanel.propTypes = {
  additionalClassName: PropTypes.string,
  children: PropTypes.any,
  tag: PropTypes.string
};
