import { AuthContext } from 'components/AuthContext/AuthContext';
import { formatISO, isSameDay, parseISO } from 'date-fns';

const { useState, useEffect, useContext } = require('react');

export const useLastVisited = (localStorageKey) => {
  const [storageKey] = useState(localStorageKey);
  const [visitBeforeLast, setVisitBeforeLast] = useState();
  const { email } = useContext(AuthContext);

  // if the current user's email is set,
  // update last viewed timestamps for use on the Dashboard.
  useEffect(() => {
    if (email) {
      const keyLastVisit = `${email}-${storageKey}`;
      const keyVisitBeforeLast = `${email}-${storageKey}-twoAgo`;

      // If a doc was displayed as new when the user got to the
      // dashboard today, we want it to remain marked new all day
      // (not to disappear as soon as they view the first new doc
      // and return to the dashboard)
      //
      // 1. Keep track of "visitBeforeLast" and "lastVisit"
      //    - If today's date equals "lastVisit" date, do not
      //      update "visitBeforeLast"
      //    - If today's date is after "lastVisit" date,
      //      move "lastVisit" to "visitBeforeLast" and put
      //      today's date in "lastVisit"
      // 2. Show docs as new based on "visitBeforeLast"

      // get previous logins
      const storedLastVisit = localStorage.getItem(keyLastVisit);
      const storedVisitBeforeLast = localStorage.getItem(keyVisitBeforeLast);

      const today = new Date();

      if (storedLastVisit && !isSameDay(today, parseISO(storedLastVisit))) {
        setVisitBeforeLast(parseISO(storedLastVisit));
        localStorage.setItem(keyVisitBeforeLast, storedLastVisit);
      } else {
        setVisitBeforeLast(storedVisitBeforeLast && parseISO(storedVisitBeforeLast));
      }

      localStorage.setItem(keyLastVisit, formatISO(today));
    }
  }, [email, storageKey]);

  return visitBeforeLast;
};
