const notices = 'notices';
const proposedRules = 'proposedRules';
const finalRules = 'finalRules';
const nonrulePolicies = 'nonrulePolicies';
const governorsActions = 'governorsActions';

// Mapping document types to the category.
// These do not quite align with the groupings on the main Register page.
const registerMap = {
  AP: notices,
  AR: notices,
  CH: notices,
  LN: notices,
  NC: notices,
  ON: notices,
  PH: notices,
  RC: notices,
  RN: notices,
  WD: notices,
  FC: proposedRules,
  FN: proposedRules,
  IN: proposedRules,
  PR: proposedRules,
  RP: proposedRules,
  RR: proposedRules,
  SC: proposedRules,
  SN: proposedRules,
  AC: finalRules,
  ER: finalRules,
  FR: finalRules,
  IF: finalRules,
  RF: finalRules,
  AT: governorsActions,
  DG: governorsActions,
  EO: governorsActions,
  GP: governorsActions,
  OG: governorsActions,
  NR: nonrulePolicies
};

const searchTabs = {
  finalRules: 'ir',
  governorsActions: 'gov',
  nonrulePolicies: 'agencyGuidance',
  notices: 'ir',
  proposedRules: 'ir'
};

/**
 * Get the document types for a given grouping as a comma-delimited string
 * @param group The group (one of 'notices', 'proposedRules', 'finalRules', 'governorsActions', or 'nonrulePolicies'
 * @returns {string}
 */
export const getDocTypeQueryParam = (group) => {
  const list = [];
  for (const [key, val] of Object.entries(registerMap)) {
    if (val === group) {
      list.push(key);
    }
  }
  return `${list.join(',')}#${searchTabs[group]}`;
};

/**
 * Get a count of IR documents in the groupings for "Current Register"
 * @param data The list of IR docs in the period (currently 7 days).
 * @returns {[{count: number, type: string, group: string},{count: number, type: string, group: string},{count: number, type: string, group: string},{count: number, type: string, group: string},{count: number, type: string, group: string}]}
 */
export const getDocCounts = (data) => {
  const counts = {
    notices: 0,
    proposedRules: 0,
    finalRules: 0,
    nonrulePolicies: 0,
    governorsActions: 0
  };

  // the first element of the array is the "All Documents" category.
  // loop through that for the counts.
  data?.[0]?.docs?.forEach((doc) => {
    const prop = registerMap[doc.doc_type];
    if (prop) {
      counts[prop]++;
    }
  });

  return [
    { count: counts.notices, group: notices, type: 'Notices' },
    { count: counts.proposedRules, group: proposedRules, type: 'Proposed Rules' },
    { count: counts.finalRules, group: finalRules, type: 'Final Rules' },
    { count: counts.nonrulePolicies, group: nonrulePolicies, type: 'Nonrule Policies' },
    {
      count: counts.governorsActions,
      group: governorsActions,
      type: "Governor's Actions"
    }
  ];
};
