import PropTypes from 'prop-types';
import { useState } from 'react';

import styles from './InputField.module.css';

/**
 * Component that wraps a REQUIRED input field, as well as the label and validation message.
 * Other validations can be added by setting the `inputType` prop (eg "email"). If more
 * validations are necessary in the future, the component will need a bit of modification.
 *
 * @param additionalClassName an additional class to apply to the component's wrapper
 * @param ariaLabel aria-label for the `<input>`
 * @param autocompleteType `autocomplete` prop to help automatically fill in information. https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
 * @param errorLabel Standard validation error message
 * @param handleBlur function to handle blur
 * @param id `<input>` element ID
 * @param initialValue value to initialize input
 * @param inputType Type of input, defaults to "text"
 * @param isVisited whether or not the field has been visited
 * @param label Label for the field
 * @param name name prop for the field (for form submission)
 * @param readonly whether or not the element is read-only
 * @returns {JSX.Element}
 * @constructor
 */
export const InputField = ({
  additionalClassName,
  ariaLabel,
  autocompleteType,
  errorLabel,
  handleBlur,
  id,
  initialValue,
  inputType,
  isVisited,
  label,
  name,
  readonly
}) => {
  const [isValid, setIsValid] = useState(false);
  const checkValidity = (e) => {
    // For keyboard events, only check validity when the user
    // indicates they are done by pressing 'Enter'.
    if (e.type === 'keydown' && e.key !== 'Enter') return;

    setIsValid(e.target.validity.valid);
    handleBlur(e);
  };

  return (
    <div
      className={additionalClassName ? `${styles.field} ${additionalClassName}` : styles.field}
      data-visited={isVisited}
    >
      <div className={styles.fieldLabelGroup}>
        <label htmlFor={id}>{label}</label>
        {!isValid ? (
          <label htmlFor={id} className={styles.errorLabel}>
            {errorLabel}
          </label>
        ) : null}
      </div>
      {inputType === 'textarea' ? (
        <textarea
          aria-label={ariaLabel}
          defaultValue={initialValue ?? ''}
          id={id}
          name={name}
          onBlur={checkValidity}
          onKeyDown={checkValidity}
          readOnly={readonly}
          required
        />
      ) : (
        <input
          aria-label={ariaLabel}
          autoComplete={autocompleteType || undefined}
          defaultValue={initialValue ?? ''}
          id={id}
          name={name}
          onBlur={checkValidity}
          onKeyDown={checkValidity}
          readOnly={readonly}
          required
          type={inputType ?? 'text'}
        />
      )}
    </div>
  );
};

InputField.propTypes = {
  additionalClassName: PropTypes.string,
  ariaLabel: PropTypes.string.isRequired,
  autocompleteType: PropTypes.string,
  errorLabel: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  inputType: PropTypes.string,
  isVisited: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  readonly: PropTypes.bool
};
