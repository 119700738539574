import PropTypes from 'prop-types';
import { createContext } from 'react';

export const FeatureFlagContext = createContext(undefined);

/**
 * Read and expose feature flags for the application.
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const FeatureFlagProvider = ({ children }) => {
  // Right now, we're just using props in the .env files.
  // In the future, we might call a lambda that returns data from the DB.
  const intake = process.env['REACT_APP_INTAKE_ENABLED'];
  const flags = {
    intake: intake === 'true'
  };

  return <FeatureFlagContext.Provider value={{ flags }}>{children}</FeatureFlagContext.Provider>;
};

FeatureFlagProvider.propTypes = {
  children: PropTypes.node
};
