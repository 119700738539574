import { Auth } from 'aws-amplify';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { Button } from 'components/Button/Button';
import { FadeScrollPanel } from 'components/FadeScrollPanel/FadeScrollPanel';
import { InputField } from 'components/InputField/InputField';
import { PasswordField } from 'components/InputField/PasswordField';
import { MainContentWrapper } from 'components/MainContentWrapper/MainContentWrapper';
import { useInputVisitedMarker } from 'hooks/useInputVisitedMarker';
import { AzurePrompt } from 'pages/MyIAR/AzurePrompt/AzurePrompt';
import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { isEmailDomainIGA } from 'util/accountUtil';

import styles from './SignUp.module.css';

export const SignUp = () => {
  const [promptAzureLogin, setPromptAzureLogin] = useState(false);
  const [visitedFields, markVisited, markAllVisited] = useInputVisitedMarker([
    'username',
    'password'
  ]);
  const [captchaResponse, setCaptchaResponse] = useState('');
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);
  const [isPolicyOpen, setIsPolicyOpen] = useState(false);
  const dialogRef = useRef(null);
  const history = useHistory();
  let toastId;

  const onChange = (captchaValue) => {
    setCaptchaResponse(captchaValue);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (toastId) {
      toast.dismiss(toastId);
    }
    markAllVisited();
    if (e.target.checkValidity() && captchaResponse && isPolicyAccepted) {
      const data = {};
      for (const [field, value] of new FormData(e.target).entries()) {
        data[field] = value;
      }

      try {
        const { user } = await Auth.signUp({
          attributes: {
            email: data.username
          },
          autoSignIn: {
            enabled: true
          },
          password: data.password,
          username: data.username
        });
        history.push('/myIAR/verify', { username: user.username });
      } catch (error) {
        toastId = toast.error(error?.message ?? 'Sign up failed.', {
          duration: 10000,
          position: 'top-right'
        });
      }
    }
  };

  const showModal = () => {
    // Open the dialog in modal mode, set the top/bottom fade classes.
    dialogRef.current.showModal();
    setIsPolicyOpen(true);
    dialogRef.current.addEventListener('close', () => {
      setIsPolicyOpen(false);
    });
  };

  const checkPrivacyPolicy = () => {
    if (isPolicyAccepted) {
      setIsPolicyAccepted(false);
    } else {
      showModal();
    }
  };

  const declinePrivacyPolicy = () => {
    setIsPolicyAccepted(false);
    dialogRef.current.close();
  };

  const acceptPrivacyPolicy = () => {
    setIsPolicyAccepted(true);
    dialogRef.current.close();
  };

  const focusPassword = () => {
    document.getElementById('password-field').focus();
  };

  const checkEmail = (e) => {
    markVisited(e);
    setPromptAzureLogin(isEmailDomainIGA(e.target.value));
  };

  return (
    <MainContentWrapper>
      <Helmet>
        <title>Sign Up | MyIAR | IARP</title>
      </Helmet>
      <AccentHeader level={1}>Sign up for MyIAR</AccentHeader>
      <p>Sign up to follow items and receive updates.</p>
      <form onSubmit={handleSignUp} noValidate className={styles.signUpForm}>
        <InputField
          additionalClassName={styles.emailField}
          ariaLabel="Enter an email for your username"
          autocompleteType="email"
          errorLabel="Valid email required"
          handleBlur={checkEmail}
          id="username-field"
          inputType="email"
          isVisited={visitedFields.username}
          label="Email"
          name="username"
        />
        <PasswordField
          additionalClassName={styles.passwordField}
          ariaLabel="Enter a password to be used with this new account"
          autocompleteType="new-password"
          errorLabel="Required"
          handleBlur={markVisited}
          id="password-field"
          isVisited={visitedFields.password}
          name="password"
        />
        <div className={styles.privacyPolicyWrapper}>
          <input
            aria-label="I agree to the privacy policy"
            checked={isPolicyAccepted}
            className={styles.privacyPolicyCheck}
            onChange={checkPrivacyPolicy}
            type="checkbox"
          />
          <p>
            I agree to the
            <button
              aria-haspopup="dialog"
              aria-label="display the privacy policy"
              className={styles.privacyPolicyButton}
              onClick={showModal}
              type="button"
            >
              privacy policy
            </button>
          </p>
        </div>
        <div className={styles.formControls}>
          <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} onChange={onChange} />
          <Button type="submit" disabled={!isPolicyAccepted}>
            Sign up
          </Button>
        </div>
      </form>
      <p>
        <strong>Notice</strong> Public websites are often targeted by SPAM email accounts, so we use
        the Captcha tool to prevent automated emails (robots).
      </p>
      <dialog className={styles.privacyPolicyDialog} ref={dialogRef} role="alertdialog">
        <div className={styles.dialogHeader}>
          <h2>LSA Privacy Policy</h2>
        </div>
        {isPolicyOpen ? (
          <FadeScrollPanel>
            <p className={styles.policyP}>
              Our Administrative Rules and Policies website collects login information when creating
              an account and logging in. This data is used for user authentication. We prioritize
              the security of your information and do not share it with third parties. By using our
              site, you agree to our practices outlined in this Privacy Statement, subject to
              periodic updates that will be effective upon posting.
            </p>
            <p className={styles.policyP}>
              Please be aware that while we take measures to protect your data, using the same
              password across multiple accounts increases the risk of potential exposure in the
              event of a data breach. We strongly recommend using unique passwords for added
              security.
            </p>
            <p className={styles.policyP}>
              If you have any questions or concerns, please contact us at{' '}
              <a href="mailto:iarhelpdesk@iga.in.gov">iarhelpdesk@iga.in.gov</a>.
            </p>
          </FadeScrollPanel>
        ) : null}
        <div className={styles.dialogFooter}>
          <Button action={declinePrivacyPolicy} variants="hollow">
            Decline
          </Button>
          <Button action={acceptPrivacyPolicy}>Accept</Button>
        </div>
      </dialog>
      <AzurePrompt isOpen={promptAzureLogin} onClose={focusPassword} />
    </MainContentWrapper>
  );
};
