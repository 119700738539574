import { CommentPeriodDeadlines } from 'pages/Home/Upcoming/CommentPeriodDeadlines';

import { Hearings } from './Hearings';
import styles from './Upcoming.module.css';

export const Upcoming = () => {
  return (
    <section
      className={styles.upcomingContainer}
      aria-label="Upcoming hearings and in-progress dockets"
    >
      <Hearings />
      <CommentPeriodDeadlines />
    </section>
  );
};
