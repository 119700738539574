import html2pdf from 'html3pdf/src';

/**
 * Convert a doc number into the desired display value
 * @param {string} docNumber LSA doc number
 * @returns {string} a displayable LSA doc number, or the original
 * docNumber if it is not a string.
 */
export const displayLsaDocNumber = (docNumber) => {
  if (!docNumber || typeof docNumber !== 'string') {
    return docNumber;
  }
  const year = docNumber.substring(0, 2);
  const number = parseInt(docNumber.substring(2));
  return `${year}-${number}`;
};

export const docGroup = {
  all: 'all',
  ag: 'ag_opinions',
  agencyGuidance: 'agency_guidance',
  gov: 'gov',
  pending: 'pending_rules',
  ir: 'ir'
};

/**
 * Generate a PDF from the specified element, save as the specified filename
 * @param element The element to use for the PDF content
 * @param filename Filename when saved
 * @param cleanupFunc Function to clean up anything once the PDF generation is complete
 */
export const downloadPDF = (element, filename, cleanupFunc) => {
  html2pdf()
    .set({
      filename: filename,
      html2canvas: { useCORS: true },
      jsPDF: {
        format: 'letter',
        orientation: 'portrait',
        unit: 'in'
      },
      margin: 0.75,
      pagebreak: {
        mode: ['avoid-all', 'css', 'legacy']
      }
    })
    .from(element)
    .save()
    .then(() => {
      if (cleanupFunc) {
        cleanupFunc();
      }
    });
};
