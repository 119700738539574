export const timeOfDayString = () => {
  const now = new Date();
  const hour = now.getHours();
  if (hour >= 2 && hour < 12) {
    return 'Morning';
  } else if (hour >= 12 && hour < 18) {
    return 'Afternoon';
  }
  return 'Evening';
};
