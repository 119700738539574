import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { MainContentWrapper } from 'components/MainContentWrapper/MainContentWrapper';
import { Helmet } from 'react-helmet-async';

export const Error403 = () => {
  return (
    <MainContentWrapper>
      <Helmet>
        <title>Forbidden | IARP</title>
      </Helmet>
      <AccentHeader level={1}>
        <strong>403</strong> Insufficient Privileges
      </AccentHeader>
      <p>
        You do not have permission to access this feature. If you believe this is an error, contact
        your administrator.
      </p>
    </MainContentWrapper>
  );
};
