import { ListFilter } from 'components/ListFilter/ListFilter';
import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import PropTypes, { oneOfType } from 'prop-types';
import { useContext } from 'react';

export const TopicAndAgencyFilter = ({
  handleFilterChange,
  handleToggle,
  selectedAgencies,
  selectedTopics
}) => {
  const { topics } = useContext(RefDataContext);
  return (
    <ListFilter
      childFieldName="agencies"
      childPropName="agency_name"
      childPropValue="title_num"
      dataList={topics}
      fieldName="topics"
      initialChildSelection={selectedAgencies}
      initialSelection={selectedTopics}
      isActive={true}
      isFilterable={true}
      listName="Topic/Agency"
      onToggle={handleToggle}
      onUpdate={handleFilterChange}
      pluralName="Topics/Agencies"
      propName="topicName"
      propValue="topicId"
    />
  );
};

TopicAndAgencyFilter.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  selectedAgencies: PropTypes.arrayOf(oneOfType([PropTypes.number, PropTypes.string])),
  selectedTopics: PropTypes.arrayOf(oneOfType([PropTypes.number, PropTypes.string]))
};
