import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Colors as ColosseumColors, Typography as ColosseumTypography } from 'colosseum';
import AdminBanner from 'components/AdminBanner/AdminBanner';
import { AdminContext } from 'components/AdminContext/AdminContext';
import { AppFooter } from 'components/AppFooter/AppFooter';
import { AppHeader } from 'components/AppHeader/AppHeader';
import { BackToTopButton } from 'components/BackToTopButton/BackToTopButton';
import { FeatureFlagProvider } from 'components/FeatureFlags/FeatureFlagContext/FeatureFlagContext';
import { RefDataProvider } from 'components/RefDataContext/RefDataContext';
import { Code } from 'pages/Code/Code';
import { LatestArticle } from 'pages/Code/LatestArticle/LatestArticle';
import { Contact } from 'pages/Contact/Contact';
import { Error403 } from 'pages/Error/Error403';
import { Error404 } from 'pages/Error/Error404';
import { Home } from 'pages/Home/Home';
import { MyIAR } from 'pages/MyIAR/MyIAR';
import { LsaDocument } from 'pages/Register/LsaDocument/LsaDocument';
import { Register } from 'pages/Register/Register';
import { TabbedSearch } from 'pages/Search/TabbedSearch';
import { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Colors, Typography } from 'util/ThemeOverrides';

import styles from './App.module.css';

// Google Analytics/Tag Manager settings
if (process.env.REACT_APP_AWS_ENV === 'prd') {
  const tagManagerArgs = {
    gtmId: 'GTM-N86585SV'
  };
  TagManager.initialize(tagManagerArgs);
}

const setCustomProps = (colosseumProps, localProps) => {
  const propSet = {
    ...colosseumProps,
    ...localProps
  };
  Object.entries(propSet).forEach(([key, value]) => {
    document.documentElement.style.setProperty(`--${key}`, value);
  });
};

export const App = () => {
  const { isBannerVisible } = useContext(AdminContext);

  // Notify Google TagManager on page changes
  if (process.env.REACT_APP_AWS_ENV === 'prd') {
    window.dataLayer.push({
      event: 'pageview'
    });
  }

  // Set css custom properties from Colosseum.
  useEffect(() => {
    setCustomProps(ColosseumColors, Colors);
    setCustomProps(ColosseumTypography, Typography);
  });

  const { pathname } = useLocation();
  const scrollRef = useRef(null);
  const headerRef = useRef(null);

  // Scroll to top when URL path changes.
  // (Not if only the hash changes, so IAC document
  // Table of Contents navigation will work.)
  useLayoutEffect(() => {
    scrollRef.current?.scrollTo({ top: 0 });
  }, [pathname]);

  const scrollTo = (target) => {
    const headerOffset = headerRef.current?.clientHeight;
    scrollRef.current?.scrollTo({
      behavior: 'smooth',
      top: target - headerOffset
    });
  };

  return (
    <>
      <HelmetProvider>
        <FeatureFlagProvider>
          <Toaster />
          <div className={styles.app}>
            <div className={styles.headerWrapper} ref={headerRef}>
              <AppHeader />
              {isBannerVisible ? <AdminBanner /> : null}
            </div>
            <div className={styles.scrollable} ref={scrollRef}>
              <main className={styles.main}>
                <RefDataProvider>
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/register" component={Register} />
                    <Route path="/lsadoc/:lsaDocNum" component={LsaDocument} />
                    <Route path="/code/:year?/:title?/:article?">
                      <Code scrollTo={scrollTo} />
                    </Route>
                    <Route path="/latestArticle/:title/:article" component={LatestArticle} />
                    <Route path="/myIAR" component={MyIAR} />
                    <Route exact path="/contact" component={Contact} />
                    <Route path="/search" component={TabbedSearch} />
                    <Route path="/notAllowed" component={Error403} />
                    <Route path="*" component={Error404} />
                  </Switch>
                </RefDataProvider>
              </main>
              <BackToTopButton scrollRef={scrollRef} />
              <AppFooter sticky={pathname === '/'} />
            </div>
          </div>
        </FeatureFlagProvider>
      </HelmetProvider>
      <ReactQueryDevtools />
    </>
  );
};
