import { Loading } from 'colosseum';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import { useFollowedAgencies, useFollowedAgenciesMutation } from 'hooks/agencyHooks';
import { AgencyTopic } from 'pages/MyIAR/Home/AgencyList/AgencyTopic/AgencyTopic';
import { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';

import { AgencyFilter } from './AgencyFilter/AgencyFilter';
import styles from './AgencyList.module.css';

/**
 * List of agencies and their followed status.
 * @returns {JSX.Element}
 * @constructor
 */
export const AgencyList = () => {
  const [filters, setFilters] = useState({
    filterSelection: 'all',
    filterString: ''
  });

  const { topics } = useContext(RefDataContext);
  const {
    data: followedAgencies,
    isLoading: loadingFollowedAgencies,
    isError: followedAgenciesError
  } = useFollowedAgencies();

  const followMutation = useFollowedAgenciesMutation();

  // Make it easy for sub-components to know whether an agency is followed
  const topicList = topics.map((topic) => {
    const children = topic.children.map((agency) => {
      const isFollowing = followedAgencies?.some((val) => val.title_num === `${agency.title_num}`);
      return {
        ...agency,
        following: isFollowing
      };
    });
    return {
      ...topic,
      children,
      following: children.every((child) => child.following)
    };
  });

  const toggleEntity = (entity, follow) => {
    const titleNum = entity.children
      ? entity.children.map((child) => child.title_num).join(',')
      : entity.title_num;
    const entityName = entity.agency_name || entity.topicName;
    followMutation.mutate({
      titleNumbers: titleNum,
      follow
    });
    toast.success(`You have ${!follow ? 'un' : ''}followed ${entityName}`, {
      position: 'top-right'
    });
  };

  return (
    <>
      <Helmet>
        <title>Topic/Agency List | IARP</title>
      </Helmet>
      <AccentHeader level={2} underline="subtle">
        Topic/Agency List
      </AccentHeader>
      <div aria-label="Topic and Agency List" aria-live="polite">
        {loadingFollowedAgencies ? (
          <div className={styles.agenciesLoadingWrapper}>
            <Loading screenreaderText="Loading List of Topics and Agencies" />
          </div>
        ) : followedAgenciesError ? (
          <p>Could not retrieve followed agencies.</p>
        ) : (
          <div className={styles.allList}>
            <AgencyFilter
              filterSelection={filters.filterSelection}
              filterString={filters.filterString}
              onChange={setFilters}
            />
            <div>
              {topicList.map((topic) => (
                <AgencyTopic
                  filters={filters}
                  toggleItem={toggleEntity}
                  topic={topic}
                  key={topic.topicId}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
