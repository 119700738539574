import { Loading } from 'colosseum';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import { format, isBefore, parseISO } from 'date-fns';
import compareDesc from 'date-fns/compareDesc';
import { useFollowedAgencies } from 'hooks/agencyHooks';
import { useOpenSearch } from 'hooks/openSearchHook';
import { useLastVisited } from 'hooks/useLastVisited';
import { DashboardGroup } from 'pages/MyIAR/Home/Dashboard/DashboardGroup/DashboardGroup';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { sixDaysAgoFn, thirtyDaysAgoFn, todayFn } from 'util/dateUtil';
import { docGroup } from 'util/documentUtil';

import styles from './Dashboard.module.css';

/**
 * Retrieve / render a list of recent documents that the user is following.
 * @returns {JSX.Element}
 * @constructor
 */
export const Dashboard = () => {
  const { getAgencyName, isLoading: refDataLoading } = useContext(RefDataContext);
  const { data: agenciesFollowed, error: agenciesError, isLoading } = useFollowedAgencies();
  const { data, error, executeSearch, isSearching } = useOpenSearch();
  const [dashboardData, setDashboardData] = useState({
    lastSevenDays: [],
    otherAgencies: [],
    sinceLastVisit: []
  });
  const lastVisited = useLastVisited('lastDashboardVisit');

  // When we have the followed agencies, we can get the recent docs...
  useEffect(() => {
    if (!isLoading && agenciesFollowed?.length) {
      const titleNums = agenciesFollowed.map((a) => a.title_num);
      // Now search for docs for those agencies
      executeSearch({
        archived: false,
        agencies: titleNums,
        document_group: docGroup.ir,
        endDate: todayFn(),
        startDate: thirtyDaysAgoFn()
      });
    }
  }, [agenciesFollowed, executeSearch, isLoading]);

  // When we get the recent docs, we can sort/set the data...
  useEffect(() => {
    const lastSevenDays = [];
    const otherAgencies = [];
    const sinceLastVisit = [];
    if (!refDataLoading && agenciesFollowed?.length && data) {
      // group by agency...
      const docsByAgency = agenciesFollowed.map((a) => {
        return {
          agencyId: a.title_num,
          agencyName: getAgencyName(a.title_num),
          docs: []
        };
      });
      data?.hits?.hits?.forEach((doc) => {
        // ooof, a find within a foreach. Maybe I should build a map structure
        // above, so these are more performant. But then I need to convert THAT
        // structure to an array afterward.
        const agency = docsByAgency.find((d) => d.agencyId === doc._source.title_num);
        agency?.docs.push(doc._source);
      });
      // sort by agency name (consistency with other areas)
      docsByAgency.sort((a, b) => a.agencyName.localeCompare(b.agencyName));
      docsByAgency.forEach((agency) => {
        agency.docs?.sort((a, b) => compareDesc(parseISO(a.date_posted), parseISO(b.date_posted)));
        if (agency.docs?.length && isBefore(sixDaysAgoFn(), parseISO(agency.docs[0].date_posted))) {
          lastSevenDays.push(agency);
        } else if (
          agency.docs?.length &&
          isBefore(lastVisited, parseISO(agency.docs[0].date_posted))
        ) {
          sinceLastVisit.push(agency);
        } else {
          otherAgencies.push(agency);
        }
      });
    }
    setDashboardData({
      lastSevenDays,
      otherAgencies,
      sinceLastVisit
    });
  }, [agenciesFollowed, data, getAgencyName, lastVisited, refDataLoading]);

  return (
    <>
      <Helmet>
        <title>Dashboard | IARP</title>
      </Helmet>
      <AccentHeader level={2} underline="subtle">
        Dashboard
      </AccentHeader>
      <div>
        <div aria-label="Recent Actions" className={styles.dashboardContent}>
          {isLoading || isSearching ? (
            <Loading />
          ) : agenciesError || error ? (
            <p>Unable to load Dashboard data.</p>
          ) : !agenciesFollowed.length ? (
            <div className={styles.dashboardMessage}>
              <p>
                You aren&apos;t following any topics or agencies. Select topics or agencies to
                follow in the <Link to="/myIAR/home/agencyList">Topic/Agency List</Link>.
              </p>
            </div>
          ) : (
            <>
              {lastVisited ? (
                <div className={styles.lastVisited}>
                  Last viewed Dashboard {format(lastVisited, 'M/dd/yy')} at{' '}
                  {format(lastVisited, 'h:mm a').toLowerCase()}
                </div>
              ) : null}
              {dashboardData.lastSevenDays?.length ? (
                <DashboardGroup
                  agencyList={dashboardData.lastSevenDays}
                  lastVisited={lastVisited}
                  title="Agencies with new documents this week"
                />
              ) : null}
              {dashboardData.sinceLastVisit?.length ? (
                <DashboardGroup
                  agencyList={dashboardData.sinceLastVisit}
                  lastVisited={lastVisited}
                  title="Agencies with new documents since you last viewed"
                />
              ) : null}
              {dashboardData.otherAgencies?.length ? (
                <DashboardGroup
                  agencyList={dashboardData.otherAgencies}
                  lastVisited={lastVisited}
                  title={
                    dashboardData.lastSevenDays?.length || dashboardData.sinceLastVisit?.length
                      ? 'Other followed agencies'
                      : null
                  }
                />
              ) : null}
              <div className={styles.updateFollowing}>
                <Link to="/myIAR/home/agencyList">Edit Followed Agencies</Link>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
