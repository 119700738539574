import { InputField } from 'components/InputField/InputField';
import { useContactMutation } from 'hooks/contactHooks';
import { useInputVisitedMarker } from 'hooks/useInputVisitedMarker';
import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import toast from 'react-hot-toast';
import { RiCloseFill } from 'react-icons/ri';

import styles from './ContactForm.module.css';

export const ContactForm = () => {
  const [visitedFields, markVisited, markAllVisited, resetVisited] = useInputVisitedMarker([
    'name',
    'email',
    'topic',
    'message'
  ]);
  const [captchaResponse, setCaptchaResponse] = useState('');
  const dialogRef = useRef(null);
  const contactUsMutation = useContactMutation();

  let captcha;

  const onChange = (captchaValue) => {
    setCaptchaResponse(captchaValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    markAllVisited();
    if (e.target.checkValidity() && captchaResponse) {
      const data = {};
      for (const [field, value] of new FormData(e.target).entries()) {
        data[field] = value;
      }
      delete data['g-recaptcha-response'];

      try {
        contactUsMutation.mutate({
          formData: data,
          captchaResponse: captchaResponse
        });
        resetVisited();
        e.target.reset();
        toast.success('Thank you for contacting us!', { position: 'top-right' });
      } catch (e) {
        toast.error('Unable to send', { position: 'top-right' });
      } finally {
        setCaptchaResponse(null);
        captcha && captcha.reset();
      }
    }
  };

  return (
    <>
      <form className={styles.contactForm} noValidate onSubmit={handleSubmit}>
        <InputField
          additionallassName={styles.nameField}
          ariaLabel="Enter your name"
          autocompleteType="name"
          errorLabel="Required"
          handleBlur={markVisited}
          id="name-field"
          isVisited={visitedFields.name}
          label="Name"
          name="name"
        />
        <InputField
          additionalClassName={styles.emailField}
          ariaLabel="Enter your email address"
          autocompleteType="email"
          errorLabel="Required"
          handleBlur={markVisited}
          id="email-field"
          inputType="email"
          isVisited={visitedFields.email}
          label="Email"
          name="email"
        />
        <InputField
          additionalClassName={styles.topicField}
          ariaLabel="Enter a topic"
          errorLabel="Required"
          handleBlur={markVisited}
          id="topic-field"
          isVisited={visitedFields.topic}
          label="Topic"
          name="topic"
        />
        <InputField
          additionalClassName={styles.messageField}
          ariaLabel="Enter a message"
          errorLabel="Required"
          handleBlur={markVisited}
          id="message-field"
          inputType="textarea"
          isVisited={visitedFields.message}
          label="Message"
          name="message"
        />
        <div className={styles.formControls}>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            onChange={onChange}
            ref={(e) => (captcha = e)}
          />
          <button type="submit" className={styles.submitButton}>
            Send
          </button>
        </div>
      </form>
      <dialog className={styles.successDialog} ref={dialogRef} role="alertdialog">
        <button
          aria-label="Close dialog"
          className={styles.closeDialogButton}
          onClick={() => dialogRef.current.close()}
        >
          <RiCloseFill size="1.5rem" title="close dialog icon" />
        </button>
        <p>Your message has been sent.</p>
        <p>A copy of this message has been sent to your email for your record.</p>
      </dialog>
    </>
  );
};
