import FiscallySignificantTooltip from 'components/FiscallySignificantTooltip/FiscallySignificantTooltip';
import { CheckboxFilter } from 'pages/Search/CheckboxFilter/CheckboxFilter';
import PropTypes from 'prop-types';

export const FiscallySignificantFilter = ({ handleFilterChange, isChecked }) => {
  return (
    <CheckboxFilter
      handleFilterChange={handleFilterChange}
      isChecked={isChecked}
      label="Fiscally Significant"
      name="fiscallySignificant"
    >
      <FiscallySignificantTooltip />
    </CheckboxFilter>
  );
};

FiscallySignificantFilter.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool
};
