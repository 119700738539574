import { AddCheckbox } from 'components/AddCheckbox/AddCheckbox';
import styles from 'components/ListFilter/FilterItem/FilterItem.module.css';
import PropTypes, { oneOfType } from 'prop-types';
import { useEffect, useState } from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';

/**
 * Toggle following / unfollowing status
 * @param checked whether or not the item is initially checked
 * @param childFieldName
 * @param childPropName
 * @param childPropValue
 * @param disabled whether or not the item is disabled
 * @param display Whether or not to display the item
 * @param fieldName the string to use for name/id of fields
 * @param filterString a string to filter on, if present only show children that match.
 * @param initialChildSelection
 * @param item The item to display in filter options
 * @param onToggle Function to call when the filter item is toggled, function takes the item's ID and the new 'checked' status
 * @param onToggleChild
 * @returns {JSX.Element}
 * @constructor
 */
export const FilterItem = ({
  checked,
  childFieldName,
  childPropName,
  childPropValue,
  disabled,
  display,
  fieldName,
  filterString,
  initialChildSelection,
  item,
  onToggle,
  onToggleChild
}) => {
  const [childrenOpen, setChildrenOpen] = useState(false);
  const [childrenSearchOpen, setChildrenSearchOpen] = useState(false);
  const hasChildrenChecked =
    item.children?.length &&
    initialChildSelection?.length &&
    initialChildSelection.some(
      (selection) => item.children.findIndex((child) => child[childPropValue] === selection) !== -1
    );

  // if the filterString changes, open/close the children if necessary
  useEffect(() => {
    const childrenFiltered =
      item?.children &&
      filterString &&
      item.children.some((child) => child[childPropName].toLowerCase().includes(filterString));
    setChildrenSearchOpen(childrenFiltered);
  }, [childPropName, filterString, item]);

  const handleToggle = (id, isChecked) => {
    // pass on the list of children (if present) to (un)check along with the parent
    onToggle(id, isChecked, item.children);
  };

  const handleChildToggle = (id, isChecked) => {
    const childIDs = new Set(item.children.map((child) => child[childPropValue]));
    const current = new Set(initialChildSelection);
    const intersection = childIDs.intersection(current);
    const parentId =
      (isChecked && intersection.size + 1 === childIDs.size) || !isChecked ? item.id : undefined;
    onToggleChild(id, isChecked, parentId);
  };

  return (
    <li className={!display ? `${styles.item} ${styles.filteredOut}` : styles.item}>
      <div>
        <span className={styles.itemName}>
          {item.children?.length ? (
            <button
              aria-expanded={childrenOpen || childrenSearchOpen}
              className={styles.toggleButton}
              onClick={() => setChildrenOpen((cur) => !cur)}
              type="button"
            >
              <RiArrowDownSLine
                size="1rem"
                className={styles.toggleButtonIcon}
                title="accordion toggle icon"
              />
              <span>{item.name}</span>
            </button>
          ) : (
            <>{item.name}</>
          )}
        </span>
        <AddCheckbox
          checked={checked}
          disabled={disabled}
          fieldName={fieldName}
          hasChildrenChecked={!!hasChildrenChecked}
          id={item.id}
          onToggle={handleToggle}
        />
      </div>
      {item.children?.length ? (
        <div
          className={
            childrenOpen || childrenSearchOpen
              ? `${styles.childItemListContainer} ${styles.open}`
              : styles.childItemListContainer
          }
        >
          <ul className={styles.childItemList}>
            {item.children
              .filter((childItem) =>
                filterString ? childItem[childPropName].toLowerCase().includes(filterString) : true
              )
              .map((childItem) => (
                <li key={childItem[childPropValue]}>
                  <span className={styles.childItemName}>{childItem[childPropName]}</span>
                  <AddCheckbox
                    checked={initialChildSelection?.includes(`${childItem[childPropValue]}`)}
                    disabled={disabled}
                    fieldName={childFieldName}
                    id={childItem[childPropValue]}
                    onToggle={handleChildToggle}
                  />
                </li>
              ))}
          </ul>
        </div>
      ) : null}
    </li>
  );
};

FilterItem.propTypes = {
  checked: PropTypes.bool,
  childFieldName: PropTypes.string,
  childPropName: PropTypes.string,
  childPropValue: PropTypes.string,
  disabled: PropTypes.bool,
  display: PropTypes.bool.isRequired,
  fieldName: PropTypes.string.isRequired,
  filterString: PropTypes.string,
  initialChildSelection: PropTypes.arrayOf(oneOfType([PropTypes.number, PropTypes.string])),
  item: PropTypes.shape({
    children: PropTypes.array,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
  }).isRequired,
  onToggle: PropTypes.func,
  onToggleChild: PropTypes.func
};
