import { CodeArticleName } from 'components/DocumentAccordion/CodeArticleName/CodeArticleName';
import { CodeTitleName } from 'components/DocumentAccordion/CodeTitleName/CodeTitleName';
import { DownloadRegisterPdfButton } from 'components/DownloadRegisterPdfButton/DownloadRegisterPdfButton';
import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import { SearchResultPaging } from 'pages/Search/SearchResultPaging/SearchResultPaging';
import styles from 'pages/Search/TabbedSearchResults/TabbedSearchResults.module.css';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { isoToMDY } from 'util/dateUtil';
import { openSearchResults, searchParams } from 'util/shapes';

export const TabbedSearchResults = ({ data, handlePageChange, params }) => {
  const { getAgencyName, getDocTypeName } = useContext(RefDataContext);
  const resultCount = data?.hits?.total?.value ?? 0;

  return (
    <div className={styles.resultsContainer}>
      {resultCount ? (
        <>
          <div>Filtered to {resultCount} results</div>
          <SearchResultPaging data={data} onPageChange={handlePageChange} params={params} />
          <ul className={styles.resultsList}>
            {data?.hits?.hits?.map((doc) => (
              <li key={doc._id} className={styles.resultItem}>
                {doc._source?.collection === 'irdoc' ? (
                  <>
                    <div className={styles.resultBanner}>
                      <span>Indiana Register</span>
                      <span>Posted {isoToMDY(doc._source?.date_posted)}</span>
                    </div>
                    <div className={styles.links}>
                      <Link
                        to={
                          doc._source?.document_group?.includes('pending_rules')
                            ? `/lsadoc/${doc._source?.lsa_doc_num}?searchDoc=${doc._source?.din}`
                            : `/register/${doc._source?.din}`
                        }
                        className={styles.irLink}
                      >
                        <strong>TITLE {doc._source?.title_num}</strong>{' '}
                        {getAgencyName(doc._source?.title_num)}
                        <br />
                        {doc._source?.din}
                        <br />
                        {getDocTypeName(doc._source?.doc_type)}
                      </Link>
                      <DownloadRegisterPdfButton din={doc._source.din} />
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.resultBanner}>
                      <span>Administrative Code</span>
                      <span>{doc._source?.edition_year}</span>
                    </div>
                    <Link
                      to={`/code/${doc._source?.edition_year}/${doc._source?.title_num}/${doc._source?.article_num}`}
                      className={styles.iacLink}
                    >
                      <CodeTitleName
                        number={doc._source?.title_num}
                        title={getAgencyName(doc._source?.title_num)}
                      />
                      <CodeArticleName name={doc._source?.article_name} />
                    </Link>
                  </>
                )}
                {doc.highlight?.doc_text?.length ? (
                  <div className={styles.highlight}>
                    {doc.highlight.doc_text.map((highlight, idx) => (
                      <p
                        dangerouslySetInnerHTML={{ __html: highlight }}
                        key={`${doc._id}-highlight-${idx}`}
                      />
                    ))}
                  </div>
                ) : null}
              </li>
            ))}
          </ul>
          <SearchResultPaging data={data} onPageChange={handlePageChange} params={params} />
        </>
      ) : (
        <>
          <p className={styles.noResults}>
            {params.search
              ? `Sorry, we could not find any documents that contain ‘${params.search}’.`
              : 'Sorry, we could not find any matching documents.'}
          </p>
          <p className={styles.noResults}>
            Please check your spelling, modify your search keywords, or check your active filters
            and try again.
          </p>
        </>
      )}
    </div>
  );
};

TabbedSearchResults.propTypes = {
  data: openSearchResults,
  handlePageChange: PropTypes.func.isRequired,
  params: searchParams
};
