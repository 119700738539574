import './wdyr'; // eslint-disable-line
import './index.css';
import './document.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';

import { App } from 'App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from 'components/AuthContext/AuthContext';
import { Amplify, Auth } from 'aws-amplify';
import { AdminProvider } from 'components/AdminContext/AdminContext';

const renderApp = (user) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // We don't really have real-time data, so this is mostly just annoying if a cache is invalidated and the page loads again.
        refetchOnWindowFocus: false,
        // Only retry when it might actually work.
        retry: (failureCount, error) => {
          const retryOnCodes = [
            '408', // Request Timeout
            '409', // Conflict
            '502', // Bad Gateway
            '503', // Service Unavailable
            '504' // Gateway Timeout
          ];
          return failureCount <= 3 && retryOnCodes.includes(error?.code);
        }
      }
    }
  });

  ReactDOM.render(
    <React.StrictMode>
      <AuthProvider user={user}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Toaster />
            <AdminProvider>
              <App />
            </AdminProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </AuthProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AWS_COGNITO_OAUTH_DOMAIN,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: process.env.REACT_APP_AWS_COGNITO_OAUTH_REDIRECT,
      redirectSignOut: process.env.REACT_APP_AWS_COGNITO_OAUTH_REDIRECT,
      responseType: 'code'
    }
  }
});

Auth.currentAuthenticatedUser()
  .then((user) => {
    renderApp(user);
  })
  .catch(() => renderApp(null));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
