import office1 from 'assets/static/images/office1.jpg';
import office3 from 'assets/static/images/office3.jpg';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { ExternalLink } from 'components/ExternalLink/ExternalLink';

import styles from './About.module.css';

export const About = () => {
  return (
    <section aria-labelledby="about-us-header">
      <div className={styles.about}>
        <AccentHeader level={1} id="about-us-header">
          About Us
        </AccentHeader>
        <div className={styles.columnText}>
          <p>
            The Indiana Register and Administrative Code Division (Division) serves as the
            administrative publishing branch of the Legislative Services Agency under the direction
            of the{' '}
            <ExternalLink
              linkText="Legislative Council"
              url={`https://iga.in.gov/${new Date().getFullYear()}/committees/interim/legislative-council`}
            />
            . The Division is responsible for publishing the Indiana Register and the Indiana
            Administrative Code, with the focus on transparency and ease of access through these
            publications.
          </p>
        </div>
      </div>
      <div className={styles.rules}>
        <div className={styles.aboutImageContainer}>
          <img src={office1} alt="Governor's Office" />
        </div>
        <div className={styles.aboutTextBox}>
          <AccentHeader level={2}>Indiana Administrative Code</AccentHeader>
          <p>
            The Indiana Administrative Code (IAC) is the electronic compilation of all permanent
            administrative rules. The first official edition of the IAC was published in 1979, with
            subsequent editions published in paper format through 2005. After June 30, 2006, the IAC
            has been updated weekly only in electronic format on the{' '}
            <ExternalLink linkText="General Assembly's website" url="https://iga.in.gov/" /> as
            permanent rulemaking becomes effective.
          </p>
        </div>
      </div>
      <div className={styles.code}>
        <div className={styles.aboutTextBox}>
          <AccentHeader level={2}>Indiana Register</AccentHeader>
          <p>
            The Indiana Register currently is a weekly, online publication of documents submitted to
            the Division by state agencies, and contains the full text of each document submitted
            for publication. The list of documents for the weekly publication schedule varies
            according to the posting requirements for each type of document, and may include nonrule
            policy documents, Notices of Public Hearing, Executive Orders, or Other Notices, as well
            as documents related to permanent rulemaking, such as Proposed Rules or Final Rules.
            Since July 1, 2006, the Indiana Register has been published only in electronic format on
            the <ExternalLink linkText="General Assembly's website" url="https://iga.in.gov/" />.
          </p>
        </div>
        <div className={styles.aboutImageContainer}>
          <img src={office3} alt="Governor's Office" />
        </div>
      </div>
    </section>
  );
};
