import PropTypes from 'prop-types';
import { createContext, useState } from 'react';
import { claims } from 'util/shapes';

export const AuthContext = createContext(undefined);

/**
 * Holds the current user, and exposes the current user and way to set (on login / logout)
 * @param children The rest of the app that needs knowledge of the user
 * @param user The user (or null) upon application initialization
 * @returns {JSX.Element} The react Provider
 * @constructor
 */
export const AuthProvider = ({ children, user }) => {
  const [currentUser, setCurrentUserUser] = useState(user);

  const isAzureUser = !!currentUser?.signInUserSession?.idToken?.payload?.identities?.some(
    (id) => id.providerName === 'Azure'
  );
  const email = currentUser?.signInUserSession?.idToken?.payload?.email;
  const firstName = currentUser?.signInUserSession?.idToken?.payload?.given_name;
  const lastName = currentUser?.signInUserSession?.idToken?.payload?.family_name;
  const userClaims = [];

  // get as an array
  const adminGroups =
    process.env['REACT_APP_ADMIN_GROUP']?.split(',')?.map((group) => group.trim()) ?? [];
  // this is a string that LOOKS LIKE an array
  const userGroupString = currentUser?.signInUserSession?.idToken?.payload?.['custom:group'];
  const userGroups =
    userGroupString
      ?.replaceAll(/[[\]']+/g, '')
      ?.split(',')
      .map((group) => group.trim()) ?? [];

  const isAdmin = userGroups?.filter((group) => adminGroups.includes(group))?.length > 0 ?? false;
  if (isAdmin) {
    userClaims.push(claims.isAdmin);
  }

  return (
    <AuthContext.Provider
      value={{
        email,
        firstName,
        isAzureUser,
        lastName,
        setUser: setCurrentUserUser,
        user: currentUser,
        userClaims
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.any,
  user: PropTypes.any
};
