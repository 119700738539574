import PropTypes from 'prop-types';
import { useLayoutEffect, useRef } from 'react';
import { RiCloseLine, RiInformationFill } from 'react-icons/ri';

import styles from './Tooltip.module.css';

export const Tooltip = ({ ariaLabel, children, id }) => {
  // If the browser doesn't yet support css anchor, reset the margin to 'auto' for default center positioning
  const popoverStyle = 'anchorName' in document.documentElement.style ? {} : { margin: 'auto' };
  const buttonRef = useRef(null);
  const popoverRef = useRef(null);

  // We need unique anchor names in case we use multiple tooltips on a page.
  // Currently, React will ignore "anchorName" or "anchor-name" in a style object, so we need to add it with vanilla JS.
  useLayoutEffect(() => {
    const anchorName = `--tooltipanchor-${id}`;
    if (buttonRef?.current && popoverRef?.current) {
      buttonRef.current.style['anchor-name'] = anchorName;
      popoverRef.current.style['position-anchor'] = anchorName;
    }
  }, [id]);

  return (
    <>
      <button
        aria-haspopup="dialog"
        aria-label={ariaLabel}
        className={styles.tooltipButton}
        popovertarget={id}
        popovertargetaction="show"
        ref={buttonRef}
        type="button"
      >
        <RiInformationFill size="1.5rem" />
      </button>
      <div className={styles.tooltip} id={id} popover="auto" ref={popoverRef} style={popoverStyle}>
        <button
          aria-label="close tooltip"
          className={`${styles.tooltipButton} ${styles.closeButton}`}
          popovertarget={id}
          popovertargetaction="hide"
          type="button"
        >
          <RiCloseLine size="1.5rem" />
        </button>
        {children}
      </div>
    </>
  );
};

Tooltip.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired
};
