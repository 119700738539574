import PropTypes from 'prop-types';
import React from 'react';

import styles from './Spinner.module.css';

const Spinner = ({ light }) => {
  return <div className={light ? `${styles.wheel} ${styles.light}` : styles.wheel} />;
};

export default Spinner;

Spinner.propTypes = {
  light: PropTypes.bool
};
