import { PrivateRoute } from 'components/PrivateRoute/PrivateRoute';
import { Error404 } from 'pages/Error/Error404';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { ForgotPassword } from './ForgotPassword/ForgotPassword';
import { Home } from './Home/Home';
import { Login } from './Login/Login';
import { SignUp } from './SignUp/SignUp';
import { Verify } from './Verify/Verify';
import { VerifyReset } from './VerifyReset/VerifyReset';

export const MyIAR = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Helmet>
        <title>MyIAR | IARP</title>
      </Helmet>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/home`} />
        </Route>
        <PrivateRoute path={`${path}/home`}>
          <Home />
        </PrivateRoute>
        <Route exact path={`${path}/login`} component={Login} />
        <Route exact path={`${path}/forgotPassword`} component={ForgotPassword} />
        <Route exact path={`${path}/signUp`} component={SignUp} />
        <Route exact path={`${path}/verify`} component={Verify} />
        <Route exact path={`${path}/verifyReset`} component={VerifyReset} />
        <Route path="*" component={Error404} />
      </Switch>
    </>
  );
};
