import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router';
import { API, getAuthData, getData, getToken } from 'util/API';

/**
 * Get the list of agencies
 * @returns the return value of react-query "useQuery"
 */
export const useAgencyList = () => {
  const agenciesUrl = `${API}/agencies`;

  return useQuery({
    queryKey: ['agencies', agenciesUrl],
    queryFn: () => getData(agenciesUrl),
    enabled: true,
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
    select: (response) => response?.iar_agency_list
  });
};

const followedAgenciesUrl = `${API}/auth/followedAgencies`;
const followedAgenciesKey = 'followedAgencies';
/**
 * Make an authorized API call for followed agencies.
 *
 * (Note: the attempt to get a token will re-direct for
 * login if the token is missing or beyond the refresh timeout.)
 *
 * @returns the return value of react-query "useQuery"
 */
export const useFollowedAgencies = () => {
  const history = useHistory();

  const authQueryFn = () => getAuthData(history, followedAgenciesUrl, []);

  return useQuery({
    queryKey: [followedAgenciesKey, followedAgenciesUrl],
    queryFn: authQueryFn,
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
    select: (response) => response?.followed_agencies
  });
};

/**
 * Set up a mutation for making authorized API calls to
 * follow / unfollow agencies.
 *
 * (Note: the attempt to get a token will re-direct for
 * login if the token is missing or beyond the refresh timeout.)
 *
 * The mutation expects to be called with an object containing
 * the "agencyId" and "follow", a boolean to pick between
 * follow / unfollow actions.
 *
 * Example 1 -- to follow an agency:
 *
 * <code>
 *   const mutation = useFollowedAgenciesMutation();
 *   mutation.mutate({
 *     agencyId: 23,
 *     docType: 1,
 *     follow: true
 *   });
 * </code>
 *
 * Example 2 -- to unfollow an agency:
 *
 * <code>
 *   const mutation = useFollowedAgenciesMutation();
 *   mutation.mutate({
 *     agencyId: 23,
 *     docType: 1,
 *     follow: false
 *   });
 * </code>
 *
 * SIDE EFFECT:
 * Invalidates the "iarFollowedAgencies" query, forcing the UI
 * to re-fetch the list of followed agencies.
 *
 * @returns the return value of react-query's "useMutation"
 */
export const useFollowedAgenciesMutation = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const authMutationFn = async ({ titleNumbers, follow }) => {
    const token = await getToken(history);
    if (token) {
      const httpMethod = follow ? 'POST' : 'DELETE';
      const url = `${followedAgenciesUrl}?title_num=${titleNumbers}`;
      return getData(url, token, httpMethod);
    }
    return null;
  };

  return useMutation({
    mutationFn: authMutationFn,

    // When updating the agencies the user follows, update the query cache for optimistic UI
    // (meaning there's no delay in the state of the UI while waiting for the http calls to complete)
    onMutate: async ({ titleNumbers, follow }) => {
      await queryClient.cancelQueries({
        queryKey: [followedAgenciesKey, followedAgenciesUrl]
      });
      const previous = queryClient.getQueryData([followedAgenciesKey, followedAgenciesUrl]);
      const previousFollowed = previous?.followed_agencies?.map((agency) => agency.title_num) ?? [];
      const titleNumberArray = titleNumbers.split(',');
      const newData = follow
        ? previousFollowed.concat(titleNumberArray)
        : previousFollowed.filter((val) => !titleNumberArray.includes(val));
      const newFollowed = newData.map((num) => {
        return { title_num: num };
      });
      queryClient.setQueryData([followedAgenciesKey, followedAgenciesUrl], {
        followed_agencies: newFollowed
      });
      return { previous };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(followedAgenciesKey);
    }
  });
};
