import { Agency } from 'pages/MyIAR/Home/AgencyList/AgencyTopic/Agency/Agency';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';

import { ToggleFollowButton } from '../ToggleFollowButton/ToggleFollowButton';
import styles from './AgencyTopic.module.css';

export const AgencyTopic = ({ filters, toggleItem, topic }) => {
  const [childrenOpen, setChildrenOpen] = useState(false);
  const [childrenSearchOpen, setChildrenSearchOpen] = useState(false);

  const nameMatches = (name, filterString) => {
    return name.toLowerCase().includes(filterString);
  };

  const isVisible = (item) => {
    if (item.children) {
      const childVisible = item.children.some(
        (child) =>
          nameMatches(child.agency_name, filters.filterString) &&
          (child.following || filters.filterSelection === 'all')
      );
      const topicVisible =
        (filters.filterSelection === 'all' || item.following) &&
        nameMatches(item.topicName, filters.filterString);
      return childVisible || topicVisible;
    } else {
      const inFilter = filters.filterString
        ? nameMatches(item.agency_name, filters.filterString)
        : true;
      const inFollow = filters.filterSelection === 'all' || item.following;
      return inFilter && inFollow;
    }
  };

  useEffect(() => {
    const showChildren =
      (filters.filterString &&
        topic.children.some((child) => nameMatches(child.agency_name, filters.filterString))) ||
      (filters.filterSelection === 'following' && topic.children.some((child) => child.following));
    setChildrenSearchOpen(showChildren);
  }, [filters, topic.children]);

  if (!isVisible(topic)) {
    return null;
  }

  return (
    <div>
      <div className={styles.topicHeader}>
        <button
          aria-expanded={childrenOpen || childrenSearchOpen}
          className={styles.toggleButton}
          onClick={() => setChildrenOpen((cur) => !cur)}
          type="button"
        >
          <RiArrowDownSLine
            size="1rem"
            className={styles.toggleButtonIcon}
            title="accordion toggle icon"
          />
          <span>{topic.topicName}</span>
        </button>
        <ToggleFollowButton entity={topic} onChange={toggleItem} />
      </div>
      <div
        className={
          childrenOpen || childrenSearchOpen
            ? `${styles.agencyListContainer} ${styles.open}`
            : styles.agencyListContainer
        }
      >
        <div className={styles.agencyList}>
          {topic.children
            .filter((agency) => isVisible(agency))
            .map((agency) => (
              <Agency agency={agency} key={agency.agency_id} toggleItem={toggleItem} />
            ))}
        </div>
      </div>
    </div>
  );
};

AgencyTopic.propTypes = {
  filters: PropTypes.shape({
    filterSelection: PropTypes.string,
    filterString: PropTypes.string
  }),
  toggleItem: PropTypes.func.isRequired,
  topic: PropTypes.any.isRequired
};
