import { useCallback, useState } from 'react';
import { OPEN_SEARCH } from 'util/API';
import { docGroup } from 'util/documentUtil';

export const defaultStart = 0;
export const defaultSize = 50;

const buildQuery = (params) => {
  // If the query is for a document "specifier", we'll want an explicit field to search.
  let fields = undefined;

  const isIACQuery = /^\d{2,3} IAC [\d.-]+$/.test(params.search);
  const isICQuery = /^IC [\d.-]+$/.test(params.search);
  const isLSAQuery = /^\d{2}-\d{1,4}$/.test(params.search);
  const isDINQuery = /^\d{8}-IR-\d{9}[A-Za-z]{3}$/.test(params.search);

  if (isDINQuery) {
    fields = ['din'];
  } else if (isIACQuery || isICQuery) {
    fields = ['linked_docs'];
  } else if (isLSAQuery) {
    fields = ['lsa_doc_num_display'];
  }

  const queryStringClause = {
    must: params.search?.length
      ? {
          simple_query_string: {
            default_operator: 'AND',
            fields,
            query: params.search
          }
        }
      : undefined
  };
  const codeClauses = [];
  const registerClauses = [];
  const codeQuery = {
    bool: {
      ...queryStringClause,
      filter: codeClauses
    }
  };
  const registerQuery = {
    bool: {
      ...queryStringClause,
      filter: registerClauses
    }
  };

  if (params?.document_group) {
    registerClauses.push({
      term: { collection: 'irdoc' }
    });

    if (params.document_group !== docGroup.all && params.document_group !== docGroup.ir) {
      registerClauses.push({
        term: { document_group: params.document_group }
      });
    }
    if (params?.archived !== true) {
      registerClauses.push({
        term: { archived: params.archived }
      });
    }
    if (params?.fiscallySignificant) {
      registerClauses.push({
        term: { fiscally_significant: true }
      });
    }

    if (params?.startDate && params?.endDate) {
      registerClauses.push({
        range: {
          date_posted: {
            gte: params.startDate.toISOString(),
            lte: params.endDate.toISOString()
          }
        }
      });
    }

    if (params.topics?.length) {
      registerClauses.push({
        terms: { topic_ids: params.topics }
      });
    }

    if (params.agencies?.length) {
      registerClauses.push({
        terms: { title_num: params.agencies }
      });
    }

    if (params.docTypes?.length && params.docSubTypes?.length) {
      registerClauses.push({
        terms: { doc_type: [...params.docTypes, 'NR'] }
      });
      registerClauses.push({
        terms: { doc_subtype: params.docSubTypes }
      });
    } else if (params.docTypes?.length) {
      registerClauses.push({
        terms: { doc_type: params.docTypes }
      });
    } else if (params.docSubTypes?.length) {
      registerClauses.push({
        terms: { doc_subtype: params.docSubTypes }
      });
    }
  }

  if (!params?.document_group || params?.document_group === docGroup.all) {
    codeClauses.push({
      term: { collection: 'iac' }
    });
    if (params?.editions?.length) {
      codeClauses.push({
        terms: { edition_year: params.editions }
      });
    }
    if (params.titles?.length) {
      codeClauses.push({
        terms: { title_num: params.titles }
      });
    }
  }

  if (codeClauses.length && !registerClauses.length) {
    return codeQuery;
  } else if (!codeClauses.length && registerClauses.length) {
    return registerQuery;
  } else {
    return {
      bool: {
        should: [codeQuery, registerQuery]
      }
    };
  }
};

const buildRequest = (params) => {
  return {
    _source: {
      excludes: ['doc_text', 'linked_docs']
    },
    // help understand scoring, if we need it
    explain: false,
    from: params.start ?? defaultStart,
    size: params.size ?? defaultSize,
    sort: [
      {
        date_posted: {
          order: 'desc'
        }
      },
      {
        edition_year: {
          order: 'desc'
        }
      },
      {
        title_num: {
          order: 'asc'
        }
      },
      {
        article_num: {
          order: 'asc'
        }
      }
    ],
    highlight: {
      fields: {
        doc_text: {
          no_match_size: 250
        }
      },
      max_analyzer_offset: 1000000
    },
    // NOTE: 'track_total_hits' is NOT currently documented as part of the request body object!
    // needed in case a search has >10k hits
    track_total_hits: true,
    query: buildQuery(params),
    aggs: {
      documentGroup: {
        terms: {
          field: 'document_group'
        }
      },
      collection: {
        terms: {
          field: 'collection'
        }
      }

      /* Currently not using these...
       * Keeping for now.
      documentType: {
        terms: {
          field: 'doc_type'
        }
      },
      documentSubtype: {
        terms: {
          field: 'doc_subtype'
        }
      },
      titleNumber: {
        terms: {
          field: 'title_num'
        }
      },
      editionYear: {
        terms: {
          field: 'edition_year'
        }
      },
      datePosted: {
        date_range: {
          field: 'date_posted',
          format: 'yyyy-MM-dd',
          ranges: [
            {
              key: 'last_seven_days',
              from: 'now-7d/d',
              to: 'now'
            },
            {
              key: 'last_thirty_days',
              from: 'now-30d/d',
              to: 'now'
            },
            {
              key: 'last_six_months',
              from: 'now-6M/d',
              to: 'now'
            },
            {
              key: 'older_than_6_months',
              to: 'now-6M/d-1d/d'
            }
          ]
        }
      }
      */
    }
  };
};

export const useOpenSearch = () => {
  const [searchState, setSearchState] = useState({
    data: null,
    error: null,
    isSearching: false
  });

  const executeSearch = useCallback((params) => {
    setSearchState({
      data: null,
      error: null,
      isSearching: true
    });

    const request = buildRequest(params);
    const requestAsString = JSON.stringify(request);

    fetch(OPEN_SEARCH, {
      body: requestAsString,
      method: 'POST',
      mode: 'cors'
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        setSearchState({
          data: null,
          error: `${response.status} - Error during search`,
          isSearching: false
        });
      })
      .then((json) => {
        setSearchState({
          data: json,
          error: null,
          isSearching: false
        });
      })
      .catch(() => {
        setSearchState({
          data: null,
          error: 'Error during search',
          isSearching: false
        });
      });
  }, []);

  const clearSearch = useCallback(() => {
    setSearchState({
      data: null,
      error: null,
      isSearching: false
    });
  }, []);

  return {
    clearSearch,
    data: searchState.data,
    error: searchState.error,
    executeSearch,
    isSearching: searchState.isSearching
  };
};
