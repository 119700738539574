import { Button } from 'components/Button/Button';
import { FadeScrollPanel } from 'components/FadeScrollPanel/FadeScrollPanel';
import { PostedDateFilter } from 'components/PostedDateFilter/PostedDateFilter';
import { AdditionalFilters } from 'pages/Search/AdditionalFilters/AdditionalFilters';
import { DocTypesFilter } from 'pages/Search/Filters/DocTypesFilter';
import { EditionYearFilter } from 'pages/Search/Filters/EditionYearFilter';
import { FiscallySignificantFilter } from 'pages/Search/Filters/FiscallySignificantFilter';
import { IncludeArchivedFilter } from 'pages/Search/Filters/IncludeArchivedFilter';
import { TitleFilter } from 'pages/Search/Filters/TitleFilter';
import { TopicAndAgencyFilter } from 'pages/Search/Filters/TopicAndAgencyFilter';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { searchParams } from 'util/shapes';

import styles from './TabbedSearchFilters.module.css';

/**
 * Search Filters
 * @param handleFilterChange
 * @param onCloseFilters
 * @param params
 * @param tabId
 * @returns {JSX.Element}
 * @constructor
 */
export const TabbedSearchFilters = forwardRef(function TabbedSearchFilters(
  { handleFilterChange, onCloseFilters, params, tabId },
  ref
) {
  const { hash } = useLocation();
  const [stackFilters, setStackFilters] = useState(false);

  const filterClasses = stackFilters
    ? `${styles.filterControls} ${styles.stack}`
    : styles.filterControls;

  useEffect(() => {
    setStackFilters(false);
  }, [hash]);

  return (
    <div ref={ref}>
      <FadeScrollPanel additionalClassName={styles.panelWrapper}>
        <div className={styles.filterWrapper}>
          <div className={filterClasses}>
            {tabId !== 'iac' ? (
              <>
                <PostedDateFilter
                  dateRange={params.dateRange}
                  endDate={params.endDate}
                  isActive={true}
                  onChange={handleFilterChange}
                  startDate={params.startDate}
                />
                <TopicAndAgencyFilter
                  handleFilterChange={handleFilterChange}
                  handleToggle={setStackFilters}
                  selectedAgencies={params.agencies}
                  selectedTopics={params.topics}
                />
                <DocTypesFilter
                  handleToggle={setStackFilters}
                  handleFilterChange={handleFilterChange}
                  selectedSubTypes={params.docSubTypes}
                  selectedTypes={params.docTypes}
                  tabId={tabId}
                />
              </>
            ) : null}
            {tabId === 'iac' || tabId === 'all' ? (
              <>
                <EditionYearFilter
                  handleFilterChange={handleFilterChange}
                  handleToggle={setStackFilters}
                  selectedEditions={params.editions}
                />
                <TitleFilter
                  handleFilterChange={handleFilterChange}
                  handleToggle={setStackFilters}
                  selectedTitles={params.titles}
                />
              </>
            ) : null}
            {tabId !== 'iac' && tabId !== 'pending' ? (
              <IncludeArchivedFilter
                handleFilterChange={handleFilterChange}
                isChecked={params.archived}
              />
            ) : null}
            <AdditionalFilters>
              {tabId !== 'iac' ? (
                <FiscallySignificantFilter
                  handleFilterChange={handleFilterChange}
                  isChecked={params.fiscallySignificant}
                />
              ) : null}
            </AdditionalFilters>
          </div>
          <div className={styles.modalButtonContainer}>
            <Button action={onCloseFilters} type="button">
              Done
            </Button>
          </div>
        </div>
      </FadeScrollPanel>
    </div>
  );
});

TabbedSearchFilters.propTypes = {
  filterRef: PropTypes.any,
  handleFilterChange: PropTypes.func.isRequired,
  onCloseFilters: PropTypes.func.isRequired,
  params: searchParams,
  tabId: PropTypes.string
};
