import PropTypes from 'prop-types';

import styles from './CheckboxFilter.module.css';

/**
 * Simple checkbox filter
 * @param children
 * @param handleFilterChange
 * @param isChecked
 * @param label
 * @param name
 * @returns {JSX.Element}
 * @constructor
 */
export const CheckboxFilter = ({ children, handleFilterChange, isChecked, label, name }) => {
  const id = `checkbox-filter-${name}`;
  return (
    <div className={styles.checkboxFilter}>
      <div>
        <label htmlFor={id}>{label}</label>
        <input
          checked={isChecked ?? false}
          id={id}
          name={name}
          onChange={(e) => handleFilterChange({ [name]: e.target.checked })}
          type="checkbox"
        />
      </div>
      {children}
    </div>
  );
};

CheckboxFilter.propTypes = {
  children: PropTypes.node,
  handleFilterChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};
