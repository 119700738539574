import searchIcon from 'assets/static/images/search-icon.png';
import { Link } from 'react-router-dom';
import { buildSearchQueryString } from 'util/searchUtil';
import { searchParams } from 'util/shapes';

import styles from './SearchButton.module.css';

/**
 * Floating search link button
 * @param params
 * @returns {JSX.Element}
 * @constructor
 */
export const SearchButton = ({ params }) => {
  const searchUrl = `/search?${buildSearchQueryString(params)}`;
  return (
    <Link to={searchUrl} className={styles.searchButton} aria-label="search">
      <img src={searchIcon} alt="search icon" />
    </Link>
  );
};

SearchButton.propTypes = {
  params: searchParams.isRequired
};
