import PropTypes from 'prop-types';
import { RiAddLine, RiCloseLine, RiStopFill } from 'react-icons/ri';

import styles from './ToggleFollowButton.module.css';

/**
 * Toggle following / unfollowing status
 * @param entity agency or topic
 * @param onChange function to call when the user changes following status
 * @returns {JSX.Element}
 * @constructor
 */
export const ToggleFollowButton = ({ entity, onChange }) => {
  const isPartiallyFollowing = entity.children && entity.children.some((child) => child.following);
  const toggleEntity = () => {
    onChange(entity, !entity.following);
  };

  return (
    <button
      aria-pressed={entity.following}
      aria-label={`${entity.following ? 'un' : ''}press to ${entity.following ? 'un' : ''}follow "${
        entity.agency_name || entity.topicName
      }"`}
      className={styles.followButton}
      onClick={() => toggleEntity(entity.name)}
    >
      {entity.following ? (
        <>
          <RiCloseLine size="1rem" /> Following
        </>
      ) : isPartiallyFollowing ? (
        <>
          <RiStopFill size="1rem" /> Follow
        </>
      ) : (
        <>
          <RiAddLine size="1rem" /> Follow
        </>
      )}
    </button>
  );
};

ToggleFollowButton.propTypes = {
  entity: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};
