import { Loading } from 'colosseum';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { useIrDocs } from 'hooks/documentHooks';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getDocCounts, getDocTypeQueryParam } from 'util/currentRegisterUtil';
import { sixDaysAgoFn, todayFn } from 'util/dateUtil';

import styles from '../RegisterDocuments.module.css';

const today = todayFn();
const sixDaysAgo = sixDaysAgoFn();

export const CurrentRegister = () => {
  const { data, error, isLoading } = useIrDocs(sixDaysAgo, today);
  const [docCounts, setDocCounts] = useState(null);

  useEffect(() => {
    setDocCounts(data ? getDocCounts(data) : null);
  }, [data]);

  return (
    <div>
      <AccentHeader level={2} subtitle="Last 7 days">
        Current Register
      </AccentHeader>
      {isLoading ? (
        <div className={styles.loadingWrapper}>
          <Loading light={true} screenreaderText="Loading Current Register information" />
        </div>
      ) : error ? (
        <p>Unable to load register.</p>
      ) : (
        <ul className={styles.documentList}>
          {docCounts?.map((docCount) => (
            <li key={`currentRegister-${docCount.type}`}>
              <strong>{docCount.count}</strong>
              <Link
                to={`/search?dateRange=sevenDays&docTypes=${getDocTypeQueryParam(docCount.group)}`}
              >
                {docCount.type}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
