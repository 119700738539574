import { Loading } from 'colosseum';
import { useOpenSearch } from 'hooks/openSearchHook';
import { TabbedSearchFilters } from 'pages/Search/TabbedSearchFilters/TabbedSearchFilters';
import { TabbedSearchResults } from 'pages/Search/TabbedSearchResults/TabbedSearchResults';
import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { searchParams } from 'util/shapes';

import styles from './SearchTabPanel.module.css';

/**
 * Panel for an individual search tab.
 * @param onCloseFilters
 * @param params
 * @param tabId
 * @param updateSearch
 * @returns {JSX.Element}
 * @constructor
 */
export const SearchTabPanel = forwardRef(function SearchTabPanel(
  { onCloseFilters, params, tabId, updateSearch },
  ref
) {
  const { data, executeSearch, isSearching } = useOpenSearch();

  useEffect(() => {
    executeSearch(params);
  }, [executeSearch, params]);

  return (
    <div className={styles.searchContent}>
      <TabbedSearchFilters
        ref={ref}
        handleFilterChange={(filter) => updateSearch(filter, tabId)}
        onCloseFilters={onCloseFilters}
        params={params}
        tabId={tabId}
      />
      {isSearching ? (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      ) : data ? (
        <TabbedSearchResults
          data={data}
          handlePageChange={(filter) => updateSearch(filter, tabId)}
          params={params}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
});

SearchTabPanel.propTypes = {
  onCloseFilters: PropTypes.func.isRequired,
  params: searchParams,
  tabId: PropTypes.string.isRequired,
  updateSearch: PropTypes.func.isRequired
};
