import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router';
import { API, getAuthData, getData, getToken } from 'util/API';

const userPreferencesUrl = `${API}/auth/userPreferences`;
const userPreferencesKey = 'userPreferences';

/**
 * Retrieve user preferences.
 *
 * @returns react-query "useQuery" response
 */
export const useUserPreferences = () => {
  const history = useHistory();

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [userPreferencesKey],
    queryFn: () => getAuthData(history, userPreferencesUrl, {}),
    notifyOnChangeProps: ['error', 'data', 'isLoading'],
    staleTime: Infinity
  });
};

/**
 * Set up a mutation for making authorized API calls to
 * update user preferences.
 *
 * (Note: the attempt to get a token will re-direct for
 * login if the token is missing or beyond the refresh timeout.)
 *
 * The mutation expects to be called with an object containing
 * - "optInEmail" a boolean for whether to send email when a
 *   followed agency publishes a new document
 *
 * Example 1 -- to opt in:
 *
 * <code>
 *   const mutation = useDocStageMutation();
 *   mutation.mutate({
 *     optInEmail: true
 *   });
 * </code>
 *
 *
 * SIDE EFFECTS:
 * - Invalidates the "userPreferencesKey" query, forcing the UI
 *   to re-fetch the user prefs for the admin page.
 *
 * @returns the return value of react-query's "useMutation"
 */
export const useUserPreferencesMutation = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const authMutationFn = async ({ optInEmail, optInMillionEmail }) => {
    const token = await getToken(history);
    if (token) {
      const params = new URLSearchParams();
      if (optInEmail !== undefined) {
        params.append('opt_in_email', optInEmail);
      }
      if (optInMillionEmail !== undefined) {
        params.append('opt_in_million_email', optInMillionEmail);
      }

      const url = `${userPreferencesUrl}?${params.toString()}`;
      return getData(url, token, 'PUT');
    }
    return null;
  };

  return useMutation({
    mutationFn: authMutationFn,

    onSuccess: () => {
      queryClient.invalidateQueries(userPreferencesKey);
    }
  });
};
