import { Auth } from 'aws-amplify';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { Button } from 'components/Button/Button';
import { InputField } from 'components/InputField/InputField';
import { MainContentWrapper } from 'components/MainContentWrapper/MainContentWrapper';
import { useInputVisitedMarker } from 'hooks/useInputVisitedMarker';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router';

import styles from '../MyIAR.module.css';

export const ForgotPassword = () => {
  const history = useHistory();
  const [visitedFields, markVisited, markAllVisited] = useInputVisitedMarker(['username']);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    markAllVisited();
    if (e.target.checkValidity()) {
      try {
        const form = new FormData(e.target);
        const email = form.get('username');
        await Auth.forgotPassword(email);
        history.push('/myIAR/verifyReset', { username: email });
      } catch (error) {
        toast.error(error?.message ?? 'Error attempting to reset account', {
          position: 'top-right'
        });
      }
    }
  };

  return (
    <MainContentWrapper>
      <Helmet>
        <title>Forgot Password | MyIAR | IARP</title>
      </Helmet>
      <AccentHeader level={1}>Forgot Password</AccentHeader>
      <p>Enter your email below to initiate a password reset.</p>
      <form onSubmit={handleForgotPassword} noValidate className={styles.simpleForm}>
        <InputField
          ariaLabel="Enter your email address"
          autocompleteType="username"
          errorLabel="Required"
          handleBlur={markVisited}
          id="username"
          isVisited={visitedFields.username}
          label="Email"
          name="username"
        />
        <div className={styles.formControls}>
          <Button type="submit">Reset Password</Button>
        </div>
      </form>
    </MainContentWrapper>
  );
};
