import PropTypes from 'prop-types';

export const articleNavType = PropTypes.shape({
  article: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleChange: PropTypes.bool,
  year: PropTypes.string.isRequired
});

export const indianaRegisterDocument = PropTypes.shape({
  cat_id: PropTypes.number,
  cat_name: PropTypes.string,
  date_posted: PropTypes.string,
  date_rcvd: PropTypes.string,
  digest: PropTypes.string,
  din: PropTypes.string,
  doc_type: PropTypes.string,
  doct_type_id: PropTypes.number,
  dup_code: PropTypes.string,
  sub_cat_id: PropTypes.number,
  sub_cat_name: PropTypes.string,
  id: PropTypes.number,
  lsa_doc_num: PropTypes.string,
  title_num: PropTypes.string
});

export const indianaRegisterCategory = PropTypes.shape({
  cat_id: PropTypes.number,
  cat_name: PropTypes.string,
  docs: PropTypes.arrayOf(indianaRegisterDocument),
  sub_cat_id: PropTypes.number,
  sub_cat_name: PropTypes.string,
  sub_cats: PropTypes.array
});

export const relatedRegisterDocument = PropTypes.shape({
  date_posted: PropTypes.string,
  digest: PropTypes.string,
  din: PropTypes.string,
  doc_type: PropTypes.string,
  id: PropTypes.number,
  lsa_doc_num: PropTypes.string,
  title_num: PropTypes.string
});

export const codeArticle = PropTypes.shape({
  article_id: PropTypes.number,
  article_name: PropTypes.string,
  article_num: PropTypes.string,
  path_and_file: PropTypes.string
});

export const codeTitle = PropTypes.shape({
  article: PropTypes.arrayOf(codeArticle),
  title_id: PropTypes.number,
  title_name: PropTypes.string,
  title_num: PropTypes.string
});

export const iacSearchResult = PropTypes.shape({
  article_filename: PropTypes.string,
  article_name: PropTypes.string,
  article_num: PropTypes.string,
  collection: PropTypes.string,
  doc_text: PropTypes.string,
  edition_year: PropTypes.string,
  linked_docs: PropTypes.arrayOf(PropTypes.string),
  title_num: PropTypes.string
});

export const iarSearchResult = PropTypes.shape({
  collection: PropTypes.string,
  date_posted: PropTypes.string,
  din: PropTypes.string,
  doc_dup_code: PropTypes.string,
  doc_text: PropTypes.string,
  doc_type: PropTypes.string,
  linked_docs: PropTypes.arrayOf(PropTypes.string),
  lsa_doc_num: PropTypes.string,
  lsa_doc_num_display: PropTypes.string,
  original_title_name: PropTypes.string,
  title_num: PropTypes.string
});

export const dateRanges = {
  sevenDays: 'sevenDays',
  thirtyDays: 'thirtyDays',
  sixMonths: 'sixMonths',
  custom: 'custom'
};
export const dateRange = PropTypes.oneOf([
  dateRanges.sevenDays,
  dateRanges.thirtyDays,
  dateRanges.sixMonths,
  dateRanges.custom
]);

export const searchParams = PropTypes.shape({
  agencies: PropTypes.arrayOf(PropTypes.string),
  commentPeriodEndDate: PropTypes.instanceOf(Date),
  commentPeriodStartDate: PropTypes.instanceOf(Date),
  dateRange: dateRange,
  docSubTypes: PropTypes.arrayOf(PropTypes.string),
  docTypes: PropTypes.arrayOf(PropTypes.string),
  editions: PropTypes.arrayOf(PropTypes.string),
  endDate: PropTypes.instanceOf(Date),
  publicHearingEndDate: PropTypes.instanceOf(Date),
  publicHearingStartDate: PropTypes.instanceOf(Date),
  scope: PropTypes.shape({
    code: PropTypes.bool,
    register: PropTypes.bool
  }),
  search: PropTypes.string,
  size: PropTypes.number,
  start: PropTypes.number,
  startDate: PropTypes.instanceOf(Date),
  tempRuleDate: PropTypes.instanceOf(Date)
});

export const claims = {
  isAdmin: 'isAdmin'
};

export const claim = PropTypes.oneOf([claims.isAdmin]);

export const documentStagePrivate = 'private';
export const documentStagePrivateReady = 'private_ready';
export const documentStagePublic = 'public';

export const documentStage = PropTypes.oneOf([
  documentStagePrivate,
  documentStagePrivateReady,
  documentStagePublic
]);

// CloudSearch shapes...

export const collection = PropTypes.oneOf(['iac', 'irdoc']);

export const irDocHit = PropTypes.shape({
  collection: collection,
  date_posted: PropTypes.string,
  din: PropTypes.string,
  doc_dup_code: PropTypes.string,
  doc_text: PropTypes.string,
  doc_type: PropTypes.string,
  lsa_doc_num: PropTypes.string,
  title_num: PropTypes.string
});

export const iacDocHit = PropTypes.shape({
  article_filename: PropTypes.string,
  article_name: PropTypes.string,
  article_num: PropTypes.string,
  collection: collection,
  doc_text: PropTypes.string,
  edition_year: PropTypes.string,
  title_num: PropTypes.string
});

export const aggregation = PropTypes.shape({
  buckets: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      doc_count: PropTypes.number
    })
  )
});

export const openSearchHit = PropTypes.shape({
  _id: PropTypes.string,
  _source: PropTypes.oneOfType([iacDocHit, irDocHit]),
  highlight: PropTypes.shape({
    doc_text: PropTypes.arrayOf(PropTypes.string)
  })
});

export const openSearchResults = PropTypes.shape({
  took: PropTypes.number,
  timed_out: PropTypes.bool,
  hits: PropTypes.shape({
    total: PropTypes.shape({
      value: PropTypes.number,
      relation: PropTypes.string
    }),
    max_score: PropTypes.any,
    hits: PropTypes.arrayOf(openSearchHit)
  }),
  aggregations: PropTypes.shape({
    documentGroup: aggregation,
    collection: aggregation
  })
});
