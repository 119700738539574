import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { Helmet } from 'react-helmet-async';

export const Intake = () => {
  return (
    <>
      <Helmet>
        <title>Intake | IARP</title>
      </Helmet>
      <AccentHeader level={2} underline="subtle">
        Intake
      </AccentHeader>
      <p>Placeholder for the Intake feature.</p>
    </>
  );
};
