import PropTypes from 'prop-types';

/**
 * Parse the name of the Title so that the "TITLE N" portion can be bolded.
 * @param additionalClassName optional class to apply
 * @param number the title number
 * @param tagName optional html tag to use (defaults to <div>)
 * @param title the title's title
 * @returns {JSX.Element}
 * @constructor
 */
export const CodeTitleName = ({ additionalClassName, number, tagName, title }) => {
  const Tag = tagName ?? 'div';
  return (
    <Tag className={additionalClassName ?? undefined}>
      <strong>TITLE {number}</strong> {title}
    </Tag>
  );
};

CodeTitleName.propTypes = {
  additionalClassName: PropTypes.string,
  number: PropTypes.string.isRequired,
  tagName: PropTypes.string,
  title: PropTypes.string.isRequired
};
