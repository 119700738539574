import searchIcon from 'assets/static/images/search-icon.png';
import { Select } from 'colosseum';
import { useState } from 'react';
import { useHistory } from 'react-router';

import styles from './Hero.module.css';

export const Hero = () => {
  const history = useHistory();
  const [searchError, setSearchError] = useState(false);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchError(false);
    const form = new FormData(e.target);
    const searchTerm = form.get('search');
    const tab = form.get('filter');
    if (searchTerm) {
      history.push(
        `/search?search=${encodeURIComponent(searchTerm)}${
          tab === 'all' ? '&archived=true' : ''
        }#${tab}`
      );
    } else {
      setSearchError(true);
    }
  };

  return (
    <section className={styles.heroContainer}>
      <form onSubmit={(e) => handleSearch(e)} className={styles.heroSearchForm} role="search">
        <input
          type="search"
          name="search"
          placeholder={`${searchError ? 'Enter a search term' : 'Search'}`}
          className={
            searchError ? `${styles.heroSearchInput} ${styles.searchError}` : styles.heroSearchInput
          }
        />
        <div className={styles.searchButtons}>
          <Select
            compact={true}
            defaultValue={{ value: 'pending', label: 'Pending Rules' }}
            name="filter"
            options={[
              { value: 'pending', label: 'Pending Rules' },
              { value: 'iac', label: 'IAC' },
              { value: 'agencyGuidance', label: 'Agency Guidance' },
              { value: 'gov', label: 'Gov' },
              { value: 'ag', label: 'AG Opinions' },
              { value: 'all', label: 'All' }
            ]}
          />
          <button type="submit" className={styles.heroSearchButton}>
            <img src={searchIcon} alt="search icon" />
          </button>
        </div>
      </form>
    </section>
  );
};
