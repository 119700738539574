import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { useLayoutEffect, useRef, useState } from 'react';
import { RiCloseLine } from 'react-icons/ri';

import styles from './Banner.module.css';

export const Banner = () => {
  const [bannerDismissed, setBannerDismissed] = useState(false);
  const bannerRef = useRef(null);

  const updateOffset = () => {
    const rectangle = bannerRef.current.getBoundingClientRect();
    document.documentElement.style.setProperty('--bannerOffset', `${rectangle.height}px`);
  };

  // Watch for component rendering and page resizing to set the --bannerOffset custom prop.
  // That prop is needed to handle sticky and absolute positioning of various elements in the app.
  useLayoutEffect(() => {
    if (!bannerDismissed && bannerRef.current) {
      updateOffset();
      window.addEventListener('resize', updateOffset);
    }

    return () => {
      window.removeEventListener('resize', updateOffset);
    };
  }, [bannerDismissed]);

  const dismissBanner = () => {
    setBannerDismissed(true);
    document.documentElement.style.setProperty('--bannerOffset', '0rem');
    window.removeEventListener('resize', updateOffset);
  };

  if (bannerDismissed) {
    return null;
  }

  return (
    <div className={styles.banner} role="banner" ref={bannerRef}>
      <button
        onClick={dismissBanner}
        aria-label="dismiss the warning banner"
        className={styles.dismissButton}
      >
        <RiCloseLine />
      </button>
      <div className={styles.message}>
        This new IAR site is currently under construction. If you find specific pages or features
        unavailable, please visit our current site at{' '}
        <ExternalLink url="http://iac.iga.in.gov/iac/" linkText="http://iac.iga.in.gov/iac/" />. To
        provide feedback on issues please email us at{' '}
        <a href="mailto:iarhelpdesk@iga.in.gov">iarhelpdesk@iga.in.gov</a>.
      </div>
    </div>
  );
};
