import PropTypes from 'prop-types';
import { useState } from 'react';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';

import styles from './InputField.module.css';

/**
 * Password field, including the show/hide toggle.
 *
 * @param additionalClassName an additional class to apply to the component's wrapper
 * @param ariaLabel aria-label for the `<input>`
 * @param autocompleteType `autocomplete` prop to help automatically fill in information. https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
 * @param errorLabel Standard validation error message
 * @param handleBlur function to handle blur
 * @param id `<input>` element ID
 * @param isVisited whether or not the field has been visited
 * @param label Label for the field
 * @param name name prop for the field (for form submission)
 * @returns {JSX.Element}
 * @constructor
 */
export const PasswordField = ({
  additionalClassName,
  ariaLabel,
  autocompleteType,
  errorLabel,
  handleBlur,
  id,
  isVisited,
  label,
  name
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const checkValidity = (e) => {
    // For keyboard events, only check validity when the user
    // indicates they are done by pressing 'Enter'.
    if (e.type === 'keydown' && e.key !== 'Enter') return;

    setIsValid(e.target.validity.valid);
    handleBlur(e);
  };

  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setIsPasswordVisible((current) => !current);
  };

  return (
    <div
      className={additionalClassName ? `${styles.field} ${additionalClassName}` : styles.field}
      data-visited={isVisited}
    >
      <div className={styles.fieldLabelGroup}>
        <label htmlFor="password-field">{label ?? 'Password'}</label>
        {!isValid ? (
          <label htmlFor="password-field" className={styles.errorLabel}>
            {errorLabel}
          </label>
        ) : null}
      </div>
      <span className={styles.passwordWrapper}>
        <input
          type={isPasswordVisible ? 'text' : 'password'}
          autoComplete={autocompleteType || undefined}
          name={name}
          id={id}
          required
          aria-label={ariaLabel}
          onBlur={checkValidity}
          onKeyDown={checkValidity}
        />
        <button
          aria-label={isPasswordVisible ? 'hide password' : 'show password'}
          type="button"
          className={styles.showPasswordButton}
          onClick={togglePasswordVisibility}
        >
          {isPasswordVisible ? (
            <RiEyeOffLine title="hide password icon" />
          ) : (
            <RiEyeLine title="show password icon" />
          )}
        </button>
      </span>
    </div>
  );
};

PasswordField.propTypes = {
  additionalClassName: PropTypes.string,
  ariaLabel: PropTypes.string.isRequired,
  autocompleteType: PropTypes.string.isRequired,
  errorLabel: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isVisited: PropTypes.bool.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired
};
