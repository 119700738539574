import { Loading } from 'colosseum';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { Button } from 'components/Button/Button';
import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import { useOpenSearch } from 'hooks/openSearchHook';
import styles from 'pages/Home/RegisterDocuments/RegisterDocuments.module.css';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { thirtyDaysAgoFn, todayFn } from 'util/dateUtil';
import { docGroup } from 'util/documentUtil';

export const PublishersReceipts = () => {
  const { data, error, executeSearch, isSearching } = useOpenSearch();
  const [receipts, setReceipts] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const { getAgencyName } = useContext(RefDataContext);

  // Call the search endpoint to get the data
  useEffect(() => {
    executeSearch({
      archived: false,
      docTypes: ['AF'],
      document_group: docGroup.ir,
      endDate: todayFn(),
      startDate: thirtyDaysAgoFn()
    });
  }, [executeSearch]);

  // Once we have the data, transform it for display
  useEffect(() => {
    // get count by agency
    const receiptCategories = {};
    data?.hits?.hits?.forEach((doc) => {
      if (receiptCategories[doc?._source?.title_num]) {
        receiptCategories[doc?._source?.title_num] = receiptCategories[doc?._source?.title_num] + 1;
      } else {
        receiptCategories[doc?._source?.title_num] = 1;
      }
    });

    // convert the counts to an array
    const currentReceipts = [];
    Object.getOwnPropertyNames(receiptCategories)?.forEach((key) => {
      currentReceipts.push({
        count: receiptCategories[key],
        name: getAgencyName(key),
        title: key
      });
    });

    // sort by count (most first), if tied, alpha on the agency name (a-z)
    currentReceipts.sort((a, b) =>
      a.count === b.count ? a.name?.localeCompare(b.name) : a.count > b.count ? -1 : 1
    );
    setReceipts(currentReceipts);
  }, [data, getAgencyName]);

  return (
    <div>
      <AccentHeader level={2} subtitle="Last 30 days">
        Publisher&apos;s Receipts
      </AccentHeader>
      {isSearching ? (
        <div className={styles.loadingWrapper}>
          <Loading light={true} screenreaderText="Loading Publisher's Receipts" />
        </div>
      ) : error ? (
        <p>Unable to retrieve Publisher&apos;s Receipts.</p>
      ) : receipts?.length ? (
        <>
          <ol className={styles.documentList}>
            {receipts?.slice(0, showAll ? receipts?.length : 5).map((docInfo) => (
              <li key={`publishersReceipt-${docInfo.title}`}>
                <strong>{docInfo.count}</strong>
                <Link
                  aria-label={`Show the ${docInfo.count} Publisher's Receipts for the agency`}
                  to={`/search?dateRange=thirtyDays&docTypes=AF&agencies=${docInfo.title}#ir`}
                >
                  {docInfo.name}
                </Link>
              </li>
            ))}
          </ol>
          {receipts?.length > 5 ? (
            <Button action={() => setShowAll((cur) => !cur)} variants="inverted hollow">
              {showAll ? 'Show First 5' : `Show All ${receipts.length}`}
            </Button>
          ) : null}
        </>
      ) : (
        <p>No Publisher&apos;s Receipts in the past 30 days.</p>
      )}
    </div>
  );
};
