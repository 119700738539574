import searchIcon from 'assets/static/images/search-icon.png';
import PropTypes from 'prop-types';

import styles from './SearchInput.module.css';

export const SearchInput = ({
  buttonAriaLabel,
  defaultValue,
  disabled,
  inputAriaLabel,
  name,
  onChange,
  onSearch,
  placeholder,
  value
}) => {
  const handleClick = (e) => {
    if (onSearch) {
      onSearch(e);
    }
  };

  return (
    <span className={styles.searchInputWrapper}>
      <input
        aria-label={inputAriaLabel}
        className={styles.searchInput}
        defaultValue={defaultValue}
        disabled={disabled}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        type="search"
        value={value}
      />
      <button
        aria-label={buttonAriaLabel}
        className={styles.searchButton}
        onClick={handleClick}
        disabled={disabled}
        type="submit"
      >
        <img src={searchIcon} alt="search icon" />
      </button>
    </span>
  );
};

SearchInput.propTypes = {
  buttonAriaLabel: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  inputAriaLabel: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string
};
