import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { RiArrowDownSLine, RiCloseLine } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';

import styles from './OptionButton.module.css';

/**
 * Button / container for a filter option
 * @param ariaLabel
 * @param children
 * @param disabled
 * @param initOpen
 * @param isInvalid
 * @param label
 * @param onToggle
 * @returns {JSX.Element}
 * @constructor
 */
export const OptionButton = ({
  ariaLabel,
  children,
  disabled,
  initOpen,
  isInvalid,
  label,
  onToggle
}) => {
  const { hash } = useLocation();
  const [isOpen, setIsOpen] = useState(initOpen ?? false);
  const stacked = !!onToggle && isOpen;

  // If the control becomes inactive, close it.
  useEffect(() => {
    setIsOpen((cur) => (disabled ? false : cur));
  }, [disabled]);

  // close on hash change
  useEffect(() => {
    setIsOpen(false);
  }, [hash]);

  const toggleOptions = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    if (onToggle) {
      onToggle(newState);
    }
  };

  return (
    <div className={stacked ? styles.stackToTop : null}>
      <button
        aria-expanded={isOpen}
        aria-label={ariaLabel}
        className={
          isInvalid ? `${styles.optionButton} ${styles.invalidButton}` : styles.optionButton
        }
        disabled={disabled}
        onClick={toggleOptions}
        type="button"
      >
        <span>{stacked ? `Filter by ${label}` : label}</span>
        {stacked ? (
          <RiCloseLine size="2rem" className={styles.optionButtonIcon} title="close filter icon" />
        ) : (
          <RiArrowDownSLine
            size="2rem"
            className={styles.optionButtonIcon}
            title="accordion toggle icon"
          />
        )}
      </button>
      <div className={styles.options}>
        <div className={styles.optionsContent}>{children}</div>
      </div>
    </div>
  );
};

OptionButton.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  disabled: PropTypes.bool,
  initOpen: PropTypes.bool,
  isInvalid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onToggle: PropTypes.func
};
