import PropTypes from 'prop-types';

import styles from './SegmentedControl.module.css';

export const SegmentedControl = ({
  ariaLabel,
  controlName,
  disabled,
  items,
  onSelect,
  selectedVal
}) => {
  return (
    <div className={styles.segmentedControl} role="radiogroup" aria-label={ariaLabel}>
      {items.map((item) => (
        <span key={item.id}>
          <input
            checked={item.value === selectedVal}
            disabled={disabled}
            id={item.id}
            name={controlName}
            onChange={onSelect}
            type="radio"
            value={item.value}
          />
          <label htmlFor={item.id}>{item.label}</label>
        </span>
      ))}
    </div>
  );
};

SegmentedControl.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  controlName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedVal: PropTypes.string
};
