import PropTypes from 'prop-types';

import styles from './Error.module.css';

/**
 * Simple component to consistently show error messages.
 * @param children Error contents. Could simply be a string, or include markup or other components.
 * @returns {JSX.Element}
 * @constructor
 */
export const ErrorMessage = ({ children }) => {
  return <div className={styles.errorContainer}>{children}</div>;
};

ErrorMessage.propTypes = {
  children: PropTypes.node.isRequired
};
