/**
 * Overrides for variables from Colosseum, if necessary.
 */
export const Colors = {
  secondary: '#2B7DB3'
};
export const Typography = {
  bodyFont: 'Montserrat, normal',
  headingFont: 'Montserrat, normal'
};
