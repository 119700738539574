import PropTypes from 'prop-types';

import styles from './MainContentWrapper.module.css';

export const MainContentWrapper = ({ additionalClassName, children }) => {
  return (
    <div
      className={
        additionalClassName ? `${styles.mainWrapper} ${additionalClassName}` : styles.mainWrapper
      }
    >
      {children}
    </div>
  );
};

MainContentWrapper.propTypes = {
  additionalClassName: PropTypes.string,
  children: PropTypes.any
};
