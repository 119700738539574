import styles from 'pages/Home/RegisterDocuments/RegisterDocuments.module.css';

import { CurrentRegister } from './CurrentRegister/CurrentRegister';
import { PublishersReceipts } from './PublishersReceipts/PublishersReceipts';

export const RegisterDocuments = () => {
  return (
    <section className={styles.documentsContainer} aria-labelledby="register-documents-header">
      <h1 className={styles.documentsTitle} id="register-documents-header">
        Register Documents
      </h1>
      <div className={styles.documentGroups}>
        <CurrentRegister />
        <PublishersReceipts />
      </div>
    </section>
  );
};
