import PropTypes from 'prop-types';

import { ToggleFollowButton } from '../../ToggleFollowButton/ToggleFollowButton';
import styles from './Agency.module.css';

export const Agency = ({ agency, toggleItem }) => {
  return (
    <div className={styles.agency} key={agency.title_num}>
      <span>{agency.agency_name}</span>
      <ToggleFollowButton entity={agency} onChange={toggleItem} />
    </div>
  );
};

Agency.propTypes = {
  agency: PropTypes.shape({
    agency_name: PropTypes.string,
    title_num: PropTypes.string,
    following: PropTypes.bool
  }),
  toggleItem: PropTypes.func.isRequired
};
