import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import styles from './IRDocument.module.css';

/**
 * Render the data for an IR document in a user's dashboard
 * @param document the document metadata
 * @param emphasized whether to emphasize this document (bold for now)
 * @param externalLink whether to open the link in a new tab
 * @param disabled if true, do not show a link for the DIN
 * @returns {JSX.Element}
 * @constructor
 */
export const IRDocument = ({
  document,
  emphasized = false,
  externalLink = true,
  disabled = false
}) => {
  const { getDocTypeName } = useContext(RefDataContext);
  return (
    <li className={styles.irDocumentContainer}>
      <div className={emphasized ? `${styles.irDocument} ${styles.emphasized}` : styles.irDocument}>
        <div className={styles.docName}>
          {disabled ? (
            <span>{document.din}</span>
          ) : externalLink ? (
            <Link to={`/register/${document.din}`} rel="noopener noreferrer" target="_blank">
              {document.din}
            </Link>
          ) : (
            <Link to={`/register/${document.din}`}>{document.din}</Link>
          )}
        </div>
        <div className={styles.docType}>{getDocTypeName(document.doc_type)}</div>
        <div className={styles.date}>{format(new Date(document.date_posted), 'MM/dd/yy')}</div>
      </div>
    </li>
  );
};

IRDocument.propTypes = {
  document: PropTypes.shape({
    date_posted: PropTypes.string,
    din: PropTypes.string,
    doc_type: PropTypes.string
  }),
  emphasized: PropTypes.bool,
  externalLink: PropTypes.bool,
  disabled: PropTypes.bool
};
