import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { AuthContext } from 'components/AuthContext/AuthContext';
import { Button } from 'components/Button/Button';
import { InputField } from 'components/InputField/InputField';
import { useInputVisitedMarker } from 'hooks/useInputVisitedMarker';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import styles from '../../MyIAR.module.css';

export const Profile = () => {
  const { email: loggedInEmail } = useContext(AuthContext);
  const [visitedFields, markVisited, markAllVisited] = useInputVisitedMarker(['email']);

  const handleProfileChange = (e) => {
    e.preventDefault();
    markAllVisited();
  };

  return (
    <>
      <Helmet>
        <title>Profile | MyIAR | IARP</title>
      </Helmet>
      <AccentHeader level={2} underline="subtle">
        Edit Profile
      </AccentHeader>
      <form onSubmit={handleProfileChange} noValidate className={styles.simpleForm}>
        <InputField
          ariaLabel="Enter your email address"
          errorLabel="Required"
          handleBlur={markVisited}
          id="email"
          initialValue={loggedInEmail}
          isVisited={visitedFields.email}
          label="Email"
          name="email"
          readonly={true}
        />
        <div className={styles.formControls}>
          <Button disabled={true} type="submit">
            Update Profile
          </Button>
        </div>
      </form>
    </>
  );
};
