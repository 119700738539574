import PropTypes from 'prop-types';
import { createContext, useState } from 'react';
import { documentStagePrivate, documentStagePublic } from 'util/shapes';

export const AdminContext = createContext(undefined);

export const AdminProvider = ({ children }) => {
  const [docStage, setDocStage] = useState(documentStagePublic);
  const [isBannerVisible, setBannerVisible] = useState(false);

  // When making visible, show Private docs.
  // When making invisible, show Public docs.
  const updateBannerVisible = (isVisible) => {
    if (isVisible) {
      setDocStage(documentStagePrivate);
    } else {
      setDocStage(documentStagePublic);
    }
    setBannerVisible(isVisible);
  };

  return (
    <AdminContext.Provider
      value={{
        docStage,
        setDocStage,
        isBannerVisible,
        updateBannerVisible
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

AdminProvider.propTypes = {
  children: PropTypes.any
};
