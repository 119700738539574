import { Auth } from 'aws-amplify';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { Button } from 'components/Button/Button';
import { InputField } from 'components/InputField/InputField';
import { MainContentWrapper } from 'components/MainContentWrapper/MainContentWrapper';
import { useInputVisitedMarker } from 'hooks/useInputVisitedMarker';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import styles from './Verify.module.css';

export const Verify = () => {
  const { state } = useLocation();
  const history = useHistory();
  const [enteredUsername, setEnteredUsername] = useState(state?.username ?? '');
  const [visitedFields, markVisited, markAllVisited] = useInputVisitedMarker(['username', 'code']);
  let toastId;

  const handleVerification = async (e) => {
    e.preventDefault();
    if (toastId) {
      toast.dismiss(toastId);
    }
    if (e.target.checkValidity()) {
      const data = {};
      for (const [field, value] of new FormData(e.target).entries()) {
        data[field] = value;
      }

      try {
        await Auth.confirmSignUp(data.username, data.code);
        history.push('/myIAR/login', {
          message: 'Account successfully confirmed! You may now sign in.'
        });
      } catch (error) {
        toast.error(error?.message ?? 'Verification failed.', {
          position: 'top-right'
        });
      }
    } else {
      markAllVisited();
    }
  };

  const handleUsernameBlur = (e) => {
    markVisited(e);
    setEnteredUsername(e.target.value);
  };

  const handleResendCode = async (e) => {
    e.preventDefault();
    if (enteredUsername) {
      try {
        await Auth.resendSignUp(enteredUsername);
        toast.success(
          `Resending code to ${enteredUsername}. If you do not receive a code, you may need to sign up.`,
          {
            duration: 15000,
            position: 'top-right'
          }
        );
      } catch (error) {
        toast.error(error?.message ?? 'Error resending verification code.', {
          position: 'top-right'
        });
      }
    } else {
      toast.error('You must enter an email address', { position: 'top-right' });
    }
  };

  return (
    <MainContentWrapper>
      <Helmet>
        <title>Verify Account | MyIAR | IARP</title>
      </Helmet>
      <AccentHeader level={1}>Verify Email</AccentHeader>
      <p>Enter the verification code sent to your email address.</p>
      <p>
        If you have not yet signed up for an account, please <Link to="/myIAR/signUp">sign up</Link>{' '}
        before accessing this page.
      </p>
      <form onSubmit={handleVerification} noValidate className={styles.verifyForm}>
        <InputField
          additionalClassName={styles.emailField}
          ariaLabel="Enter an email for your username"
          autocompleteType="email"
          errorLabel="Valid email required"
          handleBlur={handleUsernameBlur}
          id="username-field"
          initialValue={state?.username}
          inputType="email"
          isVisited={visitedFields.username}
          label="Email"
          name="username"
          readonly={!!state?.username}
        />
        <InputField
          additionalClassName={styles.verificationCodeField}
          ariaLabel="Enter the verification code from email"
          errorLabel="Required"
          handleBlur={markVisited}
          id="verificationCode"
          isVisited={visitedFields.code}
          label="Verification Code"
          name="code"
        />
        <div className={styles.formControls}>
          <Button additionalClassName={styles.verifyButton} type="submit">
            Verify
          </Button>
          <Button
            action={handleResendCode}
            additionalClassName={styles.resendButton}
            disabled={!enteredUsername}
            type="button"
          >
            Resend Code
          </Button>
        </div>
      </form>
    </MainContentWrapper>
  );
};
