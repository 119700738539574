import { ListFilter } from 'components/ListFilter/ListFilter';
import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import Spinner from 'components/Spinner/Spinner';
import { useTitleList } from 'hooks/documentHooks';
import PropTypes, { oneOfType } from 'prop-types';
import { useContext } from 'react';

export const TitleFilter = ({ handleFilterChange, handleToggle, selectedTitles }) => {
  const { currentEdition } = useContext(RefDataContext);
  const { data: allTitles, isLoading } = useTitleList(currentEdition);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <ListFilter
      dataList={allTitles}
      fieldName="titles"
      initialSelection={selectedTitles}
      isActive={true}
      isFilterable={true}
      listName="Titles"
      onToggle={handleToggle}
      onUpdate={handleFilterChange}
      pluralName="Titles"
      propName="displayName"
      propValue="title_num"
    />
  );
};

TitleFilter.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  selectedTitles: PropTypes.arrayOf(oneOfType([PropTypes.number, PropTypes.string]))
};
