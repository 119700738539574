import PropTypes from 'prop-types';
import { useState } from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';
import { indianaRegisterCategory } from 'util/shapes';

import styles from './DocumentAccordion.module.css';
import { DocumentItem } from './DocumentItem/DocumentItem';

// recursively count up all the docs in the tree...
const countDocuments = (doctype) => {
  let count = doctype.docs?.length ?? 0;
  if (doctype.sub_cats?.length) {
    for (let i in doctype.sub_cats) {
      count += countDocuments(doctype.sub_cats[i]);
    }
  }
  return count;
};

export const DocumentAccordion = ({ documentType, isTop }) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasDirectDocumentChildren = documentType.docs?.length > 0;
  const hasContent = documentType.docs?.length || documentType.sub_cats?.length;
  const docCount = countDocuments(documentType);
  const hasOnlyDocs = documentType.docs?.length && !documentType.sub_cats?.length;
  const topClass = isTop ? styles.topLevelAccordion : styles.accordion;
  const classes = hasOnlyDocs ? `${topClass} ${styles.onlyDocs}` : topClass;

  const toggleAccordion = () => {
    setIsOpen((current) => !current);
  };

  return (
    <div className={isOpen ? `${classes} ${styles.accordionOpen}` : classes}>
      <button
        className={
          hasDirectDocumentChildren
            ? `${styles.toggleButton} ${styles.directDocs}`
            : styles.toggleButton
        }
        onClick={toggleAccordion}
        disabled={!hasContent}
        aria-expanded={isOpen}
      >
        <div>
          <strong>{docCount}</strong> {documentType.cat_name || documentType.sub_cat_name}
        </div>
        {hasContent ? (
          <RiArrowDownSLine
            size="2rem"
            className={styles.toggleButtonIcon}
            title="accordion toggle icon"
          />
        ) : null}
      </button>
      {hasContent ? (
        <div className={styles.documents}>
          <div className={styles.documentsContent}>
            {documentType.docs?.map((docType) => (
              <DocumentItem item={docType} key={`${docType.cat_id}-${docType.id}`} />
            ))}
            {documentType.sub_cats?.map((subCat) => (
              <DocumentAccordion documentType={subCat} key={subCat.sub_cat_id} />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

DocumentAccordion.propTypes = {
  documentType: indianaRegisterCategory.isRequired,
  isTop: PropTypes.bool
};
