import PropTypes from 'prop-types';
import { codeTitle } from 'util/shapes';

import styles from './ArticleNav.module.css';
import { ArticleNavLink } from './ArticleNavLink/ArticleNavLink';

/**
 * Create the previous / next article navigation
 * @param article the current article number
 * @param title the current title number
 * @param titleData the title/article nested data
 * @param year the edition year
 * @returns {JSX.Element}
 * @constructor
 */
export const ArticleNav = ({ article, title, titleData, year }) => {
  const articleNav = {
    next: null,
    prev: null
  };
  // find the title in the editionData tree...
  const titleIndex = titleData.findIndex((editionTitle) => editionTitle.title_num === title);
  const curTitle = titleIndex !== -1 ? titleData[titleIndex] : null;

  // find the index of the current one
  const articleIndex = curTitle?.article.findIndex(
    (titleArticle) => titleArticle.article_num === article
  );
  // get the article before
  if (articleIndex === 0 && titleIndex > 0) {
    const prevTitle = titleData[titleIndex - 1];
    const prevArticles = prevTitle.article;
    articleNav.prev = {
      article: prevArticles[prevArticles.length - 1].article_num,
      title: prevTitle.title_num,
      titleChange: true,
      year: year
    };
  } else if (articleIndex > 0) {
    articleNav.prev = {
      article: curTitle.article[articleIndex - 1].article_num,
      title: title,
      year: year
    };
  }
  // get the article after
  if (
    articleIndex > -1 &&
    articleIndex === curTitle.article.length - 1 &&
    titleIndex < titleData.length - 1
  ) {
    const nextTitle = titleData[titleIndex + 1];
    articleNav.next = {
      article: nextTitle.article[0].article_num,
      title: nextTitle.title_num,
      titleChange: true,
      year: year
    };
  } else if (articleIndex > -1 && articleIndex < curTitle.article.length - 1) {
    articleNav.next = {
      article: curTitle.article[articleIndex + 1].article_num,
      title: title,
      year: year
    };
  }

  return (
    <div className={styles.nav}>
      {articleNav.prev ? (
        <ArticleNavLink articleInfo={articleNav.prev} direction="previous" />
      ) : (
        <span />
      )}
      {articleNav.next ? (
        <ArticleNavLink articleInfo={articleNav.next} direction="next" />
      ) : (
        <span />
      )}
    </div>
  );
};

ArticleNav.propTypes = {
  article: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleData: PropTypes.arrayOf(codeTitle).isRequired,
  year: PropTypes.string.isRequired
};
