import { Loading, Select } from 'colosseum';
import { Button } from 'components/Button/Button';
import { MainContentWrapper } from 'components/MainContentWrapper/MainContentWrapper';
import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import { SearchInput } from 'components/SearchInput/SearchInput';
import Spinner from 'components/Spinner/Spinner';
import { useOpenSearch } from 'hooks/openSearchHook';
import { SearchTabPanel } from 'pages/Search/SearchTabPanel/SearchTabPanel';
import { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { RiFilterLine } from 'react-icons/ri';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { earliestDataFn, todayFn } from 'util/dateUtil';
import { docGroup } from 'util/documentUtil';
import {
  buildSearchQueryString,
  buildSearchTabOptions,
  defaultSearchObject,
  parseTabbedSearchQueryString
} from 'util/searchUtil';
import { dateRanges } from 'util/shapes';

import styles from './TabbedSearch.module.css';
import filterStyle from './TabbedSearchFilters/TabbedSearchFilters.module.css';

const description = {
  ag: 'AG Opinions contains actions from the Attorney General including Opinions, Disapprovals, and Objections.',
  agencyGuidance:
    'Agency Guidance contains documents other than rule documents from the state agencies ' +
    'including information bulletins, revenue rulings, other guidelines of a state agency.',
  all: 'All Documents',
  gov:
    'Gov contains actions from the Governor including Executive Orders, Proclamations, Request for ' +
    'additional time, Approvals, Disapprovals, and Objections.',
  iac:
    'IAC contains the current edition of the Indiana Administrative Code consisting of a compilation ' +
    'of text adopted by the state agencies in permanent rules organized using a four level citation ' +
    'scheme. Archived editions available from 2006 to present.',
  pending:
    'Pending rules contains active rule documents being proposed by the state agencies including ' +
    'First Public Comment Periods, Second Public Comment Periods, Public Hearings, Regulatory ' +
    'Analyses, Provisional Rules, and Interim Rules.',
  ir: 'IR contains all Indiana Register Documents.'
};

const defaultDateRange = {
  dateRange: dateRanges.custom,
  endDate: todayFn(),
  startDate: earliestDataFn()
};

const defaultParams = {
  ag: {
    archived: false,
    document_group: docGroup.ag,
    ...defaultDateRange
  },
  agencyGuidance: {
    archived: false,
    document_group: docGroup.agencyGuidance,
    ...defaultDateRange
  },
  all: {
    archived: true,
    document_group: docGroup.all,
    ...defaultDateRange
  },
  gov: {
    archived: false,
    document_group: docGroup.gov,
    ...defaultDateRange
  },
  iac: {
    archived: false
  },
  pending: {
    archived: false,
    document_group: docGroup.pending,
    ...defaultDateRange
  },
  ir: {
    archived: false,
    document_group: docGroup.ir,
    ...defaultDateRange
  }
};

const resetParams = (defaults, currentEdition, searchTerm) => {
  const resetObj = {};
  for (const prop in defaults) {
    resetObj[prop] = {
      ...defaults[prop],
      search: searchTerm
    };
    if (prop === 'iac' || prop === 'all') {
      resetObj[prop].editions = [currentEdition];
    }
  }
  return resetObj;
};

/**
 * Advanced Search Page with Tabs.
 * @returns {JSX.Element}
 * @constructor
 */
export const TabbedSearch = () => {
  const { currentEdition, documentTypes, topics } = useContext(RefDataContext);
  const history = useHistory();
  const { hash, pathname, search } = useLocation();
  const { data, executeSearch, isSearching } = useOpenSearch();
  const selectedTab = hash ? hash.slice(1) : 'pending';

  const parsedParams = parseTabbedSearchQueryString(
    search,
    defaultParams,
    currentEdition,
    documentTypes,
    topics,
    selectedTab
  );
  const [params, setParams] = useState(parsedParams);
  const [tabCounts, setTabCounts] = useState(null);
  const filterRef = useRef(null);
  const isInitialized = useRef(false);

  // user clicks to change the tab
  const changeTab = (newTab) => {
    const tabParams = params[newTab.value];
    const queryString = buildSearchQueryString(tabParams);
    history.replace(`${pathname}?${queryString}#${newTab.value}`);
  };

  // When the user hits the search button
  const handleSearch = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const term = form.get('search');
    const queryString = buildSearchQueryString({
      ...params[selectedTab],
      search: term,
      start: 0
    });
    history.replace(`${pathname}?${queryString}#${selectedTab}`);
    setTabCounts(null);
    setParams({
      ...resetParams(defaultParams, currentEdition, term, true),
      [selectedTab]: {
        ...params[selectedTab],
        start: 0,
        search: term
      },
      search: term
    });
    executeSearch({
      ...defaultSearchObject,
      editions: [currentEdition],
      search: term
    });
  };

  // when the user changes filters / pages on a tab
  const handleUpdateSearch = (filter, tabId) => {
    const tabParams = {
      ...params[tabId],
      start: 0,
      ...filter
    };
    const newParams = {
      ...params,
      [tabId]: tabParams
    };
    const queryString = buildSearchQueryString(tabParams);
    history.replace(`${pathname}?${queryString}#${tabId}`);
    setParams(newParams);
  };

  // If there's no hash, and the UI has defaulted, update the URL with the default tab
  useEffect(() => {
    if (!hash) {
      history.replace(`${pathname}${search}#${selectedTab}`);
    }
  }, [hash, history, pathname, search, selectedTab]);

  // Handle search from URL, with params that have been parsed.
  // Add the default edition, too
  // But only do it on initial render.
  useEffect(() => {
    if (!isInitialized.current) {
      executeSearch({
        ...defaultSearchObject,
        editions: [currentEdition],
        search: parsedParams.search
      });
      isInitialized.current = true;
    }
  }, [currentEdition, executeSearch, parsedParams.search]);

  useEffect(() => {
    setTabCounts(() => {
      if (data) {
        const iacCount =
          data.aggregations?.collection?.buckets?.find((b) => b.key === 'iac')?.doc_count ?? 0;
        return {
          pending: `${
            data.aggregations?.documentGroup?.buckets?.find((b) => b.key === 'pending_rules')
              ?.doc_count ?? 0
          }`,
          iac: `${iacCount}`,
          agencyGuidance: `${
            data.aggregations?.documentGroup?.buckets?.find((b) => b.key === 'agency_guidance')
              ?.doc_count ?? 0
          }`,
          gov: `${
            data.aggregations?.documentGroup?.buckets?.find((b) => b.key === 'gov')?.doc_count ?? 0
          }`,
          ag: `${
            data.aggregations?.documentGroup?.buckets?.find((b) => b.key === 'ag_opinions')
              ?.doc_count ?? 0
          }`,
          all: `${data.hits?.total?.value ?? 0}`,
          ir: `${(data.hits?.total?.value ?? 0) - iacCount}`
        };
      }
      return null;
    });
  }, [data]);

  const tabOptions = buildSearchTabOptions(tabCounts);

  const toggleFilters = () => {
    filterRef.current?.classList.toggle(filterStyle.open);
  };

  return (
    <MainContentWrapper additionalClassName={styles.searchPage}>
      <Helmet>
        <title>Advanced Search | IARP</title>
      </Helmet>
      <form onSubmit={handleSearch}>
        <div className={styles.searchAndTabs}>
          <SearchInput
            buttonAriaLabel="search button"
            defaultValue={params.search}
            inputAriaLabel="Search"
            name="search"
            placeholder="Search"
          />
          <div>
            <div aria-label="Search Group tabs" className={styles.tabBar} role="tablist">
              {tabOptions.map((tab) => (
                <button
                  disabled={tab.value === selectedTab}
                  key={`tab-${tab.value}`}
                  onClick={() => changeTab(tab)}
                >
                  <span>{tab.label}</span>
                  {isSearching ? <Spinner light={tab.value === selectedTab} /> : null}
                </button>
              ))}
            </div>
            <div className={styles.selectContainer}>
              <Select
                compact={true}
                defaultValue={{ value: 'pending', label: 'Pending Rules' }}
                name="filter"
                onChange={changeTab}
                options={tabOptions}
                value={tabOptions.find((tab) => tab.value === selectedTab)}
              />
              <Button ariaLabel="open search filters" action={toggleFilters} type="button">
                <RiFilterLine />
              </Button>
            </div>
          </div>
          <p className={styles.searchContainer}>{description[selectedTab]}</p>
        </div>
        {isSearching || !tabCounts ? (
          <Loading />
        ) : (
          <SearchTabPanel
            ref={filterRef}
            onCloseFilters={toggleFilters}
            params={params[selectedTab]}
            tabId={selectedTab}
            updateSearch={handleUpdateSearch}
          />
        )}
      </form>
    </MainContentWrapper>
  );
};
