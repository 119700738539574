import PropTypes from 'prop-types';
import React, { useState } from 'react';

import styles from './Tabs.module.css';

const Tabs = ({ tabData, id, ariaLabel = 'Tabs' }) => {
  const [selected, setSelected] = useState(0);
  const tabId = (idx) => `tab-${idx}-${id}`;
  const panelId = (idx) => `panel-${idx}-${id}`;
  return (
    <>
      <div role="tablist" aria-label={ariaLabel} className={styles.tabBar}>
        {tabData?.map(({ header }, idx) => (
          <button
            key={`${idx}_${header}`}
            role="tab"
            aria-selected={selected === idx}
            aria-controls={panelId(idx)}
            id={tabId(idx)}
            className={selected === idx ? styles.selected : undefined}
            onClick={() => setSelected(idx)}
          >
            {header}
          </button>
        ))}
      </div>
      <div className={styles.tabContent}>
        {tabData?.map(({ header, body }, idx) => (
          <div
            key={`${idx}_${header}`}
            id={panelId(idx)}
            role="tabpanel"
            aria-labelledby={tabId(idx)}
            hidden={selected !== idx}
          >
            {body}
          </div>
        ))}
      </div>
    </>
  );
};

Tabs.propTypes = {
  tabData: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.node.isRequired,
      body: PropTypes.node.isRequired
    })
  ),
  id: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string
};

export default Tabs;
