import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { MainContentWrapper } from 'components/MainContentWrapper/MainContentWrapper';
import { Helmet } from 'react-helmet-async';

import styles from './Error.module.css';

export const GeneralError = () => {
  return (
    <MainContentWrapper>
      <Helmet>
        <title>Error | IARP</title>
      </Helmet>
      <AccentHeader level={1}>Error</AccentHeader>
      <p className={styles.help}>An error has occurred.</p>
    </MainContentWrapper>
  );
};
