import { Button } from 'components/Button/Button';
import { FadeScrollPanel } from 'components/FadeScrollPanel/FadeScrollPanel';
import { OptionButton } from 'components/OptionButton/OptionButton';
import { TextFilterControl } from 'components/TextFilterControl/TextFilterControl';
import PropTypes, { oneOfType } from 'prop-types';
import { useState } from 'react';

import { FilterItem } from './FilterItem/FilterItem';
import styles from './ListFilter.module.css';

/**
 * Configurable filter for a list of items, allowing for multiple selections.
 *
 * @param childPropName
 * @param childPropValue
 * @param dataHook Hook used to load data for the control (uses react-query, or something returning similar values)
 * @param dataList
 * @param fieldName Name of the field within the form. This will create an array of values in the form.
 * @param initialSelection The IDs of the items selected.
 * @param isActive Whether or not the control is active. Optional, defaults to false.
 * @param isFilterable Whether or not to show a filter input, which filters by display name. Optional defaults to false.
 * @param listName The name of the list for display
 * @param onUpdate Function to call to update the overall form object
 * @param pluralName The plural name of the list
 * @param propName The property of the display value in items returned by the data hook.
 * @param propValue The property of the ID/Value property in items returned by the data hook.
 * @returns {JSX.Element}
 * @constructor
 */
export const ListFilter = ({
  childFieldName,
  childPropName,
  childPropValue,
  dataList,
  fieldName,
  initialChildSelection,
  initialSelection,
  isActive,
  isFilterable,
  listName,
  onToggle,
  onUpdate,
  pluralName,
  propName,
  propValue
}) => {
  const [filterString, setFilterString] = useState('');
  const label =
    listName +
    (initialChildSelection?.length || initialSelection?.length
      ? ` (${(initialChildSelection?.length ?? 0) + (initialSelection?.length ?? 0)})`
      : ' (All)');

  const clearItems = (e) => {
    e.preventDefault();
    if (childFieldName) {
      onUpdate({
        [childFieldName]: [],
        [fieldName]: []
      });
    } else {
      onUpdate({ [fieldName]: [] });
    }
  };

  const updateList = (list, id, isChecked) => {
    if (isChecked) {
      list.push(`${id}`);
    } else {
      const index = list.findIndex((item) => item === id);
      list.splice(index, 1);
    }
  };

  const handleItemChange = (id, isChecked, children) => {
    const update = {};
    const newList = initialSelection?.length ? [...initialSelection] : [];
    if (children?.length) {
      const childSelection = new Set(initialChildSelection);
      children.forEach((child) => {
        if (isChecked) {
          childSelection.add(child[childPropValue]);
        } else {
          childSelection.delete(child[childPropValue]);
        }
      });
      update[childFieldName] = Array.from(childSelection);
    }
    updateList(newList, id, isChecked);
    onUpdate({
      ...update,
      [fieldName]: newList
    });
  };

  const handleChildItemChange = (id, isChecked, parentId) => {
    const update = {};
    if (parentId) {
      const newParentList = initialSelection?.length ? [...initialSelection] : [];
      updateList(newParentList, parentId, isChecked);
      update[fieldName] = newParentList;
    }
    const newList = initialChildSelection?.length ? [...initialChildSelection] : [];
    updateList(newList, id, isChecked);
    onUpdate({
      ...update,
      [childFieldName]: newList
    });
  };

  const toggleList = (isOpen) => {
    setFilterString((cur) => (isOpen ? cur : ''));
    onToggle(isOpen);
  };

  const isVisible = (item, propName, childPropName, filter) => {
    if (filterString) {
      const thisMatches = item[propName].toLowerCase().includes(filter);
      if (item.children && childPropName) {
        const childrenMatch = item.children.some((child) =>
          child[childPropName].toLowerCase().includes(filter)
        );
        return thisMatches || childrenMatch;
      } else {
        return thisMatches;
      }
    }
    return true;
  };

  return (
    <OptionButton
      ariaLabel={`select ${pluralName} to filter`}
      disabled={!isActive}
      label={label}
      onToggle={toggleList}
    >
      <div className={styles.listContainer}>
        <div className={styles.listAndCount}>
          <div className={styles.filterHeader}>
            {isFilterable ? (
              <TextFilterControl
                ariaLabel={`filter ${pluralName}`}
                onFilterChange={setFilterString}
                placeholder={`Filter ${pluralName}`}
                value={filterString}
              />
            ) : null}
            <Button
              action={clearItems}
              additionalClassName={styles.clearButton}
              ariaLabel={`clear selected ${pluralName}`}
              disabled={!initialSelection?.length && !initialChildSelection?.length}
            >
              Remove All
            </Button>
          </div>
          {dataList?.length ? (
            <FadeScrollPanel tag="ol" additionalClassName={styles.inlinePanel}>
              {dataList.map((item) => (
                <FilterItem
                  checked={initialSelection?.includes(`${item[propValue]}`)}
                  childFieldName={childFieldName}
                  childPropName={childPropName}
                  childPropValue={childPropValue}
                  disabled={!isActive}
                  display={isVisible(item, propName, childPropName, filterString)}
                  fieldName={fieldName}
                  filterString={filterString}
                  initialChildSelection={initialChildSelection}
                  item={{
                    children: item.children,
                    id: `${item[propValue]}`,
                    name: item[propName]
                  }}
                  key={`${fieldName}-${item[propValue]}`}
                  onToggle={handleItemChange}
                  onToggleChild={handleChildItemChange}
                />
              ))}
            </FadeScrollPanel>
          ) : null}
        </div>
      </div>
    </OptionButton>
  );
};

ListFilter.propTypes = {
  childFieldName: PropTypes.string,
  childPropName: PropTypes.string,
  childPropValue: PropTypes.string,
  dataHook: PropTypes.func,
  dataList: PropTypes.array,
  fieldName: PropTypes.string.isRequired,
  initialChildSelection: PropTypes.arrayOf(oneOfType([PropTypes.number, PropTypes.string])),
  initialSelection: PropTypes.arrayOf(oneOfType([PropTypes.number, PropTypes.string])),
  isActive: PropTypes.bool,
  isFilterable: PropTypes.bool,
  listName: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  pluralName: PropTypes.string.isRequired,
  propName: PropTypes.string.isRequired,
  propValue: PropTypes.string.isRequired,
  subHeader: PropTypes.string
};
