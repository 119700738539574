import { Loading } from 'colosseum';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { ErrorMessage } from 'components/ErrorMessage/ErrorMessage';
import { IARAccordion } from 'components/IARAccordion/IARAccordion';
import { MainContentWrapper } from 'components/MainContentWrapper/MainContentWrapper';
import { useIrRelatedDocs } from 'hooks/documentHooks';
import { IRDocument } from 'pages/MyIAR/Home/Dashboard/IRDocumentAccordion/IRDocument/IRDocument';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { displayLsaDocNumber } from 'util/documentUtil';

import style from './LsaDocument.module.css';

// Doc Types for each type of LSA Document
const rulemakingDocTypes = ['FN', 'FC', 'SN', 'SC', 'AP', 'AF', 'AC', 'FR'];
const readoptDocTypes = ['LN', 'RN', 'AF', 'AC', 'RF'];
const provisionalDocTypes = ['AF', 'AC', 'ER'];
const interimDocTypes = ['IN', 'AF', 'AC', 'IF'];

export const LsaDocument = () => {
  const { lsaDocNum } = useParams();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchedDin = searchParams?.get('searchDoc') ?? '';

  const { data: relatedDocs, error, isLoading } = useIrRelatedDocs(lsaDocNum);
  const prettyLsaDocNumber = displayLsaDocNumber(lsaDocNum);
  const pageTitle = `LSA Document #${prettyLsaDocNumber} | IARP`;

  const firstRulemakingDoc = relatedDocs?.find((doc) => doc.doc_type === 'FN');
  const firstReadoptDoc = relatedDocs?.find((doc) => doc.doc_type === 'LN');
  const firstInterimDoc = relatedDocs?.find((doc) => doc.doc_type === 'IN');
  const firstProvisionalDoc =
    !firstRulemakingDoc &&
    !firstReadoptDoc &&
    !firstInterimDoc &&
    relatedDocs?.find((doc) => provisionalDocTypes.includes(doc.doc_type));

  const displayedDocTypes = firstRulemakingDoc
    ? rulemakingDocTypes
    : firstReadoptDoc
    ? readoptDocTypes
    : firstInterimDoc
    ? interimDocTypes
    : firstProvisionalDoc
    ? provisionalDocTypes
    : [];

  const supportingDocs = relatedDocs?.filter((doc) => !displayedDocTypes.includes(doc.doc_type));

  const digest =
    firstRulemakingDoc?.digest ||
    firstReadoptDoc?.digest ||
    firstInterimDoc?.digest ||
    firstProvisionalDoc?.digest;

  return (
    <MainContentWrapper>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {isLoading ? (
        <Loading />
      ) : error ? (
        <ErrorMessage>Error loading related documents</ErrorMessage>
      ) : (
        <>
          <AccentHeader level={1}>LSA Document #{prettyLsaDocNumber}</AccentHeader>
          <div className={style.digest} dangerouslySetInnerHTML={{ __html: digest }}></div>
          {relatedDocs?.length ? (
            <>
              {firstRulemakingDoc ? (
                <IARAccordion title="Rulemaking" defaultOpen>
                  <ul className={style.documentList}>
                    {relatedDocs
                      .filter((doc) => rulemakingDocTypes.includes(doc.doc_type))
                      .map((doc) => (
                        <IRDocument
                          key={doc.id}
                          document={doc}
                          emphasized={searchedDin === doc.din}
                        />
                      ))}
                  </ul>
                </IARAccordion>
              ) : null}
              {firstReadoptDoc ? (
                <IARAccordion title="Readopt" defaultOpen>
                  <ul className={style.documentList}>
                    {relatedDocs
                      .filter((doc) => readoptDocTypes.includes(doc.doc_type))
                      .map((doc) => (
                        <IRDocument
                          key={doc.id}
                          document={doc}
                          emphasized={searchedDin === doc.din}
                        />
                      ))}
                  </ul>
                </IARAccordion>
              ) : null}
              {firstProvisionalDoc ? (
                <IARAccordion title="Provisional Rulemaking" defaultOpen>
                  <ul className={style.documentList}>
                    {relatedDocs
                      .filter((doc) => provisionalDocTypes.includes(doc.doc_type))
                      .map((doc) => (
                        <IRDocument
                          key={doc.id}
                          document={doc}
                          emphasized={searchedDin === doc.din}
                        />
                      ))}
                  </ul>
                </IARAccordion>
              ) : null}
              {firstInterimDoc ? (
                <IARAccordion title="Interim Rulemaking" defaultOpen>
                  <ul className={style.documentList}>
                    {relatedDocs
                      .filter((doc) => interimDocTypes.includes(doc.doc_type))
                      .map((doc) => (
                        <IRDocument
                          key={doc.id}
                          document={doc}
                          emphasized={searchedDin === doc.din}
                        />
                      ))}
                  </ul>
                </IARAccordion>
              ) : null}
              {supportingDocs.length ? (
                <IARAccordion title="Supporting Documents" defaultOpen>
                  <ul className={style.documentList}>
                    {supportingDocs.map((doc) => (
                      <IRDocument
                        key={doc.id}
                        document={doc}
                        emphasized={searchedDin === doc.din}
                      />
                    ))}
                  </ul>
                </IARAccordion>
              ) : null}
            </>
          ) : (
            <p>No documents found for {prettyLsaDocNumber}.</p>
          )}
        </>
      )}
    </MainContentWrapper>
  );
};
