import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import styles from './AdminRegisterDoc.module.css';

/**
 * Render the data for an IR document in a user's dashboard
 * @param document the document metadata
 * @param externalLink
 * @param disabled
 * @param isChecked
 * @param toggleCheckbox
 * @returns {JSX.Element}
 * @constructor
 */
export const AdminRegisterDoc = ({
  document,
  externalLink = true,
  disabled = false,
  isChecked = false,
  toggleCheckbox
}) => {
  const { getDocTypeName } = useContext(RefDataContext);
  return (
    <li className={styles.irDocumentContainer}>
      <div className={styles.irDocument}>
        <input
          type="checkbox"
          className={styles.checkbox}
          checked={isChecked}
          onChange={toggleCheckbox}
        />
        <div className={styles.docName}>
          {disabled ? (
            <span>{document.din}</span>
          ) : externalLink ? (
            <Link to={`/register/${document.din}`} rel="noopener noreferrer" target="_blank">
              {document.din}
            </Link>
          ) : (
            <Link to={`/register/${document.din}`}>{document.din}</Link>
          )}
        </div>
        <div className={styles.docType}>{getDocTypeName(document.doc_type)}</div>
        <div className={styles.date}>{format(new Date(document.date_posted), 'MM/dd/yy')}</div>
      </div>
    </li>
  );
};

AdminRegisterDoc.propTypes = {
  document: PropTypes.shape({
    date_posted: PropTypes.string,
    din: PropTypes.string,
    doc_type: PropTypes.string
  }),
  externalLink: PropTypes.bool,
  disabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  toggleCheckbox: PropTypes.func
};
