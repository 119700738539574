import { IARAccordion } from 'components/IARAccordion/IARAccordion';
import { isAfter, isSameDay, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { sixDaysAgoFn } from 'util/dateUtil';

import { IRDocument } from './IRDocument/IRDocument';
import styles from './IRDocumentAccordion.module.css';

/**
 * Render an accordion for a specified agency and it's documents
 * @param agencyDocs Agency info, and list of documents
 * @param lastVisited Date of last page visit
 * @returns {JSX.Element}
 * @constructor
 */
export const IRDocumentAccordion = ({ agencyDocs, lastVisited }) => {
  const isLater = (postedDate, compareDate) => {
    return isSameDay(postedDate, compareDate) || isAfter(postedDate, compareDate);
  };

  // get a count of new documents since last login. If there's no last login, they're ALL new
  const newCount =
    lastVisited && agencyDocs?.docs?.length
      ? agencyDocs.docs.filter((doc) => {
          const postedDate = parseISO(doc.date_posted);
          return isLater(postedDate, lastVisited);
        })?.length
      : agencyDocs?.docs?.length ?? 0;

  const isNewOrUnread = (doc) => {
    const postedDate = parseISO(doc.date_posted);
    return isLater(postedDate, lastVisited) || isLater(postedDate, sixDaysAgoFn());
  };

  return (
    <IARAccordion
      title={
        <>
          {newCount ? (
            <>
              <strong>{newCount} new</strong>&nbsp;
            </>
          ) : null}
          {agencyDocs.agencyName}
        </>
      }
    >
      <div className={styles.documentListTitle}>
        <span className={styles.docCount}>
          {agencyDocs.docs?.length} document{agencyDocs.docs?.length === 1 ? '' : 's'} in the last
          30 days
        </span>
        {agencyDocs.docs?.length ? <strong>Published</strong> : null}
      </div>
      {agencyDocs.docs?.map((doc) => (
        <IRDocument key={doc.din} document={doc} emphasized={isNewOrUnread(doc)} />
      ))}
      <div className={styles.moreLink}>
        <Link to={`/search?dateRange=sixMonths&agencies=${agencyDocs.agencyId}#ir`}>
          View documents from the last six months
        </Link>
      </div>
    </IARAccordion>
  );
};

IRDocumentAccordion.propTypes = {
  agencyDocs: PropTypes.shape({
    agencyId: PropTypes.string,
    agencyName: PropTypes.string,
    docs: PropTypes.arrayOf(
      PropTypes.shape({
        date_posted: PropTypes.string,
        din: PropTypes.string,
        doc_type: PropTypes.string
      })
    )
  }),
  lastVisited: PropTypes.instanceOf(Date)
};
