import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import FiscallySignificantTooltip from 'components/FiscallySignificantTooltip/FiscallySignificantTooltip';
import { SegmentedControl } from 'components/SegmentedControl/SegmentedControl';
import { useUserPreferences, useUserPreferencesMutation } from 'hooks/userHooks';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';

import styles from './Notifications.module.css';

const followNotificationEnabledItems = [
  { id: 'followNotificationsEnabled', label: 'Enabled', value: 'enabled' },
  { id: 'followNotificationsDisabled', label: 'Disabled', value: 'disabled' }
];

const millionNotificationEnabledItems = [
  { id: 'millionNotificationsEnabled', label: 'Enabled', value: 'enabled' },
  { id: 'millionNotificationsDisabled', label: 'Disabled', value: 'disabled' }
];

export const Notifications = () => {
  const { data: userPrefs, isLoading } = useUserPreferences();
  const { isPending, mutate } = useUserPreferencesMutation();
  const followNotificationsEnabled = userPrefs?.opt_in_email ? 'enabled' : 'disabled';
  const millionNotificationsEnabled = userPrefs?.opt_in_million_email ? 'enabled' : 'disabled';

  const handleFollowNotificationChange = (e) => {
    const optInEmail = e.target.value === 'enabled';
    mutate({ optInEmail });
    toast.success(`Successfully ${e.target.value} Followed-Agency notifications.`, {
      position: 'top-right'
    });
  };

  const handleMillionNotificationChange = (e) => {
    const optInMillionEmail = e.target.value === 'enabled';
    mutate({ optInMillionEmail });
    toast.success(`Successfully ${e.target.value} Fiscally-Significant notifications.`, {
      position: 'top-right'
    });
  };

  return (
    <>
      <Helmet>
        <title>Notifications | IARP</title>
      </Helmet>
      <AccentHeader level={2} underline="subtle">
        Notifications
      </AccentHeader>
      <div>
        <h3 className={styles.notificationHeader}>Email for Followed Agency Activity</h3>
        <SegmentedControl
          ariaLabel="Followed Agencies Email Notifications"
          controlName="followNotificationsEnabled"
          items={followNotificationEnabledItems}
          onSelect={handleFollowNotificationChange}
          selectedVal={followNotificationsEnabled}
          disabled={isLoading || isPending}
        />
        <h3 className={styles.notificationHeader}>
          Email for Fiscally Significant <FiscallySignificantTooltip /> Activity
        </h3>
        <SegmentedControl
          ariaLabel="Fiscally Significant Email Notifications"
          controlName="millionNotificationsEnabled"
          items={millionNotificationEnabledItems}
          onSelect={handleMillionNotificationChange}
          selectedVal={millionNotificationsEnabled}
          disabled={isLoading || isPending}
        />
      </div>
      <p className={styles.frequencyText}>
        Each Thursday, a weekly email notification will be sent if one or more of these documents
        types are published.
      </p>
    </>
  );
};
