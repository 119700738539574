import { SegmentedControl } from 'components/SegmentedControl/SegmentedControl';
import PropTypes from 'prop-types';

import styles from './AgencyFilter.module.css';
import { AgencyNameFilter } from './AgencyNameFilter/AgencyNameFilter';

const agencyFilterItems = [
  {
    id: 'allFilter',
    label: 'All',
    value: 'all'
  },
  {
    id: 'followingFilter',
    label: 'Following',
    value: 'following'
  }
];

/**
 * Controls for filtering the agency list.
 *
 * @param filterSelection 'all' agencies or 'following' agencies
 * @param filterString text string to match the agency name
 * @param onChange callback when the user changes the search text or all/following selection
 * @returns {JSX.Element}
 * @constructor
 */
export const AgencyFilter = ({ filterSelection, filterString, onChange }) => {
  const handleFilterSelection = (e) => {
    onChange({ filterSelection: e.target.value, filterString });
  };
  const setFilterString = (value) => {
    onChange({ filterSelection, filterString: value });
  };

  return (
    <div className={styles.filtersContainer}>
      <div className={styles.filters}>
        <AgencyNameFilter initialValue={filterString} onFilterChange={setFilterString} />
        <SegmentedControl
          controlName="agencyfilter"
          items={agencyFilterItems}
          ariaLabel="filter agencies by following status"
          onSelect={handleFilterSelection}
          selectedVal={filterSelection}
        />
      </div>
    </div>
  );
};

AgencyFilter.propTypes = {
  filterSelection: PropTypes.oneOf(['all', 'following']),
  filterString: PropTypes.string,
  onChange: PropTypes.func
};
