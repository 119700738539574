import PropTypes from 'prop-types';
import { RiSearchLine } from 'react-icons/ri';

import styles from './TextFilterControl.module.css';

/**
 * Simple text filter control.
 *
 * @param ariaLabel Text to use for aria-label
 * @param onFilterChange Function to call when the filter value changes
 * @param placeholder Text to use as placeholder
 * @param value Text for the input
 * @returns {JSX.Element}
 * @constructor
 */
export const TextFilterControl = ({ ariaLabel, onFilterChange, placeholder, value }) => {
  return (
    <div className={styles.filterWrapper}>
      <RiSearchLine className={styles.filterIcon} title="Search icon" />
      <input
        type="search"
        aria-label={ariaLabel}
        placeholder={placeholder}
        className={styles.filterInput}
        onChange={(e) => onFilterChange(e.target.value ? e.target.value.toLowerCase() : '')}
        value={value}
      />
    </div>
  );
};

TextFilterControl.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string
};
