import { AdminContext } from 'components/AdminContext/AdminContext';
import { ManualSyncButton } from 'pages/MyIAR/Home/Admin/DoubleSecretAdminControls/ManualSyncButton/ManualSyncButton';
import { useContext } from 'react';

import styles from './DoubleSecretAdminControls.module.css';

export const DoubleSecretAdminControls = () => {
  const { isBannerVisible } = useContext(AdminContext);

  return isBannerVisible ? (
    <div className={styles.controlContainer}>
      <p>Additional Admin Functions</p>
      <div className={styles.buttonsContainer}>
        <ManualSyncButton />
      </div>
    </div>
  ) : null;
};
